import React from 'react';
import PropTypes from 'prop-types';

const LayoutSection = ({ padding, children, small, className, ...props }) => {
    return (
        <section
            style={{ padding: `${padding}` }}
            {...props}
            className={`layout-section ${className || ''}`}
        >
            <div className={`container ${small ? 'small' : ''}`}>
                {children}
            </div>
        </section>
    );
};

LayoutSection.propTypes = {
    padding: PropTypes.string,
    className: PropTypes.string,
    small: PropTypes.bool,
    children: PropTypes.node,
};

export default LayoutSection;
