import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Result, Typography } from 'antd';

const { Paragraph, Text } = Typography;

// Class-based component because `componentDidCatch`
// is not available in functional components
class ErrorBoundary extends Component {
    state = {};

    componentDidCatch(error) {
        this.setState({ error });

        // Report error to Rollbar if available
        if (window.Rollbar) {
            window.Rollbar.error(error);
        }
    }

    // To decode this later, just run "atob(hashedStringHere)"
    encodeErrorMessage = error =>
        btoa(`${error.message} ::::::: ${error.stack}`);

    render() {
        if (this.state.error) {
            return (
                <Result
                    status='error'
                    title={this.props.title || 'Something went wrong'}
                    subTitle={
                        <Text>
                            Please refresh the page and try again. If the
                            problem persists, contact support at{' '}
                            <a href='mailto:info@fieldscope.org'>
                                info@fieldscope.org
                            </a>{' '}
                            and send along the text below.
                        </Text>
                    }
                >
                    <div className='desc'>
                        <Paragraph>
                            <Text
                                type='secondary'
                                style={{ wordWrap: 'break-word' }}
                            >
                                {this.encodeErrorMessage(this.state.error)}
                            </Text>
                        </Paragraph>
                    </div>
                </Result>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
