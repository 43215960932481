import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    startFetchProject,
    completeFetchProject,
    failFetchProject,
} from '../actions/project';

const initialState = Object.freeze({
    name: null,
    data: null,
    fetching: false,
    error: false,
});

const projectReducer = createReducer(
    {
        [startFetchProject]: (state, payload) =>
            update(state, {
                name: { $set: payload },
                data: { $set: initialState.data },
                fetching: { $set: true },
                error: { $set: initialState.error },
            }),
        [completeFetchProject]: (state, payload) =>
            update(state, {
                data: { $set: payload },
                fetching: { $set: initialState.fetching },
                error: { $set: initialState.error },
            }),
        [failFetchProject]: (state, payload) =>
            update(state, {
                data: { $set: initialState.data },
                fetching: { $set: initialState.fetching },
                error: { $set: payload },
            }),
    },
    initialState
);

export default projectReducer;
