import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import ColoredCard from './ColoredCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Typography, Button } from 'antd';

const { Paragraph } = Typography;

const ExploreTabContent = ({ label, description, visualizations }) => {
    const cards = visualizations.map(({ id, title, description }) => (
        <Col xs={24} sm={24} md={12} lg={8} xl={8} key={id}>
            <ColoredCard
                title={title}
                description={description}
                actions={[
                    <Link to={`/visualizations/${id}`}>
                        <Button size='large'>
                            <span>View</span>
                            <FontAwesomeIcon icon={['far', 'arrow-right']} />
                        </Button>
                    </Link>,
                ]}
            ></ColoredCard>
        </Col>
    ));

    return (
        <>
            {description && (
                <Row justify='center'>
                    <Col md={24} lg={16}>
                        <Paragraph className='home-explore__tab-description ant-typography-center'>
                            {description}
                        </Paragraph>
                    </Col>
                </Row>
            )}
            <Row
                align='top'
                justify='center'
                gutter={[32, 32]}
                className='home-explore__tab-cards'
            >
                {cards}
            </Row>
        </>
    );
};

ExploreTabContent.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    visualizations: PropTypes.array.isRequired,
};

export default ExploreTabContent;
