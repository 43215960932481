import { concat, orderBy, partition } from 'lodash';

/*
  Given a `sort` object provided by the antd `Table` component and an array of
  objects, return a sorted array unless the `sort` argument is empty or has null
  `field` or `order` properties,in which case `currentDataSource` is returned unchanged.

  Depends on the column definitions of the table including a `type` property set
  to one of the FieldScope property type strings.
 */
export function maybeSortTableData(sort, currentDataSource) {
    // antd and lodash use different string constants for specifying
    // sort order
    const sortOrder = sort?.order
        ? sort.order === 'ascend'
            ? 'asc'
            : 'desc'
        : null;

    // Function used to extract a sortable value from each table row. Schema
    // field values are stored in the database as strings, so we need to convert
    // numeric fields for proper sorting.
    const valueFunction =
        sort?.field && sort?.column
            ? sort.column.type === 'Number'
                ? o => parseFloat(o[sort.field])
                : o => o[sort.field]
            : null;

    // Ensure that rows without a value sort to the bottom of the table
    // regardless of sort direction
    const [nonEmptyValueRows, emptyValueRows] = sort?.field
        ? partition(
              currentDataSource,
              o =>
                  o[sort.field] !== null &&
                  o[sort.field] !== undefined &&
                  o[sort.field].toString().trim() !== ''
          )
        : [null, null];

    return valueFunction && sortOrder
        ? concat(
              orderBy(nonEmptyValueRows, [valueFunction], [sortOrder]),
              emptyValueRows
          )
        : currentDataSource;
}
