import { useState, useEffect } from 'react';

/**
 *
 * Hook that takes a query string (e.g. '(min-width: 960px)') and
 * ands evaluates that query whenever the window is resized, returns
 * a boolean.
 *
 * Usage:
 *  const isDesktop = useMediaQuery('(min-width: 960px)');
 *
 * From https://fireship.io/snippets/use-media-query-hook/
 */
const useMediaQuery = query => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [matches, query]);

    return matches;
};

export default useMediaQuery;
