import axios from 'axios';

import { shouldSupportOffline } from '../util';

const OFFLINE_STATUS = 408;

const event = new Event('manual-offline');

const apiRequest = axios.create();

apiRequest.interceptors.response.use(
    response => response,
    error => {
        if (
            shouldSupportOffline &&
            error?.response?.status === OFFLINE_STATUS
        ) {
            window.dispatchEvent(event);
        }
        return Promise.reject(error);
    }
);

export default apiRequest;
