import React, { useState, useEffect } from 'react';
import { Table, Tooltip, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    defaultPageSize,
    colors,
    underReviewStatus,
    draftStatus,
} from '../util/constants';
import { getDisplayColumns } from '../util/schemaHelper';
import { maybeSortTableData } from '../util/tableHelper';

import ObservationTableSidebar from './ObservationTableSidebar';
import {
    setSelectedObservations,
    setTableData,
    setTablePage,
    setTablePageSize,
} from '../actions/filterSet';
import { useSelector } from 'react-redux';
import ObservationTableBanner from './ObservationTableBanner';

const { Text } = Typography;

function ObservationTable({
    currentFilterSet,
    filterSetName,
    currentSchema,
    user,
    isAdmin,
    currentTableData,
    selectedObservations,
    currentPage,
    currentPageSize,
    dispatch,
    basemap,
    project,
    isWidget = false,
}) {
    useEffect(() => {
        // Close sidebar when switching filterSets
        setSelectedRow(null);
    }, [currentFilterSet]);

    const [selectedRow, setSelectedRow] = useState(null);
    const { isOffline, hasOfflineObservations } = useSelector(
        state => state.ui
    );

    const columnConfig = {
        onCell: record => {
            return {
                onClick: () => {
                    setSelectedRow(record);
                },
            };
        },
    };

    const fieldTypeConfig = {
        Warning: {
            render: value =>
                value ? (
                    <Tooltip title={value} arrowPointAtCenter>
                        {/* The span is required to avoid a "Function components */}
                        {/* cannot be given refs." error. */}
                        <span>
                            {value === underReviewStatus && (
                                <FontAwesomeIcon
                                    style={{ color: colors.warning }}
                                    icon={['far', 'exclamation-triangle']}
                                />
                            )}
                            {value === draftStatus && (
                                <Text mark strong>
                                    {value}
                                </Text>
                            )}
                        </span>
                    </Tooltip>
                ) : null,
            align: 'center',
        },
    };

    const displayColumns =
        currentFilterSet?.results &&
        getDisplayColumns(
            currentSchema,
            currentFilterSet.filters.displayFields ||
                currentFilterSet.filters.fields,
            columnConfig,
            fieldTypeConfig,
            currentFilterSet.filters.fieldOrder
        );

    const loading = currentFilterSet?.fetching;

    const observationTable = (
        <Table
            className='observation-table'
            rowSelection={{
                selectionType: 'checkbox',
                selectedRowKeys: selectedObservations || [],
                onChange: selectedRowKeys =>
                    dispatch(
                        setSelectedObservations({
                            filterSetName,
                            observationIds: selectedRowKeys,
                        })
                    ),
            }}
            columns={displayColumns}
            dataSource={currentTableData}
            pagination={{
                pageSize: currentPageSize ?? defaultPageSize,
                pageSizeOptions: [defaultPageSize.toString(), '50', '100'],
                defaultCurrent: 1,
                current: currentPage ?? 1,
                position: ['bottomCenter'],
            }}
            bordered
            loading={loading}
            onChange={(page, filters, sort, { currentDataSource }) => {
                dispatch(
                    setTableData({
                        filterSetName,
                        data: maybeSortTableData(sort, currentDataSource),
                    })
                );
                dispatch(setTablePage({ filterSetName, page: page.current }));
                dispatch(
                    setTablePageSize({ filterSetName, pageSize: page.pageSize })
                );
            }}
            scroll={{ x: 'max-content' }}
            sticky={{ offsetHeader: isWidget ? 0 : 64 }}
            showHeader={currentFilterSet?.results?.length > 0}
        ></Table>
    );

    const observationTableSidebar = selectedRow && displayColumns && (
        <ObservationTableSidebar
            isAdmin={isAdmin}
            data={selectedRow}
            displayColumns={displayColumns}
            clearData={() => setSelectedRow(null)}
            user={user}
            schema={currentSchema}
            basemap={basemap}
            project={project}
            isOffline={isOffline}
        />
    );

    return (
        <>
            <ObservationTableBanner
                isOffline={isOffline}
                hasOfflineObservations={hasOfflineObservations}
                user={user}
            />
            {observationTable}
            {observationTableSidebar}
        </>
    );
}

export default ObservationTable;
