import { flatMap, reduce, map, isNil } from 'lodash';
import {
    draftStatus,
    underReviewStatus,
    managerFieldSchemaFolders,
    latLngPrecision,
} from './constants';

import { formatTableFieldValue } from './fieldFormatter';
import { separateObservationMedia } from './mediaHelper';
import { formatUnit } from './units';

/*
Check the schemas object and return the default schema name
if it is loaded. Schemas are stored as an array, but a project
effectively only has a single schema of a given type (observations,
station, and user).
*/
export const getCurrentSchema = schemas => {
    const schemaName = Object.keys(schemas).length
        ? Object.keys(schemas)[0]
        : null;
    if (
        !schemaName ||
        !schemas ||
        !schemas[schemaName] ||
        schemas[schemaName].fetching
    ) {
        return null;
    }

    return schemas[schemaName].data;
};

/*
Given a schema object and a set of string field names, return the
schema-defined field objects that match the field names. Sources from both
the Observation and Station schema elements.

Include ReclassFields in the merged field set. These are meta-fields that act
like regular observation fields, except they reclassify the value of an
existing field with new lookup values. In practice, only frogwatch (species
complex) and budburst (regular report) use this functionality
*/
export const pluckFieldDefinitionsByName = (
    schema,
    fieldnames,
    fieldorder = null,
    additionalFolders = []
) => {
    if (!schema || !fieldnames) {
        return [];
    }

    const reclass = [
        {
            fields: schema.observation.reclassFields,
            label: 'Reclass',
            repeatable: false,
        },
    ];

    const stationObservationFolders = [
        { fields: getSynthesizedFields(schema) },
        ...schema.observation.folders,
        ...reclass,
        ...additionalFolders,
        ...schema.station.folders,
    ];

    const fields = flatMap(
        stationObservationFolders.map(folder =>
            folder.fields.filter(field => fieldnames.includes(field.name))
        )
    );

    if (fieldorder) {
        fields.sort((a, b) => {
            return fieldorder.indexOf(a.name) - fieldorder.indexOf(b.name);
        });
    }

    return fields;
};

/*
Given a field, calculate its column width based on how long its label is.
Shorter labels are as wide in ems as there are letters.
Longer labels are shorter. Follows the line y = 0.5x + 6
*/
const getFieldWidth = field => `${0.5 * field.label.length + 6}em`;

/*
For a list of schema-defined field definitions, including reclass fields,
create table column objects that conform to the antd table column
specification.
*/
const fieldDefinitionToColumns = (
    fields,
    optionalColumnConfig = {},
    optionalFieldTypeConfig = {}
) => {
    return fields.map(field => {
        const unitSuffix = field.units ? ` (${formatUnit(field.units)})` : '';
        const fieldTypeConfig = optionalFieldTypeConfig[field.type] || {};
        return {
            title: `${field.label}${unitSuffix}`,
            dataIndex: field.name,
            key: field.name,
            ellipsis: true,
            type: field.type,
            width: getFieldWidth(field),
            sorter: true,
            ...optionalColumnConfig,
            ...fieldTypeConfig,
        };
    });
};

const parseActiveReclassFields = (fieldNames, schema) =>
    schema?.observation?.reclassFields
        .filter(field => fieldNames.includes(field.name))
        .map(field => ({
            name: field.name,
            reclassSource: field.reclassSource,
        }));

/*
    Take a reclass field name and associate it with the value of the target
    source field that needs to be reclassed. Return an object which resembles
    a standard Observation attribute set.

    For example:
        [{ name: 'Frogwatch_SpeciesComplex', reclassSource: 'Frogwatch_SpeciesId' }]
    becomes:
        { Frogwatch_SpeciesComplex: '216' }
*/
const makeReclassAttributeSet = (reclassFields, attributes) =>
    reclassFields
        ? Object.fromEntries(
              reclassFields.map(field => [
                  field.name,
                  attributes[field.reclassSource],
              ])
          )
        : {};

/*
Given a schema object and a set of field names, create table column object that
conforms to the antd table column specification.
*/
export const getDisplayColumns = (
    schema,
    fieldnames,
    optionalColumnConfig,
    optionalFieldTypeConfig,
    optionalFieldOrderConfig
) =>
    fieldDefinitionToColumns(
        pluckFieldDefinitionsByName(
            schema,
            fieldnames,
            optionalFieldOrderConfig
        ),
        optionalColumnConfig,
        optionalFieldTypeConfig
    );

/*
Create an antd dataSource spec for each observation attribute contained in
each station result. Merge all station observations to a single list and
include observationID as a `key` value required for React rendering. Note
that the returned results will only contain the attributes requested in the
filter set. However, named fields in the filter set which are actually
reclass fields need to be directly tested for.
*/
export const getObservationDisplayValues = (
    schema,
    fieldNames,
    stationValues,
    user,
    project
) => {
    const formatter = schemaFieldValueFormatter(schema, formatTableFieldValue);
    const reclassFields = parseActiveReclassFields(fieldNames, schema);
    const managedLocValues =
        user?.projects_coordinated?.[project?.name]?.loc_field_values || [];

    return flatMap(
        stationValues?.map(station =>
            station.observations.map(observation => {
                // If this filter set contains reclass fields, construct a
                // meta-attribute set consisting of the reclass field name with
                // the reclass-source field value.
                const hasReclass = !!reclassFields?.length;
                const reclassAttributes = makeReclassAttributeSet(
                    reclassFields,
                    observation.attributes
                );
                const isDraftObservation = observation.observationId.includes(
                    'draft'
                );

                const isDataTrusted = project?.useStations
                    ? station.trusted && observation.trusted
                    : observation.trusted;

                const reviewStatus = isDraftObservation
                    ? draftStatus
                    : !isDataTrusted
                    ? underReviewStatus
                    : null;

                // Take all real and meta attributes, and format them according
                // to their schema definition.
                return {
                    ...formatter(observation.attributes),
                    ...formatter(station.attributes),
                    ...(hasReclass && formatter(reclassAttributes)),
                    ...formatter({
                        stationName: station?.stationName,
                        collectionDate: new Date(observation.collectionDate),
                        latitude: station.geometry.y,
                        longitude: station.geometry.x,
                        hasMedia: observation.hasMedia,
                    }),
                    key: observation.observationId,
                    stationId: station.stationId,
                    geometry: station.geometry,
                    isOwner: user?.userId === observation.ownerId,
                    isLoc: managedLocValues.includes(
                        observation.ownerLocFieldValue
                    ),
                    photos: observation.photos || [],
                    ...separateObservationMedia(observation),
                    reviewStatus,
                };
            })
        )
    );
};

export const getCSVDownloadDisplayValues = (
    schema,
    fieldNames,
    observationValues
) => {
    const formatter = schemaFieldValueFormatter(
        schema,
        formatTableFieldValue,
        managerFieldSchemaFolders
    );
    const reclassFields = parseActiveReclassFields(fieldNames, schema);

    return observationValues.map(observation => {
        // If this filter set contains reclass fields, construct a
        // meta-attribute set consisting of the reclass field name with
        // the reclass-source field value.
        const hasReclass = !!reclassFields?.length;
        const reclassAttributes = makeReclassAttributeSet(
            reclassFields,
            observation.attributes
        );

        // Take all real and meta attributes, and format them according
        // to their schema definition.
        const formattedObservation = {
            ...formatter(observation),
            ...(hasReclass && formatter(reclassAttributes)),
        };

        return fieldNames.map(name => formattedObservation[name]);
    });
};

/*
For a FieldScope schema object, return a formatter function that accepts
an object of field/value pairs and formats the values according to the
specified field definition from the schema.
*/
export const schemaFieldValueFormatter = (
    schema,
    fieldValueFormatterFn,
    additionalFolders = []
) => {
    return attributeSet => {
        const displayAttributeSet = Object.entries(attributeSet).map(
            ([fieldName, value]) => {
                const fieldDef = pluckFieldDefinitionsByName(
                    schema,
                    [fieldName],
                    null,
                    additionalFolders
                )[0];

                if (isNil(value) || value === '') {
                    return [fieldName, value];
                }

                return [fieldName, fieldValueFormatterFn(fieldDef, value)];
            }
        );
        return Object.fromEntries(displayAttributeSet);
    };
};

/*
Create a dict of names to labels from field definitions for easy
label lookup

Helper function for getFieldNameReclassLabelDictionary()

Input:
    fields: {{ name, label }, ...}
Output:
    { name: label, ... }
*/
const createNameLabelDictionary = fields =>
    reduce(
        fields,
        (acc, field) => {
            acc[field.name] = field.label;
            return acc;
        },
        {}
    );

/*
For a list of schema-defined field definitions, including reclass fields,
map field names to reclassed labels for easy lookup

Input:
    schema: a project schema object
    fieldnames: ["string"]
Output:
    { fieldName: reclassedFieldLabel, ... }
*/
export const getFieldNameReclassLabelDictionary = (schema, fieldnames) =>
    createNameLabelDictionary(pluckFieldDefinitionsByName(schema, fieldnames));

/*
For a list of schema-defined field definitions pluck out field names

Input:
    schema: a project schema object (e.g. observation schema, user schema)
Output:
    [ fieldNames ]
*/
export const pluckFieldNamesFromSchema = schema => {
    const { folders } = schema;

    const fields = flatMap(map(folders, folder => folder.fields));
    const fieldNames = map(fields, field => field.name);
    return fieldNames;
};

/*
Synthesize a minimum set of attributes to describe fields that are not
contained in the schema for station or observation, but instead attached
directly on station or observation data objects. The format matches the
`folder` format, so they can be combined directly with true schema elements.
*/
export const getSynthesizedFields = schema => {
    return [
        {
            label: 'Status',
            name: 'reviewStatus',
            type: 'Warning',
        },
        ...(schema.useStations
            ? [
                  {
                      label: 'Station Name',
                      name: 'stationName',
                      type: 'String',
                  },
              ]
            : []),
        {
            label: 'Observation Date',
            name: 'collectionDate',
            type: 'Date',
            description: 'When the observation was collected',
        },
        {
            label: 'Latitude',
            name: 'latitude',
            type: 'Number',
            precision: latLngPrecision,
            units: 'deg',
        },
        {
            label: 'Longitude',
            name: 'longitude',
            type: 'Number',
            precision: latLngPrecision,
            units: 'deg',
        },
        {
            label: 'Has Media',
            name: 'hasMedia',
            type: 'Boolean',
        },
    ];
};

// Generate a list of field name from the synthesized fields
export const getSynthesizedFieldNames = schema => {
    return getSynthesizedFields(schema).map(field => field.name);
};

export const getSynthesizedFieldLabelDictionary = schema =>
    getSynthesizedFields(schema).reduce((acc, f) => {
        acc[f.name] = f.label;
        return acc;
    }, {});

/*
Find a field within the schemas object and return that field's precision
or null if the field definition or precision values are not available
*/
export const getFieldPrecision = (schema, fieldName) => {
    const fieldDefinitions =
        schema &&
        pluckFieldDefinitionsByName(schema, [
            fieldName,
            // Latitude and Longitude have inconsistent capitalization in saved
            // visualization data so we include both options to ensure we find
            // the field
            fieldName?.toLowerCase(),
        ]);

    if (!fieldDefinitions || !fieldDefinitions.length) {
        return null;
    }

    return fieldDefinitions[0].precision;
};

export const userColumnLabels = (fields, userSchemaColumnMap) => {
    const lookup = {
        userId: 'User ID',
        userName: 'Username',
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        organization: 'Organization',
        status: 'Status',
        canManageProject: 'Can Manage Project',
        dateJoined: 'Date Joined',
        lastLogin: 'Last Login',
        ...userSchemaColumnMap,
    };

    return fields.map(f => (f in lookup ? lookup[f] : f));
};
