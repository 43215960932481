import apiRequest from '../util/apiRequest';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { Input, Form, Button, Alert } from 'antd';
import { makeAuthPasswordChangeUrl } from '../util/constants';

function AuthPasswordResetChangeForm() {
    const history = useHistory();
    const { hash } = useParams();

    const [alertMessage, setAlertMessage] = useState(null);

    const url = makeAuthPasswordChangeUrl(hash);
    useEffect(() => {
        apiRequest.get(url).then(response => {
            if (response?.data?.error) {
                setAlertMessage(response.data.error);
            }
        });
    }, [url]);
    const [changePasswordRequestState, changePasswordRequest] = useAsyncFn(
        async values => {
            const formData = new FormData();
            formData.set('password', values['password']);

            try {
                const response = await apiRequest.post(url, formData);
                const payload = response.data;
                if (payload.status === 200) {
                    if (!payload.error) {
                        history.replace(`/login/?success=password_reset`);
                    } else {
                        setAlertMessage(payload.error);
                    }
                } else if (payload.status === 400) {
                    setAlertMessage(payload.error);
                } else {
                    setAlertMessage(
                        'An error has occurred. Please try again or contact an administrator.'
                    );
                }
                return payload;
            } catch (ex) {
                setAlertMessage(
                    'An error has occurred. Please try again or contact an administrator.'
                );
            }
        },
        []
    );

    const handleFinish = values => {
        changePasswordRequest(values);
        setAlertMessage(null);
    };

    const alert = alertMessage && (
        <Alert
            message={alertMessage}
            type='info'
            showIcon
            closable
            style={{ margin: '10px 0' }}
        />
    );

    const passwordResetChangeForm = (
        <>
            {alert}
            <Form onFinish={handleFinish} className='login-form'>
                <label>
                    New password
                    <Form.Item
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>
                </label>
                <label>
                    Confirm password
                    <Form.Item
                        name='confirm'
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Please confirm your password!',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            'The two passwords that you entered do not match!'
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </label>
                <Form.Item className='auth-form__buttongroup'>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='auth-form-button'
                        loading={changePasswordRequestState.loading}
                    >
                        Change password
                    </Button>
                </Form.Item>
            </Form>
        </>
    );

    return passwordResetChangeForm;
}

export default AuthPasswordResetChangeForm;
