import React from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

function AuthRegistrationComplete() {
    const { message } = useSelector(state => state.auth.registration);

    return (
        <>
            <Paragraph>{message}</Paragraph>
            <Link to='/login' className='ant-btn ant-btn-primary'>
                Sign in
            </Link>
        </>
    );
}

export default AuthRegistrationComplete;
