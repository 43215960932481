import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { Button, message, Select } from 'antd';
import { identity, reduce } from 'lodash';

import DownloadButton from './DownloadButton';

import apiRequest from '../util/apiRequest';
import {
    makeUserApprovalUrl,
    makeProjectUsersUrl,
    defaultPageSize,
    managerUrl,
    roleUrl,
} from '../util/constants';
import {
    userCanAdminProject,
    userIsLoc,
    userCanDownload,
} from '../util/projectHelper';
import { completeFetchProject } from '../actions/project';
import { getCurrentSchema } from '../util/schemaHelper';

const { Option } = Select;

const ManageUsersTableDefinition = project => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const isOffline = useSelector(state => state.ui.isOffline);
    const schemas = useSelector(state => state.schema);
    const schema = getCurrentSchema(schemas);
    const userIsOwner = project?.data?.owner === user?.userId;
    const userIsAdmin = userCanAdminProject(user, project?.data);
    const userIsLOC = userIsLoc(user, project?.data);
    const isDownloadAllowed = userCanDownload({ user, project, isOffline });

    const userSchemaFields = project?.data?.userSchema?.folders[0]?.fields;
    const userSchemaColumns =
        userSchemaFields?.map(({ name, label, values }) => {
            const fieldDefn = {
                title: label,
                dataIndex: ['data', name],
                width: '10em',
            };

            // Attempt to translate options field value into its label
            if (values) {
                fieldDefn['render'] = v => {
                    return values.find(({ value }) => value === v)?.label || v;
                };
            }

            return fieldDefn;
        }) || [];

    const updateProjectManager = async u => {
        const payload = await apiRequest.post(managerUrl, {
            userId: u.userId,
        });
        if (payload.status === 200 && payload.data) {
            const updatedProject = payload.data.result;
            dispatch(completeFetchProject(updatedProject));
            message.success(`Updated ${u.email}'s manager status`);
        } else {
            message.error(`Failed to change ${u.email}'s manager status`);
        }
    };
    const makeProjectManagerStatus = u => {
        const managerStatus = userCanAdminProject(u, project.data)
            ? 'Yes'
            : 'No';
        return userIsOwner ? (
            <Button onClick={() => updateProjectManager(u)}>
                {managerStatus}
            </Button>
        ) : (
            managerStatus
        );
    };
    const updateProjectRole = async (u, roleId) => {
        const payload = await apiRequest.post(roleUrl, {
            userId: u.userId,
            roleId,
        });
        if (payload.status === 200 && payload.data) {
            // refetch users
            usersRequest();
            message.success(`Updated ${u.email}'s role`);
        } else {
            message.error(`Failed to change ${u.email}'s role`);
        }
    };
    const makeProjectRole = u => {
        const roles = u.project_relationships || [];

        const projectRoles = roles[project.name] || [{ role: '' }];
        const projectRole = projectRoles[0].role || '';

        return userIsAdmin ? (
            <Select
                style={{ minWidth: 180 }}
                value={projectRole}
                onChange={value => updateProjectRole(u, value)}
            >
                <Option value='' key='__novalue__'></Option>
                {project?.data?.roleChoices?.map(({ value, label }) => (
                    <Option value={value} key={value}>
                        {label}
                    </Option>
                ))}
            </Select>
        ) : (
            projectRole
        );
    };
    const toggleUserApprovalStatus = async u => {
        const payload = await apiRequest.post(makeUserApprovalUrl(u.userId));
        if (payload.status === 200 && payload.data) {
            // refresh project data in case an administrator has been deposed
            dispatch(completeFetchProject(payload.data.result));
            // refetch users
            usersRequest();
            message.success(`Updated ${u.email}'s approval`);
        } else {
            message.error(`Failed to change ${u.email}'s approval`);
        }
    };
    const makeUserApprovalStatus = u => {
        const uIsApproved = u?.project_relationships[project.name]?.some(
            pr => pr.approved
        );
        const approvalStatus = uIsApproved ? 'Approved' : 'Not approved';
        return (
            <Button onClick={() => toggleUserApprovalStatus(u)}>
                {approvalStatus}
            </Button>
        );
    };

    const userCSVFields = [
        'userId',
        'userName',
        'firstName',
        'lastName',
        'email',
        'organization',
        'status',
        project.data.enableRoles && 'project_role',
        'canManageProject',
        'dateJoined',
        'lastLogin',
        ...userSchemaColumns.map(sc => sc.dataIndex.join('-')),
    ].filter(identity);

    const userTableColumns = [
        {
            title: 'Name',
            key: 'id',
            render: data => (
                <>
                    {data.firstName} {data.lastName}
                </>
            ),
            orderKey: 'first_name,last_name',
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'id',
            sorter: true,
            orderKey: 'profile__organization',
        },
        {
            title: 'Status',
            key: 'id',
            render: makeUserApprovalStatus,
        },
        project.data.enableRoles && {
            title: 'Project role',
            key: 'id',
            render: makeProjectRole,
        },
        {
            title: 'Can manage project',
            key: 'id',
            render: makeProjectManagerStatus,
            width: '10em',
        },
        {
            title: 'Date joined',
            dataIndex: 'dateJoined',
            orderKey: 'date_joined',
            sorter: true,
            width: '10em',
        },
        {
            title: 'Last login',
            dataIndex: 'lastLogin',
            orderKey: 'last_login',
            sorter: true,
            width: '10em',
        },
        ...userSchemaColumns,
    ].filter(identity);
    // This final filter statement allows allows us to adding conditionally by
    // setting them to a falsey value.

    // Maps name to title for project specific user fields
    const userSchemaColumnMap = reduce(
        userSchemaColumns,
        (obj, item) => {
            obj[item['dataIndex'].join('-')] = item['title'];
            return obj;
        },
        {}
    );

    const [usersTablePageSize, setUsersTablePageSize] = useState(
        defaultPageSize
    );
    const [usersTablePage, setUsersTablePage] = useState(1);
    const [usersTableQ, setUsersTableQ] = useState(null);
    const [usersTableOrder, setUsersTableOrder] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [usersRequestState, usersRequest] = useAsyncFn(async () => {
        const response = await apiRequest.get(
            makeProjectUsersUrl(project?.name),
            {
                params: {
                    page: usersTablePage,
                    pageSize: usersTablePageSize,
                    q: usersTableQ || undefined,
                    order: usersTableOrder || undefined,
                },
            }
        );
        const payload = response.data;

        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200 && payload.result) {
            setDataSource(payload.result.users);
            return payload.result;
        }
        setDataSource([]);
        return payload;
    }, [usersTablePageSize, usersTablePage, usersTableQ, usersTableOrder]);

    useEffect(() => {
        if (userIsAdmin || userIsLOC) {
            usersRequest();
        }
    }, [
        userIsAdmin,
        userIsLOC,
        usersRequest,
        usersTablePage,
        usersTablePageSize,
        usersTableQ,
        usersTableOrder,
    ]);

    if (usersRequestState.error) {
        return <div>Oops there was an error loading this page.</div>;
    }

    const onUsersTableAction = (pagination, filters, sorter) => {
        setUsersTablePage(pagination.current);
        setUsersTablePageSize(pagination.pageSize);

        if (sorter?.column) {
            setUsersTableOrder(
                `${sorter.order === 'descend' ? '-' : ''}${sorter.column
                    .orderKey || sorter.column.dataIndex}`
            );
        } else {
            setUsersTableOrder(null);
        }
    };

    const total = usersRequestState.value?.total;

    const downloadButton = (
        <DownloadButton
            filterSetName='Users'
            isDownloadAllowed={isDownloadAllowed}
            schema={schema}
            elementName='user'
            dispatch={dispatch}
            filters={{
                table: 'Users',
                fields: userCSVFields,
                q: usersTableQ || undefined,
                order: usersTableOrder || undefined,
            }}
            userSchemaColumnMap={userSchemaColumnMap}
            isTableVisible
        />
    );

    return {
        title: 'Users',
        tab: 'Users',
        type: 'paginatedTable',
        columns: userTableColumns,
        dataSource: dataSource,
        page: usersTablePage,
        pageSize: usersTablePageSize,
        total: total,
        loading: usersRequestState.loading,
        onChange: onUsersTableAction,
        onSearch: setUsersTableQ,
        downloadButton,
    };
};

export default ManageUsersTableDefinition;
