import React from 'react';
import PropTypes from 'prop-types';

const WidgetToolbar = ({ left, right, center }) => {
    return (
        <div className='widget-toolbar'>
            {left && <div className='widget-toolbar__section left'>{left}</div>}
            {center && (
                <div className='widget-toolbar__section center'>{center}</div>
            )}
            {right && (
                <div className='widget-toolbar__section right'>{right}</div>
            )}
        </div>
    );
};

WidgetToolbar.propTypes = {
    left: PropTypes.node,
    right: PropTypes.node,
    center: PropTypes.node,
};

export default WidgetToolbar;
