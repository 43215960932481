import React, { useEffect } from 'react';
import { Button, Typography, Progress, Space, Divider } from 'antd';

const { Text } = Typography;

function DownloadProgress({
    percentCompleted,
    isCanceled,
    hasError,
    filename,
    fileFormat,
    isFirst,
    handleCancel,
    handleRetry,
    removeHiddenItem,
}) {
    const isCompleted = percentCompleted === 100 && !hasError;
    const displayName = `${filename}.${fileFormat}`;
    useEffect(() => {
        let timeout;
        // If the item is completed or canceled, wait for it to fade then remove
        if (isCanceled || isCompleted) {
            timeout = setTimeout(() => {
                removeHiddenItem(filename);
                // The timeout delay needs to match the length of the .fade CSS transition
            }, 2000);
        }

        // Remove timeout on component dismount
        return () => clearTimeout(timeout);
    }, [isCanceled, isCompleted, filename, removeHiddenItem]);

    let text = `Preparing: '${displayName}'`;
    if (hasError) {
        text = `'${displayName}' file preparation failed`;
    }
    if (isCompleted) {
        text = `'${displayName}'`;
    }

    return (
        <Space
            direction='vertical'
            className={`download-progress-item ${
                isCanceled || isCompleted ? 'fade' : ''
            }`}
            key={filename}
        >
            {!isFirst && <Divider className='download-progress-divider' />}
            <Text className={hasError ? 'download-progress-error-text' : ''}>
                {text}
            </Text>
            <Progress
                percent={percentCompleted || 0}
                strokeWidth='15px'
                status={
                    hasError ? 'exception' : isCompleted ? 'success' : 'normal'
                }
            />
            <Space>
                <Button
                    onClick={() => handleCancel(filename, fileFormat)}
                    disabled={isCompleted}
                >
                    Cancel
                </Button>
                {hasError && (
                    <Button
                        onClick={() => handleRetry(filename)}
                        type='primary'
                    >
                        Retry
                    </Button>
                )}
            </Space>
        </Space>
    );
}

export default DownloadProgress;
