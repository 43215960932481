import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import { hardcodedMyOfflineFilterSetName } from '../util/constants';
import { setActiveFilterSetName } from '../actions/filterSet';

const OfflineBanner = ({
    isOffline,
    isLoggedIn,
    hasOfflineObservations,
    isDataLoaded,
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const offlineDataLoadedBanner = (
        <div className='offline-top-banner'>
            <FontAwesomeIcon icon={['far', 'wifi-slash']} />
            <div className='offline-top-banner__message'>
                <b>It looks like you're offline. </b>
                You can save observations on your device and upload them later.
            </div>
        </div>
    );

    const offlineNoDataLoadedBanner = (
        <div className='offline-top-banner'>
            <FontAwesomeIcon icon={['far', 'wifi-slash']} />
            <div className='offline-top-banner__message'>
                <b>It looks like you're offline. </b>
                To use Fieldscope offline you need to reconnect to the internet
                and refresh to download offline data.
            </div>
            <Button
                className='offline__button'
                // TODO: Add a handler that goes to documentation
                onClick={() => {}}
            >
                Learn more
            </Button>
        </div>
    );

    const offlineNotLoggedInBanner = (
        <div className='offline-top-banner'>
            <FontAwesomeIcon icon={['far', 'wifi-slash']} />
            <div className='offline-top-banner__message'>
                <b>It looks like you're offline. </b>
                Reconnect to the internet and log in to save observations on
                your device to upload later.
            </div>
        </div>
    );

    const onlineSavedObservationsBanner = (
        <div className='offline-top-banner'>
            <b>You have offline draft observations saved on your device.</b>
            <Button
                className='offline__button'
                onClick={() => {
                    dispatch(
                        setActiveFilterSetName(hardcodedMyOfflineFilterSetName)
                    );
                    history.push('/observations');
                }}
            >
                See drafts
            </Button>
        </div>
    );

    const getOfflineBanner = () => {
        if (isLoggedIn) {
            if (isOffline) {
                if (isDataLoaded) {
                    return offlineDataLoadedBanner;
                }
                return offlineNoDataLoadedBanner;
            } else {
                if (hasOfflineObservations) {
                    return onlineSavedObservationsBanner;
                }
            }
        } else {
            if (isOffline) {
                return offlineNotLoggedInBanner;
            }
        }
        return null;
    };

    return getOfflineBanner();
};

export default OfflineBanner;
