import update from 'immutability-helper';
import { filter, flatMap } from 'lodash';
import { mediaTypes, mediaPropertyNames } from '../util/constants';

/*
  Given a list of observations return a dictionary where the keys are media
  property names and the values are the media items of that type collected from
  all the observations.

  If there is no media, a dictionary with empty arrays will be returned. For
  example:

  {
    photos: [],
    videos: [],
    audio: [],
    documents: []
  }
*/
export const makeMediaDict = observations => {
    const emptyMediaDict = Object.entries(mediaPropertyNames).reduce(
        (acc, [_, propName]) => update(acc, { [propName]: { $set: [] } }),
        {}
    );
    return Object.entries(mediaPropertyNames).reduce(
        (acc, [mediaType, propName]) =>
            update(acc, {
                [propName]: {
                    $push: filter(
                        flatMap(observations, mediaPropertyNames[mediaType])
                    ),
                },
            }),
        emptyMediaDict
    );
};

/*
  Given an observation object return an new object where the contents
  of the `media` attribute have been separated into individual attributes for
  each media type.
  */
export const separateObservationMedia = observation =>
    [mediaTypes.document, mediaTypes.audio, mediaTypes.video].reduce(
        (acc, type) => {
            acc[mediaPropertyNames[type]] = filter(
                observation.media,
                media => media.type === type
            );
            return acc;
        },
        {}
    );
