import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconBadge = ({ icon, ...props }) => {
    return (
        <div className='icon-badge ' {...props}>
            <FontAwesomeIcon icon={icon} />
        </div>
    );
};

IconBadge.propTypes = {
    icon: PropTypes.array,
};

export default IconBadge;
