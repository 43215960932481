import React from 'react';
import { Modal } from 'antd';
import { func, string } from 'prop-types';

import MediaBrowser from './MediaBrowser';
import { pauseAllMediaTags } from '../util/domHelper';

// After opening a modal from a drawer, the body is no longer scrollable because
// `overflow: hidden` is added to `<body>`. This is an open issue in the Ant
// Design repo (https://github.com/ant-design/ant-design/issues/21539) We work
// around this bug by removing the overflow property.
const workaroundBodyOverflowBug = () =>
    document.body.style.removeProperty('overflow');

const afterClose = () => {
    workaroundBodyOverflowBug();
    pauseAllMediaTags();
};

const MediaBrowserModal = props => {
    return (
        <Modal
            open={!!props.activeType}
            onCancel={() => props.onTypeChange(null)}
            onOk={() => props.onTypeChange(null)}
            footer={null}
            width='90%'
            afterClose={afterClose}
        >
            <MediaBrowser {...props}></MediaBrowser>
        </Modal>
    );
};

MediaBrowserModal.propTypes = {
    activeType: string,
    onTypeChange: func.isRequired,
};

export default MediaBrowserModal;
