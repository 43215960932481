import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    startFetchUser,
    completeFetchUser,
    loginUser,
    logoutUser,
    registerUser,
    updateUser,
    setRedirectPath,
} from '../actions/auth';

export const initialUserState = {
    fetching: false,
    isLoggedIn: false,
    user: null,
    registration: {
        message: null,
    },
    redirectPath: null,
    previousUserId: null,
};

const authReducer = createReducer(
    {
        [startFetchUser]: (state, payload) =>
            update(state, {
                fetching: { $set: true },
            }),
        [completeFetchUser]: (state, payload) =>
            update(state, {
                fetching: { $set: false },
            }),
        [loginUser]: (state, payload) =>
            update(state, {
                fetching: { $set: false },
                isLoggedIn: {
                    $set: true,
                },
                user: {
                    $set: payload,
                },
            }),
        [logoutUser]: state =>
            update(state, {
                fetching: { $set: false },
                isLoggedIn: {
                    $set: false,
                },
                user: {
                    $set: null,
                },
                previousUserId: { $set: state.user.userId },
            }),
        // TODO: Add an action to clear the registration message
        [registerUser]: (state, payload) =>
            update(state, {
                registration: {
                    message: {
                        $set: payload,
                    },
                },
            }),
        [updateUser]: (state, payload) =>
            update(state, {
                user: {
                    $set: payload,
                },
            }),
        [setRedirectPath]: (state, payload) =>
            update(state, { redirectPath: { $set: payload } }),
    },
    initialUserState
);

export default authReducer;
