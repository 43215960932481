import React, { useState } from 'react';
import apiRequest from '../util/apiRequest';
import { AutoComplete, message } from 'antd';

import { makeGeocodeUrl, makeSuggestUrl } from '../util/constants';

const Geocoder = ({ onGeocode, dataExtent = [] }) => {
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([]);

    const onSearch = async text => {
        try {
            const req = await apiRequest.get(makeSuggestUrl(text, dataExtent));
            setOptions(
                req.data?.suggestions?.map(suggestion => ({
                    value: suggestion.magicKey,
                    label: suggestion.text,
                })) || []
            );
        } catch (error) {
            console.error('An error occurred while suggesting:', error);
        }
    };

    const onSelect = async magicKey => {
        try {
            const req = await apiRequest.get(
                makeGeocodeUrl(searchText, magicKey, dataExtent)
            );
            if (req.status !== 200 || !req.data?.candidates?.length) {
                // Ensure that the 'No candidates' error message makes it to the user
                message.error('No candidates found within project extent');
            } else {
                const { address, location } = req.data.candidates[0];
                const searchPoint = {
                    latitude: location.y,
                    longitude: location.x,
                };
                setSearchText(address);
                onGeocode(searchPoint);
            }
        } catch (error) {
            console.error('An error occurred while geocoding:', error);
            message.error('There was a geocoding error.');
        }
    };

    return (
        <AutoComplete
            value={searchText}
            onChange={setSearchText}
            options={options}
            style={{ width: 200 }}
            placeholder='Location search'
            onSearch={onSearch}
            onSelect={onSelect}
        />
    );
};

export default Geocoder;
