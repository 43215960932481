import React from 'react';
import DOMPurify from 'dompurify';

const SafeHtml = ({ html }) => (
    <span
        dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(html),
        }}
    />
);

export default SafeHtml;
