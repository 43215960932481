import React from 'react';
import { BaseControl } from 'react-map-gl';
import { array, func, object } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    Checkbox,
    Menu,
    Popover,
    Radio,
    Slider,
    Space,
    Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import { basemap } from '../util/propTypes';

// We use a class-based component here so that we can override the `BaseControl`
// provided by react-map-gl, which handles intercepting click events.
class ObservationMapLayerSelector extends BaseControl {
    constructor(props) {
        super(props);
        this.state = { openSubMenuKeys: ['basemaps'] };
    }

    _render() {
        const {
            basemaps,
            layerFolders,
            activeDataLayerIds,
            selectedBasemap,
            layerOpacity,
            onBasemapChange,
            onToggleDataLayer,
            onLayerOpacityChange,
        } = this.props;

        const { openSubMenuKeys } = this.state;

        const activeBasemap = selectedBasemap || basemaps[0];

        const makeDetailTooltip = ({ description }) => (
            <span>
                <Tooltip title={description} trigger={['hover', 'click']}>
                    <QuestionCircleOutlined />
                </Tooltip>
            </span>
        );

        const rootSubMenuKeys = [
            'basemaps',
            ...layerFolders.map(({ id }) => id.toString()),
        ];

        const onOpenChange = newOpenSubMenuKeys => {
            const latestOpenKey = newOpenSubMenuKeys.find(
                key => openSubMenuKeys.indexOf(key) === -1
            );
            if (rootSubMenuKeys.indexOf(latestOpenKey) === -1) {
                this.setState({ openSubMenuKeys: newOpenSubMenuKeys });
            } else {
                this.setState({
                    openSubMenuKeys: latestOpenKey ? [latestOpenKey] : [],
                });
            }
        };

        const makeDataLayerClickHandler = layerId => () =>
            onToggleDataLayer(layerId);

        const makeOpacityChangeHandler = layerId => value =>
            onLayerOpacityChange(layerId, value);

        const layerMenu = (
            <Menu
                mode='inline'
                openKeys={openSubMenuKeys}
                onOpenChange={onOpenChange}
                inlineIndent={6}
                style={{
                    border: 'none',
                }}
                items={[
                    {
                        key: 'basemaps',
                        label: 'Basemaps',
                        children: basemaps.map(item => {
                            return {
                                key: item.label,
                                label: (
                                    <Space size='small'>
                                        <Radio
                                            onClick={() =>
                                                onBasemapChange(item)
                                            }
                                            checked={
                                                item.label ===
                                                activeBasemap.label
                                            }
                                        >
                                            {item.label}
                                        </Radio>
                                        {makeDetailTooltip(item)}
                                    </Space>
                                ),
                            };
                        }),
                    },
                    ...layerFolders.map(folder => ({
                        key: folder.id,
                        label: folder.label,
                        children: folder.layerDefinitions.map(layer => ({
                            key: layer.id,
                            style: { height: 'auto' },
                            label: (
                                <>
                                    <Space size='small'>
                                        <Checkbox
                                            checked={activeDataLayerIds.includes(
                                                layer.id
                                            )}
                                            onClick={makeDataLayerClickHandler(
                                                layer.id
                                            )}
                                        >
                                            {layer.label}
                                        </Checkbox>
                                        {makeDetailTooltip(layer)}
                                    </Space>
                                    {activeDataLayerIds.includes(layer.id) && (
                                        <div>
                                            <Slider
                                                value={layerOpacity[layer.id]}
                                                min={1}
                                                defaultValue={100}
                                                onChange={makeOpacityChangeHandler(
                                                    layer.id
                                                )}
                                            />
                                        </div>
                                    )}
                                </>
                            ),
                        })),
                    })),
                ]}
            />
        );

        return (
            <div
                ref={this._containerRef}
                className='mapboxgl-ctrl mapboxgl-ctrl-group '
            >
                <Popover
                    content={layerMenu}
                    trigger='click'
                    placement='bottomRight'
                >
                    <Button size='large' title='Layers'>
                        <FontAwesomeIcon icon={['far', 'map']} />
                    </Button>
                </Popover>
            </div>
        );
    }
}

ObservationMapLayerSelector.propTypes = {
    selectedBasemap: basemap.isRequired,
    layerOpacity: object,
    basemaps: array.isRequired,
    layerFolders: array,
    activeDataLayerIds: array.isRequired,
    onBasemapChange: func.isRequired,
    onToggleDataLayer: func.isRequired,
    onLayerOpacityChange: func.isRequired,
};

ObservationMapLayerSelector.defaultProps = {
    layerFolders: [],
    layerOpacity: {},
};

export default ObservationMapLayerSelector;
