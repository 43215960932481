import apiRequest from '../util/apiRequest';
import { createAction } from 'redux-act';

import { getIdbStationsUrl, makeStationsUrl } from '../util/constants';
import {
    logErrorAndDispatchFailure,
    dispatchApiCompleteOrFail,
    shouldSupportOffline,
    isServiceWorkerActivated,
} from '../util';

export const startFetchStations = createAction('Start fetch stations');
export const completeFetchStations = createAction('Complete fetch stations');
export const failFetchStations = createAction('Fail fetch stations');
export const clearStations = createAction('Clear stations data');

export const addStation = createAction('Add station');
export const updateStation = createAction('Update station');
export const addUserToStation = createAction('Add user to station');

export function fetchStations(schemaName) {
    return async (dispatch, getState) => {
        const {
            stations: { fetching },
        } = getState();

        if (fetching) return;

        dispatch(startFetchStations());
        try {
            if (shouldSupportOffline && isServiceWorkerActivated()) {
                const stationDataResponses = await Promise.all([
                    apiRequest.get(makeStationsUrl(schemaName)),
                    apiRequest.get(getIdbStationsUrl),
                ]);
                const combinedOnlineOfflineStations = stationDataResponses.flatMap(
                    r => r.data?.result
                );
                const wasSuccessful = stationDataResponses.every(
                    r => r.data?.status === 200
                );
                dispatchApiCompleteOrFail(
                    dispatch,
                    completeFetchStations,
                    failFetchStations,
                    {
                        status: wasSuccessful ? 200 : 500,
                        result: combinedOnlineOfflineStations,
                    }
                );
            } else {
                const response = await apiRequest.get(
                    makeStationsUrl(schemaName)
                );
                dispatchApiCompleteOrFail(
                    dispatch,
                    completeFetchStations,
                    failFetchStations,
                    response.data
                );
            }
        } catch (e) {
            dispatch(
                logErrorAndDispatchFailure(
                    e,
                    `An error prevented fetching the stations for schema ${schemaName}`,
                    failFetchStations
                )
            );
        }
    };
}
