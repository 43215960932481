import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Result, Button, message } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import apiRequest from '../util/apiRequest';

import { requestProjectAccessUrl } from '../util/constants';
import { setRedirectPath } from '../actions/auth';
import { userApprovalPending } from '../util/projectHelper';

const pendingState = {
    title: 'Your request is pending.',
    subtitle: 'A member of the project team will review your request.',
    extra: (
        <>
            <p>
                <Button type='primary'>
                    <Link to='/'>Back Home</Link>
                </Button>
            </p>
        </>
    ),
};

const FourOhThree = ({
    title,
    subtitle,
    extra,
    participationRequiresApproval = false,
}) => {
    const user = useSelector(state => state.auth.user);
    const project = useSelector(state => state.project);

    const history = useHistory();
    const dispatch = useDispatch();

    const pathname = history.location.pathname;

    const [state, setState] = useState({
        title,
        subtitle,
        extra,
    });

    useEffect(() => {
        const isUserPending = userApprovalPending(user, project);
        isUserPending && setState(pendingState);
    }, [user, project]);

    const handleParticipateClick = async () => {
        try {
            const response = await apiRequest.post(requestProjectAccessUrl);
            const payload = response.data;
            if (!payload.result || !payload.status === 200) {
                throw new Error(payload.error);
            }
            if (payload.result.approved) {
                window.location.reload();
            } else {
                setState(pendingState);
            }
        } catch {
            message.error(
                'The request to participate failed. Please try again or contact us at info@fieldscope.org'
            );
        }
    };

    const titleContent =
        state.title || (user ? 'Not Approved' : 'Not Logged In');
    const subtitleContent =
        state.subtitle ||
        (user
            ? 'Sorry, you are not approved to access this page.'
            : 'Sorry, you need to log in to access this page.');
    const extraContent =
        state.extra ||
        (user ? (
            <>
                <p>
                    {participationRequiresApproval
                        ? 'You need to be a participant in this project to add data. If you would like to participate in this project, please click the button below. A project coordinator will approve your request.'
                        : 'If you would like to participate in this project, please click the button below:'}
                </p>
                <p>
                    <Button type='primary' onClick={handleParticipateClick}>
                        Participate
                    </Button>
                </p>
                <p>Otherwise you can return to the project home page:</p>
                <p>
                    <Button type='primary'>
                        <Link to='/'>Back Home</Link>
                    </Button>
                </p>
            </>
        ) : (
            <>
                <p>
                    Log in or register a new account by clicking the button
                    below:
                </p>
                <p>
                    <Button
                        type='primary'
                        onClick={() => dispatch(setRedirectPath(pathname))}
                    >
                        <Link to='/login'>Log In</Link>
                    </Button>
                </p>
                <p>Otherwise you can return to the project home page:</p>
                <p>
                    <Button type='primary'>
                        <Link to='/'>Back Home</Link>
                    </Button>
                </p>
            </>
        ));
    return (
        <Result
            status='403'
            title={titleContent}
            subTitle={subtitleContent}
            extra={extraContent}
        />
    );
};

export default FourOhThree;
