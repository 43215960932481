import { createAction } from 'redux-act';
import apiRequest from '../util/apiRequest';

import { shouldSupportOffline, isServiceWorkerActivated } from '../util';
import { getIdbObservationsUrl } from '../util/constants';

export const setBasemap = createAction('Set basemap');
export const toggleDataLayer = createAction('Toggle data layer');
export const setLayerOpacity = createAction('Set layer opacity');
export const setEmbed = createAction('Set embed');
export const setOffline = createAction('Set offline');
export const setHasOfflineObservations = createAction(
    'Set has offline observations'
);
export const setOptOutDownloadInfo = createAction(
    'Set that user has opted out of the large download info'
);
export const setObservationEditLocation = createAction(
    'Set the location from which observation editing starts'
);

export function checkIfOfflineObservations() {
    return async (dispatch, getState) => {
        const {
            auth: { user },
        } = getState();

        if (shouldSupportOffline && isServiceWorkerActivated() && user) {
            const response = await apiRequest.get(getIdbObservationsUrl);
            const userOwnsObservations = response.data?.result?.some(
                o => o.ownerId === user.userId
            );
            return dispatch(setHasOfflineObservations(userOwnsObservations));
        } else {
            return dispatch(setHasOfflineObservations(false));
        }
    };
}

export const setOfflineIfChanged = newOffline => (dispatch, getState) => {
    const {
        ui: { isOffline },
    } = getState();
    if (newOffline === isOffline) return;
    dispatch(setOffline(newOffline));
};

const manualOnlineEvent = new Event('manual-online');
export function setOnlineStatus() {
    return async dispatch => {
        // If the browser says we are offline, set status to offline
        if (!navigator?.onLine) {
            dispatch(setOfflineIfChanged(true));
        } else {
            // Some browsers incorrectly say we are online when we aren't.
            // Mnaually confirm connection.

            // Avoid CORS errors with a request to current origin
            let url = new URL(window?.location?.origin);

            /*
            Random value in query to prevent cached responses.
            The query param key, 'test_offline', allows the service worker to
            identify this request. Without an identifier, the service worker
            would make this response succeed regardless of internet connectivity.
            */
            url.searchParams.set('test_offline', Date.now());

            try {
                // Make a GET request to root
                const response = await fetch(url.toString(), {
                    method: 'HEAD',
                });
                const body = await response.text();
                const isOffline = !response.ok || body === 'false';

                if (!isOffline) {
                    window.dispatchEvent(manualOnlineEvent);
                }

                dispatch(setOfflineIfChanged(isOffline));
            } catch {
                dispatch(setOfflineIfChanged(true));
            }
        }
    };
}
