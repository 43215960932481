import React from 'react';
import { func } from 'prop-types';
import { BaseControl } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover, Divider } from 'antd';

import Geocoder from './Geocoder';

// We use a class-based component here so that we can override the `BaseControl`
// provided by react-map-gl, which handles intercepting click events.
class MapGeocoder extends BaseControl {
    _render() {
        const content = (
            <>
                Location search
                <Divider style={{ margin: '4px' }} />
                <Geocoder onGeocode={this.props.onGeocode} />
            </>
        );

        return (
            <div
                ref={this._containerRef}
                className='mapboxgl-ctrl mapboxgl-ctrl-group '
            >
                <Popover
                    content={content}
                    trigger='click'
                    placement='bottomRight'
                >
                    <Button size='large' title='Location Search'>
                        <FontAwesomeIcon icon={['far', 'search']} />
                    </Button>
                </Popover>
            </div>
        );
    }
}

MapGeocoder.propTypes = {
    onGeocode: func,
};

export default MapGeocoder;
