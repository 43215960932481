import { createAction } from 'redux-act';
import apiRequest from '../util/apiRequest';

import { userUrl } from '../util/constants';

import { clearFilterSetData } from './filterSet';
import { clearStations } from './stations';

export const startFetchUser = createAction('Start fetch user');
export const completeFetchUser = createAction('Complete fetch user');
export const loginUser = createAction('Login user');
export const logoutUser = createAction('Logout current user');
export const registerUser = createAction('Register user');
export const updateUser = createAction('Update user');
export const setRedirectPath = createAction('Set path to redirect to');

export const handleLogoutUser = () => {
    return dispatch => {
        dispatch(logoutUser());
        dispatch(clearFilterSetData());
    };
};

export function getUser() {
    return async (dispatch, getState) => {
        const {
            auth: { fetching },
        } = getState();

        if (fetching) return;

        dispatch(startFetchUser());
        const response = await apiRequest.get(userUrl);
        const payload = response.data;
        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200 && payload.result) {
            // Save the user object
            dispatch(loginUser(payload.result));

            // Clear cached station data if the user differs since last login
            const {
                auth: { previousUserId },
            } = getState();
            if (payload.result.userId !== previousUserId) {
                dispatch(clearStations());
            }
        }
        dispatch(completeFetchUser());
    };
}
