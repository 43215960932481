import { message } from 'antd';

const authMessages = {
    success: {
        activation: 'Account activation successful!',
        authorization: 'Account authorization successful!',
        password_reset: 'Password reset successful!',
    },
    error: {
        invalid_authorization:
            'That activation key does not appear to exist. The request may have already been activated.',
        invalid_activation:
            'That activation key does not appear to exist. Please double-check the link URL.',
        expired: 'That activation key has expired.',
        unknown: 'An unknown error occurred.',
    },
};

// Seconds for which any auth message will be shown
const authMessageShownDuration = 6;

/**
 * Given a URLSearchParams object, if there are any "success" or "error"
 * params, and have values found in the `authMessages` dictionary above,
 * shows those messages using the antd.message component.
 *
 * @param {URLSearchParams} params
 */
export function showAuthMessages(params) {
    for (const key in authMessages) {
        const param = params.get(key);
        const msg = authMessages[key]?.[param];
        if (msg) {
            message[key](msg, authMessageShownDuration);
        }
    }
}
