import { createAction } from 'redux-act';
import apiRequest from '../util/apiRequest';

import { logErrorAndDispatchFailure, dispatchApiCompleteOrFail } from '../util';
import { makeObservationUrl } from '../util/constants';

export const startDeleteObservation = createAction(
    'Start deleting observation'
);
export const completeDeleteObservation = createAction(
    'Complete deleting observation'
);
export const completeDeleteStationlessObservation = createAction(
    'Complete deleting observation and station'
);
export const failDeleteObservation = createAction('Fail deleting observation');

export function deleteObservation(observation, useStations) {
    return dispatch => {
        dispatch(startDeleteObservation(observation));
        return apiRequest
            .delete(makeObservationUrl(observation.observationId))
            .then(({ data }) => {
                const completeDeleteObservationCallback = useStations
                    ? completeDeleteObservation
                    : completeDeleteStationlessObservation;

                return dispatchApiCompleteOrFail(
                    dispatch,
                    completeDeleteObservationCallback,
                    failDeleteObservation,
                    data
                );
            })
            .catch(e =>
                dispatch(
                    logErrorAndDispatchFailure(
                        e,
                        `An error occurred while deleting observation ${observation.observationId}`,
                        failDeleteObservation
                    )
                )
            );
    };
}
