import apiRequest from '../util/apiRequest';
import { createAction } from 'redux-act';

import { makeOrganizationsUrl } from '../util/constants';
import { logErrorAndDispatchFailure, dispatchApiCompleteOrFail } from '../util';

export const startFetchOrganizations = createAction(
    'Start fetch organizations'
);
export const completeFetchOrganizations = createAction(
    'Complete fetch organizations'
);
export const failFetchOrganizations = createAction('Fail fetch organizations');
export const clearOrganizations = createAction('Clear organizations data');

export function fetchOrganizations(projectHostname) {
    return async dispatch => {
        dispatch(startFetchOrganizations());
        try {
            const response = await apiRequest.get(
                makeOrganizationsUrl(projectHostname)
            );
            dispatchApiCompleteOrFail(
                dispatch,
                completeFetchOrganizations,
                failFetchOrganizations,
                response.data
            );
        } catch (e) {
            dispatch(
                logErrorAndDispatchFailure(
                    e,
                    `An error prevented fetching the organizations for schema ${projectHostname}`,
                    failFetchOrganizations
                )
            );
        }
    };
}
