import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { userApprovalPending, userIsApproved } from '../util/projectHelper';
import FourOhThree from './403';
import OverlaySpinner from './OverlaySpinner';

// Using ideas outlined in https://ui.dev/react-router-v5-protected-routes-authentication/

const PrivateRoute = ({ children, userIsAllowed = () => true, ...rest }) => {
    const project = useSelector(state => state.project);
    const auth = useSelector(state => state.auth);

    // If the user or project is fetching, show the spinner
    // If userIsAllowed returns falsey, show a generic forbidden message
    // If the project is public, or the user is approved, show the children
    // Else show a 403 component which defaults to inviting the user to participate.
    return (
        <Route
            {...rest}
            render={() => {
                if (project.fetching || auth.fetching) {
                    return <OverlaySpinner show={true} />;
                }

                if (
                    userIsApproved(auth.user, project) &&
                    userIsAllowed(auth.user, project)
                ) {
                    return children;
                }
                return !userIsApproved(auth.user, project) ||
                    userApprovalPending(auth.user, project) ? (
                    <FourOhThree />
                ) : (
                    <FourOhThree
                        title='This page is not accessible'
                        subtitle='You do not have the required permission to view this page'
                        extra={
                            <div>
                                If you think that you are seeing this page in
                                error, please contact{' '}
                                <a href='mailto:info@fieldscope.org'>
                                    info@fieldscope.org{' '}
                                </a>
                            </div>
                        }
                    />
                );
            }}
        />
    );
};

PrivateRoute.propTypes = {
    userIsAllowed: PropTypes.func,
};

export default PrivateRoute;
