import React, { useState } from 'react';
import { array } from 'prop-types';
import { Button, Space, Avatar, Tooltip } from 'antd';
import { filter } from 'lodash';
import {
    mediaTypes,
    mediaPropertyNames,
    mediaCategoryNames,
} from '../util/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeMediaDict } from '../util/mediaHelper';
import MediaBrowserModal from './MediaBrowserModal';

function MediaLauncher({ observations }) {
    const media = makeMediaDict(observations);

    const photoButton = media.photos?.length ? (
        <li
            className='observation-drawer__detail--item'
            key='mediaButtons_photo'
        >
            <span onClick={() => setBrowsingMediaType(mediaTypes.photo)}>
                <Tooltip title='Browse photos'>
                    <Avatar
                        shape='square'
                        size={48}
                        src={media.photos[0].url}
                        style={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            </span>
        </li>
    ) : null;

    const makeMediaListItemButton = (key, iconName) =>
        media[mediaPropertyNames[key]] &&
        media[mediaPropertyNames[key]].length ? (
            <li
                className='observation-drawer__detail--item'
                key={`mediaButtons_${key}`}
            >
                <Tooltip title={`Browse ${mediaCategoryNames[key]}`}>
                    <Button
                        style={{ width: '48px', height: '48px' }}
                        onClick={() => setBrowsingMediaType(key)}
                    >
                        <FontAwesomeIcon icon={['far', iconName]} />
                    </Button>
                </Tooltip>
            </li>
        ) : null;

    const mediaButtons = filter([
        photoButton,
        makeMediaListItemButton(mediaTypes.video, 'video'),
        makeMediaListItemButton(mediaTypes.audio, 'volume-up'),
        makeMediaListItemButton(mediaTypes.document, 'file'),
    ]);

    const [browsingMediaType, setBrowsingMediaType] = useState(null);

    const mediaBrowserModal = (
        <MediaBrowserModal
            observations={[media]}
            activeType={browsingMediaType}
            onTypeChange={setBrowsingMediaType}
        ></MediaBrowserModal>
    );

    return (
        <ul className='observation-drawer__media'>
            <label className='ant-typography label'>Media</label>
            {mediaButtons.length > 0 ? (
                <Space style={{ marginTop: '-10px' }}>{mediaButtons}</Space>
            ) : (
                <span className='ant-typography value'>None</span>
            )}
            {mediaBrowserModal}
        </ul>
    );
}

MediaLauncher.propTypes = {
    observations: array.isRequired,
};

export default MediaLauncher;
