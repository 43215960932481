import apiRequest from '../util/apiRequest';
import { createAction } from 'redux-act';

import { makeProjectUrl } from '../util/constants';
import { logErrorAndDispatchFailure, dispatchApiCompleteOrFail } from '../util';

export const startFetchProject = createAction('Start fetch project');
export const completeFetchProject = createAction('Complete fetch project');
export const failFetchProject = createAction('Fail fetch project');

export function fetchProject(name) {
    return async dispatch => {
        dispatch(startFetchProject(name));
        try {
            const response = await apiRequest.get(makeProjectUrl(name));
            dispatchApiCompleteOrFail(
                dispatch,
                completeFetchProject,
                failFetchProject,
                response.data
            );
        } catch (e) {
            logErrorAndDispatchFailure(
                e,
                `An error prevented fetching the details for project ${name}`,
                failFetchProject
            );
        }
    };
}
