import axios from 'axios';
import { message } from 'antd';

import {
    observationUrl,
    stationUrl,
    hardcodedMyOfflineFilterSetName,
    staleValue,
    makeStationUrl,
    getUpdateIdbObservationsStationIdUrl,
} from '../util/constants';
import { addStation } from '../actions/stations';
import { setFilterSetStale } from '../actions/filterSet';
import { checkIfOfflineObservations } from '../actions/ui';
import { deleteObservation } from '../actions/observations';

/**
 *
 * Uploading an offline observation alone is pretty linear: once saved to the
 * server, delete the draft from indexeddb. Update app state.
 *
 * If accompanied by an offline station, there are many stacked steps in the
 * upload process. The station must save to the server successfully first, and
 * its new data patched and deleted where referenced across redux and in
 * indexeddb.Thereafter, the offline observation can be uploaded normally.
 * Any failures along these steps path may result in stray data or duplicates.
 *
 * Uploaded data is deleted from indexeddb.
 */

export function uploadOfflineData({
    observationId,
    idbObservationData,
    schema,
    user,
    useStations,
    dispatch,
    clearData,
}) {
    const uploadOfflineObservation = obsDataObject => {
        return async dispatch => {
            const observationResponse = await axios.post(
                observationUrl,
                obsDataObject
            );

            const responseData = observationResponse.data;

            if (
                !responseData.result ||
                (responseData.result && responseData.status !== 200)
            ) {
                if (responseData.status === 422) {
                    message.error(responseData.error);
                } else {
                    message.error('An error prevented saving the observation.');
                }
            } else {
                message.success(
                    `Saved observation ${responseData.result.observationId}.`
                );

                // delete the uploaded observation from idb
                await dispatch(
                    deleteObservation({
                        ...idbObservationData,
                        observationId,
                    })
                );
                // close table sidebar
                clearData();

                // update app messaging
                await dispatch(checkIfOfflineObservations());

                // update filterset and table data
                // importantly, this updates station data and observation count
                // that underlie the current table data
                await dispatch(
                    setFilterSetStale({
                        filterSetName: hardcodedMyOfflineFilterSetName,
                        stale: staleValue.fetch,
                    })
                );
                message.success(`Updated table.`);
            }
        };
    };

    const uploadOfflineStation = stationDataObj => {
        return async dispatch => {
            // We use latitude and longitude in the client because
            // that is what MapboxGL uses, but the API requires us
            // to rename them to y and x. Passing undefined
            // effectively removes the properties.

            const { stationId } = stationDataObj;

            const stationSchemaFolders = schema.station.folders;

            const stationObsObjData = Object.assign(
                {
                    x: stationDataObj.longitude,
                    y: stationDataObj.latitude,
                    wkid: 4326,
                    public: stationDataObj?.isPublic,
                    attributes:
                        stationSchemaFolders.length > 0
                            ? stationSchemaFolders[0].fields.map(f => f.name)
                            : [],
                    ownerId: parseInt(user.userId),
                },
                stationDataObj,
                {
                    latitude: undefined,
                    longitude: undefined,
                    isPublic: undefined,
                }
            );

            const saveStationToServer = () => {
                // returns new station id
                return async dispatch => {
                    const stationResponse = await axios.post(
                        stationUrl,
                        stationObsObjData
                    );
                    if (
                        !stationResponse.data.result ||
                        (stationResponse.data.result &&
                            stationResponse.data.status !== 200)
                    ) {
                        if (stationResponse.data.status === 422) {
                            throw new Error(stationResponse.data.error);
                        } else {
                            throw new Error(
                                'An error prevented saving the station.'
                            );
                        }
                    } else {
                        message.success('Saved station.');
                        dispatch(
                            addStation({
                                station: stationResponse.data.result,
                                user,
                                schema,
                            })
                        );
                        const savedStationId =
                            stationResponse.data.result.stationId;
                        return savedStationId;
                    }
                };
            };

            const updateIdbObservationsWithNewStationId = nextStationId => {
                return async () => {
                    const observationsUpdateResponse = await axios.post(
                        getUpdateIdbObservationsStationIdUrl,
                        {
                            stationId,
                            nextStationId,
                        }
                    );
                    if (
                        observationsUpdateResponse.status === 200 &&
                        observationsUpdateResponse.data.result
                    ) {
                        message.success(
                            'Offline data has been updated with saved station data.'
                        );
                    } else {
                        throw new Error(
                            'There was an issue updating offline data with the saved station data. This may result in duplicate similar stations.'
                        );
                    }
                };
            };

            const deleteSavedStationFromIdb = () => {
                return async () => {
                    // delete station from idb
                    // failing here or earlier will result in lingering offline
                    // station data. it is non-breaking but may result in this
                    // offline station being reused
                    const options = {
                        headers: {
                            X_HTTP_Method_Override: 'DELETE',
                        },
                    };
                    const deleteIdbStationResponse = await axios.post(
                        makeStationUrl(stationId),
                        null,
                        options
                    );
                    if (
                        deleteIdbStationResponse.status === 200 &&
                        deleteIdbStationResponse.data.result
                    ) {
                        message.success(
                            'Offline station data has been cleaned up.'
                        );
                    } else {
                        // non-show-stopping data cleaning step
                        message.error(
                            'There was an issue updating offline data with the saved station data. This may result in duplicate similar stations.'
                        );
                    }
                };
            };

            try {
                const savedStationId = await dispatch(saveStationToServer());
                await dispatch(
                    updateIdbObservationsWithNewStationId(savedStationId)
                );
                await dispatch(deleteSavedStationFromIdb());

                // upload the offline observation data
                await dispatch(
                    uploadOfflineObservation({
                        ...stationDataObj,
                        stationId: savedStationId,
                    })
                );
            } catch (e) {
                message.error(
                    e?.message ||
                        'An unexpected problem was encountered while uploading the data.'
                );
            }
        };
    };

    const isOfflineDraftStation = idbObservationData.stationId?.includes(
        'draft'
    );
    const modifiedData = Object.assign(idbObservationData, {
        schema: schema.name,
        photos: JSON.stringify([]),
        media: JSON.stringify([]),
    });
    // upload offline station data first
    if (isOfflineDraftStation && useStations) {
        return dispatch(uploadOfflineStation(modifiedData));
    } else {
        return dispatch(uploadOfflineObservation(modifiedData));
    }
}
