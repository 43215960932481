import apiRequest from '../util/apiRequest';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { Link } from 'react-router-dom';
import { Form, Button, Alert } from 'antd';

import { userUrl, baseRegistrationFormSchema } from '../util/constants';

import SchemaFormFields from './SchemaFormFields';
import {
    formValuesToUserRequestPayload,
    flattenUserObjectWithHdata,
} from '../util/profileHelper';
import { updateUser } from '../actions/auth';

const UserProfileForm = () => {
    const dispatch = useDispatch();
    const [alertMessage, setAlertMessage] = useState(null);

    // Scroll to top if alert message changes
    useEffect(() => {
        if (alertMessage) {
            window.scroll({ top: 0, behavior: 'smooth' });
        }
    }, [alertMessage]);

    const projectUserSchema = useSelector(
        state => state.project?.data?.userSchema
    );

    // Collect all of the necessary schemas fields for this form
    // and combine them all together into one schema for this form
    const formSchema = [
        // except password
        ...baseRegistrationFormSchema.filter(item => {
            return item.name.toLowerCase() !== 'password';
        }),
        ...(projectUserSchema?.folders?.[0]?.fields ?? []),
    ];

    // grab user profile
    const userProfile = useSelector(state => state.auth.user);

    // these are the initial values (i.e. the current profile settings that the
    // user can change)
    const initialValues = flattenUserObjectWithHdata(userProfile, formSchema);

    const [registerRequestState, registerRequest] = useAsyncFn(
        async formValues => {
            const fieldNames = formSchema.map(field => field.name);

            const response = await apiRequest.put(
                userUrl,
                formValuesToUserRequestPayload(
                    fieldNames,
                    formValues,
                    userProfile
                )
            );

            const payload = response.data;
            if (payload.status === 200 && payload.result) {
                dispatch(updateUser(payload.result));
                setAlertMessage({
                    alertType: 'success',
                    message: 'Profile successfully updated',
                });
            } else {
                const error = payload.error ? `: ${payload.error}` : '';
                setAlertMessage({
                    alertType: 'info',
                    message: `There was an error updating your profile${error}`,
                });
            }

            return payload;
        },
        [formSchema]
    );

    const handleFinish = values => {
        setAlertMessage(null);
        registerRequest(values);
    };

    const formFields = (
        <SchemaFormFields fields={formSchema} indicateRequired={true} />
    );

    const alert = alertMessage && (
        <Alert
            message={alertMessage.message}
            type={alertMessage.alertType}
            showIcon
            closable
            style={{ margin: '10px 0' }}
        />
    );

    const updateProfileForm = (
        <>
            {alert}
            {
                <Form
                    initialValues={initialValues}
                    onFinish={handleFinish}
                    layout='vertical'
                    className='profile-form'
                >
                    {formFields}
                    <Form.Item className='profile-form__buttongroup'>
                        <Button
                            type='primary'
                            htmlType='submit'
                            className='profile-form-button'
                            loading={registerRequestState.loading}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                    <Link to='reset'>Reset password</Link>
                </Form>
            }
        </>
    );

    return <>{updateProfileForm}</>;
};

export default UserProfileForm;
