import React from 'react';
import PropTypes from 'prop-types';
import IconBadge from './IconBadge';
import { Row, Col, Typography, Divider, Tabs } from 'antd';

import ExploreTabContent from './ExploreTabContent';

const { Title } = Typography;

const TitleSection = () => {
    return (
        <Row justify='center' className='home-explore__intro'>
            <Col>
                <IconBadge icon={['far', 'map-marked-alt']} />
                <Title type='primary' className='ant-typography-light'>
                    Explore and learn
                </Title>
                <Divider className='section-divider' />
            </Col>
        </Row>
    );
};

const HomeExplore = ({ visualizationFolders }) => {
    return visualizationFolders ? (
        <>
            {visualizationFolders.length > 0 ? <TitleSection /> : null}
            <Tabs
                size='large'
                type='card'
                tabBarGutter={20}
                items={visualizationFolders.map(folder => ({
                    label: folder.label,
                    key: folder.label,
                    children: <ExploreTabContent {...folder} />,
                }))}
            />
        </>
    ) : null;
};

HomeExplore.propTypes = {
    visualizationFolders: PropTypes.array,
};

export default HomeExplore;
