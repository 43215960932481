import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Layout, Affix } from 'antd';
import AppFooter from './AppFooter';
import NavBar from './NavBar';
import OfflineBanner from './OfflineBanner';

const { Header, Content } = Layout;

function LayoutBasic({ label, children }) {
    // FUTURE #2072 Adding a call to this hook resolved a unusual issue with
    // menu items disappearing from the parent Menu when resizing the window.
    // Ideally we would rather not call a hook when we don't use the value it
    // provides.
    useWindowSize();

    const { embed, isOffline, hasOfflineObservations } = useSelector(
        state => state.ui
    );

    const { isLoggedIn } = useSelector(state => state.auth);

    const { data: project } = useSelector(state => state.project);
    const { data: stations } = useSelector(state => state.stations);
    const isDataLoaded = project !== null && stations !== null;

    const Navigation = ({ headerClasses }) => {
        if (embed) return null;

        return (
            <Affix offsetTop={0}>
                <div>
                    <OfflineBanner
                        isOffline={isOffline}
                        hasOfflineObservations={hasOfflineObservations}
                        isLoggedIn={isLoggedIn}
                        isDataLoaded={isDataLoaded}
                    />
                    <Header className={headerClasses}>
                        <NavBar />
                    </Header>
                </div>
            </Affix>
        );
    };

    const footer = embed ? null : <AppFooter label={label} />;

    return (
        <Layout className={`${embed ? 'embed' : ''}`}>
            <Switch>
                <Route
                    path={[
                        '/visualizations/new',
                        '/visualizations/:visualizationId',
                        '/visualizations/:visualizationId/edit',
                    ]}
                >
                    <Navigation headerClasses='visualization-navbar' />
                </Route>
                <Route>
                    <Navigation />
                </Route>
            </Switch>
            <Content>{children}</Content>
            {footer}
        </Layout>
    );
}

export default LayoutBasic;
