import { find, flatMap, map, partition } from 'lodash';

import {
    observationSchemaFolderAddendumForUseStationsUpload,
    observationSchemaFolderAddendumForUpload,
    stationFieldAddendumForUpload,
} from './constants';
import { objectToFormData } from '../util/formHelper';

export const tryParseSheet = async blob => {
    try {
        const XLSX = await import('xlsx');
        const raw = new Uint8Array(blob);
        const wb = XLSX.read(raw, {
            type: 'array',
            cellDates: true,
            dateNF: 'yyyy-mm-dd',
        });
        // Get first worksheet
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        // Convert to CSV, split headers and body
        const csv = XLSX.utils.sheet_to_csv(ws, { blankrows: false });
        const data = XLSX.utils.sheet_to_json(ws, { blankrows: false });
        const headers = csv.substring(0, csv.indexOf('\n')).split(',');
        const body = csv.substring(csv.indexOf('\n') + 1);

        return [headers, body, data];
    } catch {
        return [[], null, null];
    }
};

export const getUploadPayload = (useStations, schema, files) => {
    return objectToFormData({
        schema,
        stationColumns:
            files.stations?.matches &&
            getStationColumns(files.stations.matches),
        stations: files.stations?.body,
        observationColumns:
            files.observations?.matches &&
            getObservationColumns(useStations, files.observations.matches),
        observations: files.observations?.body,
    });
};

export const getStationColumns = matches => {
    const activeMatches = matches.filter(m => m.matchIndex !== null);
    const addendumFields = map(stationFieldAddendumForUpload, 'name');
    const isTopLevel = match => addendumFields.indexOf(match.name) >= 0;
    const [topLevel, others] = partition(activeMatches, isTopLevel);

    return JSON.stringify({
        idColumn: find(topLevel, { name: 'stationId' }).matchIndex,
        nameColumn: find(topLevel, { name: 'stationName' }).matchIndex,
        latitudeColumn: find(topLevel, { name: 'latitude' }).matchIndex,
        longitudeColumn: find(topLevel, { name: 'longitude' }).matchIndex,
        fieldColumns: others.reduce((acc, m) => {
            acc[m.name] = m.matchIndex;
            return acc;
        }, {}),
        fieldUnits: {},
    });
};

export const makeStationsReviewData = data =>
    data.map((s, index) =>
        Object.assign(
            {
                key: index,
                stationId: s.stationId,
                latitude: s.geometry.y,
                longitude: s.geometry.x,
                stationName: s.stationName,
            },
            s.attributes
        )
    );

export const getObservationColumns = (useStations, matches) => {
    const activeMatches = matches.filter(m => m.matchIndex !== null);
    const addendum = useStations
        ? observationSchemaFolderAddendumForUseStationsUpload
        : observationSchemaFolderAddendumForUpload;
    const addendumFields = map(flatMap(addendum, 'fields'), 'name');
    const isTopLevel = match => addendumFields.indexOf(match.name) >= 0;
    const [topLevel, others] = partition(activeMatches, isTopLevel);
    let locationData = {};
    if (useStations) {
        locationData = {
            stationIdColumn: find(topLevel, { name: 'stationId' }).matchIndex,
            existingStationIdColumn: find(topLevel, { name: 'stationId' })
                .matchIndex,
        };
    } else {
        locationData = {
            latitudeColumn: find(topLevel, { name: 'latitude' }).matchIndex,
            longitudeColumn: find(topLevel, { name: 'longitude' }).matchIndex,
        };
    }

    return JSON.stringify({
        idColumn: -1,
        dateColumn: find(topLevel, { name: 'collectionDate' }).matchIndex,
        fieldColumns: others.reduce((acc, m) => {
            acc[m.name] = m.matchIndex;
            return acc;
        }, {}),
        ...locationData,
        fieldUnits: {},
    });
};

export const makeObservationsUseStationsReviewData = data =>
    flatMap(data, 'observations').map((o, index) =>
        Object.assign(
            {
                key: index,
                stationId: o.stationId,
                collectionDate: o.collectionDate,
            },
            o.attributes
        )
    );

export const makeObservationsReviewData = data =>
    data.map((s, index) => {
        const o = s.observations[0];
        return Object.assign(
            {
                key: index,
                latitude: s.geometry.y,
                longitude: s.geometry.x,
                collectionDate: o.collectionDate,
            },
            o.attributes
        );
    });
