import React from 'react';
import { useSelector } from 'react-redux';
import { userCanAdminProject, userIsLoc } from '../util/projectHelper';

function ShowIfManagerOrLoc({ locFieldValue, alsoShowIf = false, children }) {
    const project = useSelector(state => state.project);
    const user = useSelector(state => state.auth.user);

    if (
        userCanAdminProject(user, project?.data) ||
        userIsLoc(user, project?.data, locFieldValue) ||
        alsoShowIf
    ) {
        return <>{children}</>;
    }

    return null;
}

export default ShowIfManagerOrLoc;
