import React from 'react';

const ObservationTableBanner = ({
    isOffline,
    hasOfflineObservations,
    user,
}) => {
    if (!user) return null;

    return isOffline ? (
        <div className='offline-interior-banner'>
            While you're offline you can only see observations saved on this
            device.
        </div>
    ) : hasOfflineObservations ? (
        <div className='offline-interior-banner'>
            <div className='offline-interior-banner__text'>
                <b>
                    Observations you saved while offline are available with
                    Draft status in the My Offline Observations tab.
                </b>
                <br />
                Click on an observation and choose Upload to upload an
                observation to FieldScope.
            </div>
        </div>
    ) : null;
};

export default ObservationTableBanner;
