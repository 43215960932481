import apiRequest from '../util/apiRequest';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { Menu, Button, Dropdown, Row, Col, message, Skeleton } from 'antd';

import { handleLogoutUser, setRedirectPath } from '../actions/auth';

import { authLogoutUrl } from '../util/constants';
import {
    userCanAdminProject,
    userIsLoc,
    userCanBulkUpload,
} from '../util/projectHelper';
import fieldscopeLogo from '../assets/images/fieldscope-logo.png';
import NewTabLink from './NewTabLink';

const NavBar = location => {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const user = useSelector(state => state.auth.user);
    const project = useSelector(state => state.project);
    const isOffline = useSelector(state => state.ui.isOffline);

    const dispatch = useDispatch();
    const history = useHistory();
    const currentPath = location.location.pathname;

    const [logoutRequestState, logoutRequest] = useAsyncFn(async () => {
        const response = await apiRequest.post(authLogoutUrl);
        const payload = response.data;

        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200) {
            dispatch(handleLogoutUser());
            history.push('/');
        } else {
            message.error('Log out failed.');
        }
        return payload;
    }, []);

    const logoSrc = project?.data?.logo || fieldscopeLogo;
    const logo = !project?.fetching ? (
        <img
            src={logoSrc}
            alt='logo'
            style={{ maxHeight: '30px', maxWidth: '100%' }}
        />
    ) : (
        <Skeleton.Avatar size='large' shape='circle' />
    );

    const userCanViewManagePage =
        userCanAdminProject(user, project?.data) ||
        userIsLoc(user, project?.data);
    // Can't use ShowIfProjectAdmin component wrapper due to antd bug
    // https://github.com/ant-design/ant-design/issues/18022
    const manageDataButton = userCanViewManagePage && {
        key: '/manage',
        disabled: isOffline,
        label: (
            <Link to='/manage'>
                <FontAwesomeIcon icon={['far', 'map-marker-edit']} /> Manage
            </Link>
        ),
    };

    const visualizationMenuItem = {
        key: '/visualizations',
        disabled: isOffline,
        label: (
            <Link to='/visualizations'>
                <FontAwesomeIcon icon={['far', 'map']} /> Visualizations
            </Link>
        ),
    };

    const addMenu = [
        {
            key: 'new-observation',
            label: (
                <Link to='/observations/new'>
                    {' '}
                    <FontAwesomeIcon
                        fixedWidth
                        icon={['far', 'keyboard']}
                    />{' '}
                    Enter an observation
                </Link>
            ),
        },
        { type: 'divider' },
        {
            key: 'upload',
            disabled: isOffline,
            label: (
                <Link to='/upload'>
                    <FontAwesomeIcon fixedWidth icon={['far', 'upload']} />{' '}
                    Upload
                </Link>
            ),
        },
    ];

    // Show Add Data button if project has data schemas
    // and the user is not in the Visualization section
    const addDataButton =
        project?.data?.dataSchemas?.length > 0 &&
        !currentPath.startsWith('/visualizations') &&
        (userCanBulkUpload(user, project) ? (
            <Dropdown.Button
                type='primary'
                trigger={['click']}
                menu={{ items: addMenu }}
                onClick={() => history.push('/observations/new')}
            >
                <FontAwesomeIcon icon={['far', 'plus']} />
                <span className='sr-only-mobile'>Add</span>&nbsp;Data
            </Dropdown.Button>
        ) : (
            <Link to='/observations/new' className='ant-btn ant-btn-primary'>
                <FontAwesomeIcon icon={['far', 'plus']} />
                <span className='sr-only-mobile'>Add</span> Data
            </Link>
        ));

    const AppNavItems = [
        {
            key: '/',
            label: (
                <Link to='/'>
                    <FontAwesomeIcon icon={['far', 'home']} /> Home
                </Link>
            ),
        },
        visualizationMenuItem,
        {
            key: '/observations',
            label: (
                <Link to='/observations'>
                    <FontAwesomeIcon icon={['far', 'map-marker']} /> Data
                </Link>
            ),
        },
        manageDataButton,
        {
            key: '/help',
            disabled: isOffline,
            label: (
                <NewTabLink href='https://fieldscope.zendesk.com'>
                    <FontAwesomeIcon icon={['far', 'question']} /> Help
                </NewTabLink>
            ),
        },
    ];

    const AppNav = ({ mode, ...props }) => {
        return (
            <Menu
                mode={mode}
                {...props}
                selectedKeys={[currentPath]}
                items={AppNavItems}
            />
        );
    };

    const AccountNav = [
        {
            key: '0qwer',
            label: (
                <Link to='/profile'>
                    <FontAwesomeIcon fixedWidth icon={['far', 'user-cog']} />{' '}
                    Account settings
                </Link>
            ),
        },
        {
            key: '1adfs',
            disabled: isOffline,
            label: (
                <Link to='/reset'>
                    <FontAwesomeIcon fixedWidth icon={['far', 'unlock']} /> Edit
                    password
                </Link>
            ),
        },
        { type: 'divider' },
        {
            key: '3asdf',
            disabled: logoutRequestState.loading || isOffline,
            label: (
                <a
                    href='/'
                    onClick={e => {
                        e.preventDefault();
                        logoutRequest();
                    }}
                >
                    <FontAwesomeIcon fixedWidth icon={['far', 'sign-out']} />{' '}
                    Log out
                </a>
            ),
        },
    ];

    const AuthNav = () => {
        const menu = isLoggedIn ? (
            <div className='ant-menu-horizontal'>
                {addDataButton}
                <Dropdown
                    menu={{ items: AccountNav }}
                    trigger={['click']}
                    placement='bottomRight'
                >
                    <div className='ant-menu-horizontal'>
                        <div className='ant-menu-item'>
                            <FontAwesomeIcon icon={['far', 'user']} />{' '}
                            <span className='sr-only-mobile'>{user.email}</span>
                        </div>
                    </div>
                </Dropdown>
            </div>
        ) : (
            <Menu
                mode='horizontal'
                className='navbar__AuthNav'
                selectable={false}
                items={[
                    {
                        key: 'login',
                        disabled: isOffline,
                        label: (
                            <Link
                                to='/login'
                                onClick={() =>
                                    dispatch(
                                        setRedirectPath(
                                            history.location.pathname
                                        )
                                    )
                                }
                            >
                                <FontAwesomeIcon icon={['far', 'user']} /> Log
                                in
                            </Link>
                        ),
                    },
                ]}
            />
        );
        return menu;
    };

    return (
        <Row className='navbar container'>
            <Col
                className='navbar__brand'
                order={0}
                xs={8}
                sm={10}
                md={4}
                lg={4}
            >
                <Link to='/'>{logo}</Link>
            </Col>
            <Col className='navbar__Nav' order={2} flex='1' md={20}>
                <AppNav
                    className='navbar__AppNav'
                    mode='horizontal'
                    style={{ lineHeight: '64px', textAlign: 'center' }}
                />
                <AuthNav />
            </Col>
            <Col className='navbar__MobileNav' order={1}>
                <Dropdown menu={{ items: AppNavItems }} trigger={['click']}>
                    <Button
                        className='ant-dropdown-link'
                        onClick={e => e.preventDefault()}
                        icon={<FontAwesomeIcon icon={['far', 'bars']} />}
                        ghost
                        type='primary'
                    >
                        Menu
                    </Button>
                </Dropdown>
            </Col>
        </Row>
    );
};

export default withRouter(NavBar);
