import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import moment from 'moment';

import {
    startFetchOrganizations,
    completeFetchOrganizations,
    failFetchOrganizations,
    clearOrganizations,
} from '../actions/organizations';

const ORGANIZATIONS_CACHE_TTL_DURATION = 60;
const ORGANIZATIONS_CACHE_TTL_UNIT = 'minutes';

const initialState = Object.freeze({
    data: null,
    fetching: false,
    error: false,
    expirationDate: null,
});

const OrganizationsReducer = createReducer(
    {
        [startFetchOrganizations]: state =>
            update(state, {
                fetching: { $set: true },
                error: { $set: initialState.error },
            }),
        [completeFetchOrganizations]: (state, payload) =>
            update(state, {
                data: { $set: payload },
                fetching: { $set: initialState.fetching },
                error: { $set: initialState.error },
                expirationDate: {
                    $set: moment().add(
                        ORGANIZATIONS_CACHE_TTL_DURATION,
                        ORGANIZATIONS_CACHE_TTL_UNIT
                    ),
                },
            }),
        [failFetchOrganizations]: (state, payload) =>
            update(state, {
                fetching: { $set: initialState.fetching },
                error: { $set: payload },
            }),
        [clearOrganizations]: state =>
            update(state, {
                $set: initialState,
            }),
    },
    initialState
);

export default OrganizationsReducer;
