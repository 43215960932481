import React from 'react';
import { useAsync } from 'react-use';
import apiRequest from '../util/apiRequest';
import { Collapse, Space, Spin, Typography } from 'antd';

import { makeLayerLegendUrl } from '../util/mapHelper';

const { Panel } = Collapse;
const { Text } = Typography;

const LegendImg = ({ imageData, contentType, label }) => (
    <img src={`data:${contentType};base64, ${imageData}`} alt={label} />
);

const MapLegendItem = ({ layer }) => {
    const legendRequest = useAsync(async () => {
        const url = makeLayerLegendUrl(layer);
        const response = await apiRequest.get(url);
        return response.data;
    }, [layer]);

    const loading = legendRequest.loading ? <Spin /> : null;
    const items =
        legendRequest.value && legendRequest.value.layers
            ? legendRequest.value.layers.map(({ layerName, legend }) => (
                  <Collapse key={layerName} bordered={false}>
                      <Panel header={layerName} key={layerName}>
                          {legend.map((entry, i) => (
                              <div key={`${i}_${entry.label}`}>
                                  <Space>
                                      <LegendImg {...entry} />
                                      {entry.label}
                                  </Space>
                              </div>
                          ))}
                      </Panel>
                  </Collapse>
              ))
            : null;
    return (
        <div>
            <Text strong>{layer.label}</Text>
            {loading}
            {items}
        </div>
    );
};

export default MapLegendItem;
