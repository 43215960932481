import React, { useEffect, useState } from 'react';
import apiRequest from '../util/apiRequest';
import { useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { message, Select } from 'antd';
import difference from 'lodash/difference';

import {
    manageUsersGroupsUrl,
    locUrl,
    makeLocUrl,
    defaultPageSize,
} from '../util/constants';
import { userCanAdminProject } from '../util/projectHelper';

const { Option } = Select;

const ManageLocsTableDefinition = project => {
    const user = useSelector(state => state.auth.user);
    const userIsAdmin = userCanAdminProject(user, project?.data);

    const updateLocs = async (newLocUserIds, groupData) => {
        const { loc_users, loc_field_value, loc_field_label } = groupData;
        const locUserIds = loc_users.map(u => u.user);

        // Ignore no change
        if (newLocUserIds === locUserIds) return null;

        const deletedLocUserId = difference(locUserIds, newLocUserIds)[0];
        const addedLocUserId = difference(newLocUserIds, locUserIds)[0];

        const getLocRequest = () => {
            if (deletedLocUserId) {
                // Delete user from LOCs
                const targetLocId = loc_users.find(
                    u => u.user === deletedLocUserId
                )['id'];
                return async () =>
                    await apiRequest.delete(makeLocUrl(targetLocId));
            }

            if (addedLocUserId) {
                // Add user to LOCs
                return async () =>
                    await apiRequest.post(locUrl, {
                        user: addedLocUserId,
                        loc_field_value,
                    });
            }
        };

        const request = getLocRequest();
        const response = await request();
        const payload = response.data;
        if (payload.status === 200 && payload.result) {
            groupsRequest();
            message.success(`Updated coordinators for ${loc_field_label}`);
        } else {
            message.error(
                `Failed to change coordinators for ${loc_field_label}`
            );
        }
    };

    const groupsTableColumns = [
        {
            title: 'Group',
            dataIndex: 'loc_field_label',
            className: 'loc-group-name',
        },
        {
            title: 'Coordinators',
            key: 'loc_field_value',
            render: data => {
                const locUserIds = data.loc_users.map(u => u.user);
                return (
                    <Select
                        key={data.loc_field_label}
                        showSearch
                        mode='multiple'
                        style={{ width: '100%' }}
                        defaultValue={locUserIds}
                        onChange={values => updateLocs(values, data)}
                        filterOption={(input, option) =>
                            option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {data.users.map(({ id, email }) => (
                            <Option value={id} key={id}>
                                {email}
                            </Option>
                        ))}
                    </Select>
                );
            },
        },
    ];

    const [groupsTablePageSize, setGroupsTablePageSize] = useState(
        defaultPageSize
    );
    const [groupsTablePage, setGroupsTablePage] = useState(1);
    const [groupsTableQ, setGroupsTableQ] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [groupsRequestState, groupsRequest] = useAsyncFn(async () => {
        const response = await apiRequest.get(manageUsersGroupsUrl, {
            params: {
                page: groupsTablePage,
                pageSize: groupsTablePageSize,
                q: groupsTableQ || undefined,
            },
        });
        const payload = response.data;

        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200 && payload.result) {
            setDataSource(payload.result.data);
            return payload.result;
        }
        setDataSource([]);
        return payload;
    }, [groupsTablePageSize, groupsTablePage, groupsTableQ]);

    useEffect(() => {
        if (userIsAdmin) {
            groupsRequest();
        }
    }, [userIsAdmin, groupsRequest]);

    if (groupsRequestState.error) {
        return <div>Oops there was an error loading this page.</div>;
    }

    if (!user || !userIsAdmin) return null;

    const onGroupsTableAction = (pagination, filters, sorter) => {
        setGroupsTablePage(pagination.current);
        setGroupsTablePageSize(pagination.pageSize);
    };

    return {
        title: 'Groups',
        tab: 'Groups',
        type: 'paginatedTable',
        columns: groupsTableColumns,
        dataSource: dataSource,
        page: groupsTablePage,
        pageSize: groupsTablePageSize,
        total: groupsRequestState.value?.total,
        loading: groupsRequestState.loading,
        onChange: onGroupsTableAction,
        onSearch: setGroupsTableQ,
    };
};

export default ManageLocsTableDefinition;
