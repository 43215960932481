import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Spin, message } from 'antd';
import sortBy from 'lodash/sortBy';
import { find, orderBy } from 'lodash';

import ObservationTableHeader from './ObservationTableHeader';
import ObservationTable from './ObservationTable';
import ObservationMap from './ObservationMap';

import {
    getObservationDisplayValues,
    getFieldNameReclassLabelDictionary,
    getSynthesizedFieldLabelDictionary,
} from '../util/schemaHelper';
import {
    userCanAdminProject,
    userCanDownload,
    userIsLoc,
    userIsProjectManager,
} from '../util/projectHelper';
import { getActiveDataLayers } from '../util/mapHelper';
import { shouldSetTableData, redrawRequested } from '../util/filterSetHelper';
import { getObservationTableFieldsFromProjectDataSchema } from '../util/queryHelper';
import {
    fetchFilterSet,
    fetchFilterSetManagerFields,
    setSelectedObservations,
    setTableData,
    setIsTableVisible,
    setTablePage,
    setFilterSetStale,
} from '../actions/filterSet';
import {
    staleValue,
    managerFieldDataLabelMap,
    hardcodedMyOfflineFilterSetName,
    getIdbObservationsUrl,
} from '../util/constants';

function FilterSetDataViewer({
    // From caller
    schema,
    filterSetName,
    filters,
    basemap,
    activeDataLayerIds,
    layerOpacity,
    onBasemapChange,
    onToggleDataLayer,
    onLayerOpacityChange,
    height,
    defaultViewport,
    disableZoomToSelectedStations,
    symbology,
    fieldDefinitions,
    onViewportChange,
    scrollZoom,
    showHeader,
    isWidget = false,
    // From state
    filterSets,
    filterSetManagerFields,
    projectData,
    user,
    isTableVisible,
    tableData,
    selectedObservations,
    tablePage,
    tablePageSize,
    isOffline,
    dispatch,
}) {
    const currentFilterSet = filterSets[filterSetName];

    if (shouldSetTableData(currentFilterSet, tableData[filterSetName])) {
        dispatch(
            setTableData({
                filterSetName,
                // The observations get sorted in the backend before we limit them
                // but we lose that sort order when they get grouped by station.
                // This re-sorts the observations by date so that by default the
                // newest show up first
                data: orderBy(
                    getObservationDisplayValues(
                        schema,
                        currentFilterSet.filters.displayFields ||
                            currentFilterSet.filters.fields,
                        currentFilterSet.results,
                        user,
                        projectData
                    ),
                    'collectionDate',
                    'desc'
                ),
            })
        );
        if (redrawRequested(currentFilterSet)) {
            dispatch(
                setFilterSetStale({
                    filterSetName,
                    stale: null,
                })
            );
        }
    }

    const currentTableData = tableData[filterSetName];
    const currentTablePage = tablePage[filterSetName];
    const currentTablePageSize = tablePageSize[filterSetName];
    const currentSelectedObservations = selectedObservations[filterSetName];

    const activeBasemap = basemap || projectData?.basemaps[0];
    const activeDataLayers = getActiveDataLayers(
        projectData,
        activeDataLayerIds
    );

    const isAdmin = userCanAdminProject(user, projectData);
    const isLoc = userIsLoc(user, projectData);
    const isDownloadAllowed = userCanDownload({
        user,
        project: projectData,
        isOffline,
    });

    useEffect(() => {
        let dataSource = filters
            ? find(projectData?.dataSources, {
                  id: filters.dataSource,
              })
            : null;

        if (filterSetName === hardcodedMyOfflineFilterSetName) {
            dataSource = {
                url: getIdbObservationsUrl,
            };
        }

        if (currentFilterSet?.error) {
            message.error(
                'Error fetching observations. Refresh the page to try again.'
            );
            return;
        }

        if (
            (!currentFilterSet?.results ||
                currentFilterSet?.stale === staleValue.fetch) &&
            !currentFilterSet?.fetching &&
            schema &&
            dataSource
        ) {
            dispatch(
                fetchFilterSet(
                    {
                        filterSetName,
                        schemaName: schema.key,
                        filters: {
                            ...filters,
                            fields: getObservationTableFieldsFromProjectDataSchema(
                                schema,
                                userIsProjectManager(user, projectData),
                                projectData?.useStations
                            ),
                        },
                    },
                    dataSource?.url
                )
            );
            if (isAdmin || isLoc) {
                dispatch(
                    fetchFilterSetManagerFields(
                        {
                            filterSetName,
                            schemaName: schema.key,
                            filters,
                        },
                        dataSource?.url
                    )
                );
            }
        }
    }, [
        filterSetName,
        filters,
        currentFilterSet,
        schema,
        projectData,
        dispatch,
        isAdmin,
        isOffline,
        isLoc,
        user,
    ]);

    if (!schema) {
        return <Spin />;
    }

    if (schema.error) {
        return <Alert message='Failed to load schema' type='error' />;
    }

    // Pre-generate the data field labels to pass to the sidebar and header
    const dataLabelMap =
        currentFilterSet?.results &&
        Object.assign(
            getFieldNameReclassLabelDictionary(
                schema,
                currentFilterSet.filters.fields
            ),
            getSynthesizedFieldLabelDictionary(schema),
            isAdmin || isLoc ? managerFieldDataLabelMap : {}
        );

    const showInTable = observations => {
        const observationIds = observations.map(obs => obs.observationId);

        // Place selected observations at the top of the table
        const prioritizeSelectedRowsDataSource = sortBy(currentTableData, obs =>
            observationIds.includes(obs.key) ? 0 : 1
        );

        dispatch(setTablePage({ filterSetName, page: 1 }));
        dispatch(setSelectedObservations({ filterSetName, observationIds }));
        dispatch(
            setTableData({
                filterSetName,
                data: prioritizeSelectedRowsDataSource,
            })
        );
        dispatch(setIsTableVisible({ filterSetName, isTableVisible: true }));
    };

    const observationContent = isTableVisible[filterSetName] ? (
        <ObservationTable
            currentFilterSet={currentFilterSet}
            filterSetName={filterSetName}
            currentSchema={schema}
            user={user}
            isAdmin={isAdmin}
            currentPage={currentTablePage}
            currentPageSize={currentTablePageSize}
            selectedObservations={currentSelectedObservations}
            currentTableData={currentTableData}
            dataLabelMap={dataLabelMap}
            dispatch={dispatch}
            basemap={activeBasemap}
            project={projectData}
            isWidget={isWidget}
        />
    ) : (
        <ObservationMap
            currentFilterSet={currentFilterSet}
            currentSchema={schema}
            user={user}
            selectedObservations={currentSelectedObservations}
            showInTable={showInTable}
            project={projectData}
            basemap={activeBasemap}
            activeDataLayerIds={activeDataLayerIds}
            activeDataLayers={activeDataLayers}
            layerOpacity={layerOpacity}
            onBasemapChange={onBasemapChange}
            onToggleDataLayer={onToggleDataLayer}
            onLayerOpacityChange={onLayerOpacityChange}
            height={height}
            defaultViewport={defaultViewport}
            disableZoomToSelectedStations={disableZoomToSelectedStations}
            symbology={symbology}
            fieldDefinitions={fieldDefinitions}
            onViewportChange={onViewportChange}
            scrollZoom={scrollZoom}
        />
    );

    return (
        <div className='observation-data'>
            {showHeader ? (
                <ObservationTableHeader
                    filterSetName={filterSetName}
                    filters={currentFilterSet?.filters}
                    isTableVisible={isTableVisible}
                    dispatch={dispatch}
                    isDownloadAllowed={isDownloadAllowed}
                    user={user}
                    project={projectData}
                    schema={schema}
                    loading={currentFilterSet?.fetching}
                    activeDataLayers={activeDataLayers}
                    filterSetResults={currentFilterSet?.results}
                    isOffline={isOffline}
                />
            ) : null}
            {observationContent}
        </div>
    );
}

function mapStateToProps({
    project: { data: projectData },
    data: {
        filterSets,
        filterSetManagerFields,
        isTableVisible,
        tableData,
        tablePage,
        tablePageSize,
        selectedObservations,
    },
    auth: { user },
    ui: { isOffline },
    dispatch,
}) {
    return {
        filterSets,
        filterSetManagerFields,
        user,
        projectData,
        isTableVisible,
        tableData,
        tablePage,
        tablePageSize,
        selectedObservations,
        isOffline,
        dispatch,
    };
}

export default connect(mapStateToProps)(FilterSetDataViewer);
