import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from './reducers';

const migrations = {
    1: state => {
        return {
            ...state,
            ui: { ...state.ui, activeDataLayerIds: [] },
        };
    },
    2: state => {
        return {
            ...state,
            ui: { ...state.ui, layerOpacity: {} },
        };
    },
    3: state => {
        return {
            ...state,
            ui: { ...state.ui, isOffline: false },
        };
    },
    4: state => {
        return {
            ...state,
            ui: { ...state.ui, observationEditLocation: null },
        };
    },
};

const persistConfig = {
    key: 'root',
    version: 2,
    storage,
    whitelist: ['ui', 'visualizationWorkspace'],
    migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducers = persistReducer(persistConfig, reducers);

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({ diff: true, collapsed: true });
    middlewares.push(logger);
}

const configureStore = () => {
    const store = applyMiddleware(...middlewares)(createStore)(
        persistedReducers,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            window.__REDUX_DEVTOOLS_EXTENSION__()
    );
    const persistor = persistStore(store);
    return { store, persistor };
};

export { configureStore as default };
