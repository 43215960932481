import React, { useState, useEffect, useRef } from 'react';
import ReactMapGL, {
    NavigationControl,
    Marker,
    ScaleControl,
} from 'react-map-gl';
import apiRequest from '../util/apiRequest';
import update from 'immutability-helper';
// Maps appear to work without importing the stylesheet, but there is a console
// warning.
import 'mapbox-gl/dist/mapbox-gl.css';
import { intersection, some } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    defaultExtentToViewport,
    makeMapStyle,
    makeStationMapStyle,
    makeStationFeatureCollection,
    makeZoomClamp,
    makeSelectedStationViewport,
    makeLayerIdentifyUrl,
    makeSymbolizer,
} from '../util/mapHelper';
import { closestZoom } from '../util/constants';

import ObservationMapSidebar from './ObservationMapSidebar';
import ObservationMapLayerSelector from './ObservationMapLayerSelector';
import ObservationMapLegend from './ObservationMapLegend';
import MapGeocoder from './MapGeocoder';

const MARKER_COLOR = '#5a7201';
const MARKER_SIZE = 20;

function ObservationMap({
    currentFilterSet,
    currentSchema,
    user,
    selectedObservations,
    showInTable,
    project,
    basemap,
    activeDataLayerIds,
    activeDataLayers,
    layerOpacity,
    height = '80vh',
    defaultViewport,
    disableZoomToSelectedStations,
    symbology,
    fieldDefinitions,
    onBasemapChange,
    onToggleDataLayer,
    onLayerOpacityChange,
    onViewportChange,
    scrollZoom,
}) {
    const [stationState, setStationState] = useState({
        stations: [],
        stationData: {
            type: 'FeatureCollection',
            features: [],
        },
        legendValues: [],
        stationLegendTitle: '',
    });

    const {
        stations,
        stationData,
        legendValues,
        stationLegendTitle,
    } = stationState;
    const { useStations } = project;

    useEffect(() => {
        const newStations = currentFilterSet?.results?.map(station => {
            // A station is selected if any of its observations are selected
            const stationObservations = station.observations.map(
                o => o.observationId
            );
            const selected =
                intersection(selectedObservations, stationObservations).length >
                0;

            return {
                ...station,
                selected,
            };
        });

        const symbolizer = makeSymbolizer(
            newStations,
            {
                symbology,
                fieldDefinitions,
            },
            useStations
        );

        const newStationData = makeStationFeatureCollection(newStations, {
            symbology,
            fieldDefinitions,
            symbolizer,
        });

        setStationState({
            stations: newStations,
            stationData: newStationData,
            legendValues: symbolizer.legendValues || [],
            stationLegendTitle: symbolizer.stationLegendTitle || '',
        });
    }, [
        currentFilterSet,
        symbology,
        fieldDefinitions,
        selectedObservations,
        useStations,
    ]);

    // Make a list of stations, specifying if any are selected

    const mapStyle = makeMapStyle(
        makeStationMapStyle(
            basemap,
            stationData,
            activeDataLayers,
            layerOpacity
        )
    );
    const clampZoom = makeZoomClamp(mapStyle);

    // Initialize map around selected stations, if any
    const selectedStationViewport = makeSelectedStationViewport(stations);
    // Zoom out a little for some breathing space
    const relax = viewport =>
        viewport && {
            ...viewport,
            zoom: clampZoom(viewport.zoom - 2),
        };

    const projectExtent = project?.defaultExtent || {
        scale: 0,
        center: { x: 0, y: 0 },
    };

    const [state, setState] = useState({
        viewport:
            defaultViewport ||
            relax(selectedStationViewport) ||
            defaultExtentToViewport(projectExtent),
        showSidebar: false,
        clickedPoint: null,
        selectedStations: null,
        selectedLayerIdentifyRequests: [],
    });

    // Recenter around selected stations, if any, when they change
    useEffect(() => {
        if (!disableZoomToSelectedStations) {
            const vp = relax(makeSelectedStationViewport(stations));
            setState(state => ({
                ...state,
                viewport: vp || defaultExtentToViewport(projectExtent),
            }));
        }

        // Don't enjoy doing this, but we have to disable the warning here
        // because specifying `stations` in the dependency array causes an
        // inifinite loop as it is calculated on each render, even though
        // it is a pure function of `currentFilterSet` and `selectedObservations`.

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilterSet, selectedObservations, project]);

    const mapRef = useRef(null);

    const onMapClick = async event => {
        const { features, lngLat } = event;
        const map = mapRef.current.getMap();
        const canvas = map.getCanvas();
        const bounds = map.getBounds().toArray();
        const requests = activeDataLayers
            ? activeDataLayers.map(layer => {
                  return {
                      layer,
                      identifyUrl: makeLayerIdentifyUrl(
                          layer,
                          lngLat,
                          bounds,
                          canvas.width,
                          canvas.height
                      ),
                  };
              })
            : [];

        const selectedStations =
            features && features.filter(f => f.layer.id === 'stations');

        setState(state =>
            update(state, {
                clickedPoint: {
                    $set:
                        features && features.count > 0
                            ? features[0].geometry.coordinates
                            : lngLat,
                },
                selectedStations: { $set: selectedStations },
                selectedLayerIdentifyRequests: { $set: [] },
            })
        );

        for (var i = 0; i < requests.length; i++) {
            try {
                requests[i].response = await apiRequest.get(
                    requests[i].identifyUrl
                );
            } catch (e) {
                requests[i].error = e;
            }
        }

        const layerResults = requests.flatMap(
            r => r.response?.data?.results || []
        );

        if (!features && !some(layerResults)) {
            setState(state =>
                update(state, {
                    showSidebar: { $set: false },
                    selectedLayerIdentifyRequests: { $set: [] },
                })
            );
        } else {
            setState(state =>
                update(state, {
                    showSidebar: { $set: some(features) || some(layerResults) },
                    selectedLayerIdentifyRequests: { $set: requests },
                })
            );
        }
    };

    const onGeocode = ({ latitude, longitude }) => {
        setState(state =>
            update(state, {
                viewport: {
                    $set: {
                        ...state.viewport,
                        latitude,
                        longitude,
                        zoom: closestZoom,
                    },
                },
            })
        );
    };

    return (
        <>
            <div className='observation-map'>
                <ReactMapGL
                    {...state.viewport}
                    width='100%'
                    height={height}
                    mapStyle={mapStyle}
                    onViewportChange={viewport => {
                        const { width, height, ...etc } = viewport;
                        etc.zoom = clampZoom(etc.zoom);
                        setState(state =>
                            update(state, { viewport: { $set: etc } })
                        );
                        if (onViewportChange) {
                            onViewportChange(viewport);
                        }
                    }}
                    onClick={onMapClick}
                    ref={mapRef}
                    scrollZoom={scrollZoom}
                >
                    <div
                        style={{
                            padding: '1rem',
                            position: 'absolute',
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <ScaleControl style={{ position: 'static' }} />
                    </div>
                    <div
                        style={{
                            padding: '1rem',
                            position: 'absolute',
                            right: 0,
                        }}
                    >
                        <NavigationControl
                            style={{ position: 'static' }}
                            className='mapboxgl-ctrl mapboxgl-ctrl-group'
                        />

                        <ObservationMapLayerSelector
                            captureClick
                            captureDoubleClick
                            captureScroll
                            captureDrag
                            basemaps={project?.basemaps || []}
                            layerFolders={project?.layerFolders || []}
                            activeDataLayerIds={activeDataLayerIds}
                            selectedBasemap={basemap}
                            layerOpacity={layerOpacity}
                            onBasemapChange={onBasemapChange}
                            onToggleDataLayer={onToggleDataLayer}
                            onLayerOpacityChange={onLayerOpacityChange}
                        />
                        <ObservationMapLegend
                            captureClick
                            captureDoubleClick
                            captureScroll
                            captureDrag
                            activeDataLayers={activeDataLayers}
                            legendValues={legendValues}
                            stationLegendTitle={stationLegendTitle}
                        />
                        <MapGeocoder onGeocode={onGeocode} />
                    </div>
                    {state.showSidebar && state.clickedPoint && (
                        <Marker
                            longitude={state.clickedPoint[0]}
                            latitude={state.clickedPoint[1]}
                            offsetLeft={-MARKER_SIZE / 2}
                            offsetTop={-MARKER_SIZE}
                        >
                            <FontAwesomeIcon
                                icon={['far', 'map-marker']}
                                style={{
                                    color: MARKER_COLOR,
                                    width: `${MARKER_SIZE}px`,
                                    height: `${MARKER_SIZE}px`,
                                }}
                            />
                        </Marker>
                    )}
                </ReactMapGL>
            </div>
            {state.showSidebar && (
                <ObservationMapSidebar
                    currentFilterSet={currentFilterSet}
                    schema={currentSchema}
                    user={user}
                    project={project}
                    selectedStations={state.selectedStations}
                    selectedLayerIdentifyRequests={
                        state.selectedLayerIdentifyRequests
                    }
                    field={symbology?.field}
                    showInTable={showInTable}
                    onClose={() =>
                        setState(state =>
                            update(state, {
                                showSidebar: { $set: false },
                            })
                        )
                    }
                />
            )}
        </>
    );
}

export default ObservationMap;
