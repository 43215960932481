import apiRequest from '../util/apiRequest';
import { createAction } from 'redux-act';
import { message } from 'antd';

import { makeCommentUrl, makeObservationUrl, models } from '../util/constants';

export const completeMarkForReview = createAction('Complete mark for review');

export const markObservationForReview = ({
    comment,
    id,
    user,
    schema,
    project,
}) => {
    const url = makeCommentUrl(makeObservationUrl(id));
    return markForReview({
        comment,
        url,
        id,
        user,
        schema,
        model: models.OBSERVATION,
        project,
    });
};

export const markStationForReview = ({ comment, id, user, schema }) => {
    // TODO: Implement station commenting
    throw new Error('Station commenting is not yet implemented');
};

const markForReview = ({ comment, url, id, user, schema, model, project }) => {
    return async dispatch => {
        const form = new FormData();
        form.set('text', comment);

        const options = {
            method: 'POST',
            headers: {
                'content-type': `multipart/form-data`,
            },
            data: form,
            url,
        };
        const response = await apiRequest(options);
        const payload = response.data;

        if (payload.status === 200) {
            message.success(`Successfully marked ${id} for review`);
            dispatch(
                completeMarkForReview({
                    id,
                    user,
                    schema,
                    model,
                    project,
                })
            );
        } else {
            message.error(
                `Oops! There was an error submitting your comment: ${payload.error}`
            );
        }
        return payload;
    };
};
