import React, { useState } from 'react';
import apiRequest from '../util/apiRequest';
import { Button, List, message, Space, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ShowIfManagerOrLoc from './ShowIfManagerOrLoc';
import UserAutocomplete from './UserAutocomplete';

import { makeObservationUrl } from '../util/constants';

const { Text } = Typography;

const ObservationFormOwner = ({
    observationId,
    locFieldValue,
    owner,
    setOwner,
    isOfflineDraft = false,
}) => {
    const [isEditOwner, setEditOwner] = useState(false);

    const onSelectNewOwner = async newOwner => {
        if (!!newOwner && owner.ownerId !== newOwner) {
            const formData = { owner: newOwner };
            try {
                const payload = await apiRequest.put(
                    makeObservationUrl(observationId),
                    formData
                );
                if (payload.status === 200 && payload.data) {
                    const updatedObservation = payload.data.result;
                    setOwner(updatedObservation.owner);
                    message.success(
                        `Updated owner to ${updatedObservation.owner.email}`
                    );
                } else {
                    message.error(`Failed to change observation owner`);
                }
            } catch {
                message.error(`Failed to change observation owner`);
            }
        }
        setEditOwner(false);
    };

    const ownerString = user => (
        <Space direction='vertical'>
            <span>
                {user?.firstName} {user?.lastName}
            </span>
            <span>{user?.email}</span>
            <span>{user?.ownerId}</span>
        </Space>
    );

    // offline data cannot be reassigned to another user
    const editOwnerButton = !isOfflineDraft && (
        <Button
            type='primary'
            ghost
            icon={<FontAwesomeIcon icon={['far', 'pencil']} />}
            onClick={() => setEditOwner(true)}
            size='small'
        >
            Edit
        </Button>
    );

    const ownerInformation = (
        <div className='observation__section--editable'>
            {ownerString(owner)}
            {editOwnerButton}
        </div>
    );

    return isOfflineDraft ? (
        <div className='observation__section'>
            <Text className='observation__section--title'>Owner</Text>
            {ownerInformation}
        </div>
    ) : (
        <ShowIfManagerOrLoc locFieldValue={locFieldValue}>
            <div className='observation__section'>
                <Text className='observation__section--title'>Owner</Text>
                {ownerInformation}
                {isEditOwner && (
                    <List.Item>
                        Select a different owner:
                        <UserAutocomplete
                            onSave={onSelectNewOwner}
                            onCancel={() => setEditOwner(false)}
                        />
                    </List.Item>
                )}
            </div>
        </ShowIfManagerOrLoc>
    );
};

export default ObservationFormOwner;
