import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { findIndex, sortBy, union } from 'lodash';
import moment from 'moment';

import {
    startFetchStations,
    completeFetchStations,
    failFetchStations,
    clearStations,
    addStation,
    updateStation,
    addUserToStation,
} from '../actions/stations';

const STATIONS_CACHE_TTL_DURATION = 60;
const STATIONS_CACHE_TTL_UNIT = 'minutes';

const initialState = Object.freeze({
    data: null,
    fetching: false,
    error: false,
    expirationDate: null,
});

const StationsReducer = createReducer(
    {
        [startFetchStations]: state =>
            update(state, {
                fetching: { $set: true },
                error: { $set: initialState.error },
            }),
        [completeFetchStations]: (state, payload) =>
            update(state, {
                data: { $set: sortBy(payload, ['stationName', 'stationId']) },
                fetching: { $set: initialState.fetching },
                error: { $set: initialState.error },
                expirationDate: {
                    $set: moment().add(
                        STATIONS_CACHE_TTL_DURATION,
                        STATIONS_CACHE_TTL_UNIT
                    ),
                },
            }),
        [failFetchStations]: (state, payload) =>
            update(state, {
                fetching: { $set: initialState.fetching },
                error: { $set: payload },
            }),
        [clearStations]: state =>
            update(state, {
                $set: initialState,
            }),
        [addStation]: (state, { station }) =>
            update(state, {
                data: { $push: [station] },
            }),
        [updateStation]: (state, { station }) => {
            const stationIdx = findIndex(
                state.data,
                s => s.stationId === station.stationId
            );

            return stationIdx < 0
                ? state
                : update(state, {
                      data: {
                          [stationIdx]: {
                              $set: station,
                          },
                      },
                  });
        },
        [addUserToStation]: (state, { station, user }) => {
            const stationIdx = findIndex(
                state.data,
                s => s.stationId === station.stationId
            );
            if (stationIdx < 0) {
                return state;
            }
            const updatedStationRegisteredUserIds = union(
                state.data[stationIdx].registeredUserIds,
                [user.userId]
            );
            return update(state, {
                data: {
                    [stationIdx]: {
                        registeredUserIds: {
                            $set: updatedStationRegisteredUserIds,
                        },
                    },
                },
            });
        },
    },
    initialState
);

export default StationsReducer;
