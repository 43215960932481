import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { object, func, arrayOf, bool } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { StaticMap, Marker } from 'react-map-gl';
import { Drawer, Typography, Space, Tag, Button } from 'antd';
import { RightOutlined, UpOutlined } from '@ant-design/icons';
import { map } from 'lodash';
import Color from 'color';

import ReviewModal from './ReviewModal';
import MediaLauncher from './MediaLauncher';

import { makeMapStyle } from '../util/mapHelper';
import { markObservationForReview } from '../actions/review';
import { setObservationEditLocation } from '../actions/ui';
import { tableRowData, tableColumn, basemap } from '../util/propTypes';
import { pluckFieldNamesFromSchema } from '../util/schemaHelper';
import { draftStatus } from '../util/constants';
import { uploadOfflineData } from '../util/offline';

const ZOOM_LEVEL = 12;
const STROKE_WIDTH = 2;
const MARKER_RADIUS = 10;
const MARKER_DIAMETER = MARKER_RADIUS * 2 + STROKE_WIDTH * 2;
const MARKER_COLOR = '#ff7d00';

const BlackMarker = () => (
    <svg height={MARKER_DIAMETER} width={MARKER_DIAMETER}>
        <circle
            cx={MARKER_RADIUS + STROKE_WIDTH}
            cy={MARKER_RADIUS + STROKE_WIDTH}
            r={MARKER_RADIUS}
            fill={MARKER_COLOR}
            stroke={Color(MARKER_COLOR)
                .darken(0.3)
                .string()}
            strokeWidth='2'
        />
    </svg>
);

function ObservationTableSidebar({
    isAdmin,
    data,
    displayColumns,
    clearData,
    user,
    schema,
    basemap,
    project,
    isOffline,
}) {
    const [isVisible, setIsVisible] = useState(false);
    const [stationDetailsVisible, setStationDetailsVisible] = useState(false);
    const location = useLocation();

    const { Text } = Typography;
    const {
        key: observationId,
        geometry: { x, y },
        isOwner,
        isLoc,
        reviewStatus,
    } = data;
    const { observation, station } = schema;
    const isOfflineDraftObservation = observationId?.includes('draft');

    useEffect(() => {
        data && setIsVisible(true);
    }, [data]);

    const observationFields = pluckFieldNamesFromSchema(observation);
    const stationFields = pluckFieldNamesFromSchema(station);
    const useStations = project?.useStations;

    const detailRows = map(displayColumns, ({ dataIndex, title }) => {
        if (!dataIndex || !title) {
            return;
        }

        // Don't list empty fields
        const value = data[dataIndex];

        if (!value) {
            return;
        }

        return (
            <li className='observation-drawer__detail--item' key={dataIndex}>
                <Text className='label'>{title}</Text>
                <Text className='value'>{value}</Text>
            </li>
        );
    });

    const mapStyle = makeMapStyle({ basemap });
    const staticMap = data && (
        <StaticMap
            viewState={{
                latitude: y,
                longitude: x,
                zoom: ZOOM_LEVEL,
            }}
            mapStyle={mapStyle}
            width={'100%'}
            height={'200px'}
        >
            <Marker
                latitude={y}
                longitude={x}
                offsetLeft={-MARKER_RADIUS}
                offsetTop={-MARKER_RADIUS}
            >
                <BlackMarker />
            </Marker>
        </StaticMap>
    );

    const dispatch = useDispatch();
    const reviewButton = user && !isOfflineDraftObservation && (
        <ReviewModal
            key='review-button'
            submitCallback={comment => {
                dispatch(
                    markObservationForReview({
                        comment,
                        id: observationId,
                        user,
                        schema,
                        project,
                    })
                );
            }}
        />
    );
    const editButton = (isOwner || isAdmin || isLoc) && (
        <Link
            to={{ pathname: `/observations/${observationId}` }}
            className='ant-btn ant-btn-sm'
            key='edit-button'
            onClick={() => dispatch(setObservationEditLocation(location))}
        >
            Edit
        </Link>
    );

    const uploadOfflineDataButton =
        !isOffline && isOfflineDraftObservation ? (
            <Button
                onClick={() => {
                    uploadOfflineData({
                        observationId,
                        idbObservationData: data,
                        schema,
                        user,
                        useStations,
                        clearData,
                        dispatch,
                    });
                }}
                className='ant-btn ant-btn-sm ant-btn-primary'
                key='upload-button'
                disabled={isOffline}
            >
                Upload
            </Button>
        ) : null;

    const reviewStatusTag = reviewStatus ? (
        <Tag color='warning' key='review-status-tag'>
            {reviewStatus}
        </Tag>
    ) : null;

    const headerItems = [
        reviewButton,
        editButton,
        reviewStatusTag,
        uploadOfflineDataButton,
    ];
    const headerItemCount = headerItems.reduce(
        (count, item) => (item ? count + 1 : count),
        0
    );
    const headerContent =
        headerItemCount > 1 ? (
            <Space>{headerItems}</Space>
        ) : (
            <span>{headerItems}</span>
        );

    // There are a handful of attributes that are not included in either of the station or
    // observation schema but that we want to include in the sidebar. We extract them here
    // and add them to the appropriate sections when rendering the component.
    const stationNameElement = detailRows.find(
        row => row && row.key === 'stationName'
    );
    const latLongElements = detailRows.filter(
        row => row && ['latitude', 'longitude'].includes(row.key)
    );
    const observationDateElement = detailRows.find(
        row => row && row.key === 'collectionDate'
    );

    return (
        <Drawer
            className='observation-drawer'
            visible={isVisible}
            placement='right'
            mask={false}
            maskClosable={false}
            closable={true}
            onClose={() => {
                setIsVisible(false);
                clearData();
            }}
        >
            {headerContent}
            {reviewStatus === draftStatus && (
                <div className='offline-interior-banner'>
                    <div className='offline-interior-banner__text'>
                        <b>This observation was created offline.</b>
                        <br />
                        Use the edit button above to make changes before
                        uploading.
                    </div>
                </div>
            )}

            {useStations && (
                <div>
                    <h2 className='observation-drawer__title'>Station</h2>
                    {!stationDetailsVisible && (
                        <div className='observation-drawer__detail'>
                            {stationNameElement}
                        </div>
                    )}
                    <div className='observation-drawer__map'>{staticMap}</div>
                    <div>
                        {stationDetailsVisible && (
                            <ul className='observation-drawer__detail'>
                                {[
                                    stationNameElement,
                                    ...latLongElements,
                                    ...detailRows.filter(
                                        row =>
                                            row &&
                                            stationFields.includes(row.key)
                                    ),
                                ]}
                            </ul>
                        )}
                        <button
                            className={'toggle-station-details'}
                            onClick={() =>
                                setStationDetailsVisible(!stationDetailsVisible)
                            }
                        >
                            {stationDetailsVisible ? (
                                <div>
                                    <UpOutlined className='station-details-caret' />
                                    {'Hide station details'}
                                </div>
                            ) : (
                                <div>
                                    <RightOutlined className='station-details-caret' />
                                    {'Show station details'}
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            )}
            <div>
                <h2 className='observation-drawer__title'>Observation</h2>
                {!useStations && (
                    <>
                        <div className='observation-drawer__map'>
                            {staticMap}
                        </div>
                        <div>
                            <ul className='observation-drawer__detail'>
                                {latLongElements}
                            </ul>
                        </div>
                    </>
                )}
                <MediaLauncher observations={[data]} />
                <ul className='observation-drawer__detail'>
                    {[
                        observationDateElement,
                        ...detailRows.filter(
                            row => row && observationFields.includes(row.key)
                        ),
                    ]}
                </ul>
            </div>
        </Drawer>
    );
}

ObservationTableSidebar.propTypes = {
    isAdmin: bool,
    data: tableRowData.isRequired,
    displayColumns: arrayOf(tableColumn).isRequired,
    clearData: func.isRequired,
    schema: object.isRequired,
    user: object,
    basemap: basemap.isRequired,
    project: object,
    isOffline: bool,
};

export default ObservationTableSidebar;
