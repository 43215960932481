import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    startFetchGeometrySource,
    completeFetchGeometrySource,
    failFetchGeometrySource,
} from '../actions/geometrySources';

const initialState = Object.freeze({});

const schemaReducer = createReducer(
    {
        [startFetchGeometrySource]: (state, payload) =>
            update(state, {
                [payload]: {
                    $set: { data: null, fetching: true, error: false },
                },
            }),
        [completeFetchGeometrySource]: (state, payload) =>
            update(state, {
                [payload.sourceId]: {
                    $set: {
                        data: payload,
                        fetching: false,
                        error: false,
                    },
                },
            }),
        [failFetchGeometrySource]: (state, payload) =>
            update(state, {
                [payload.sourceId]: {
                    $set: { data: null, fetching: false, error: payload },
                },
            }),
    },
    initialState
);

export default schemaReducer;
