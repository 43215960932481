import moment from 'moment';

import { formatUnit, getValidUnits } from './units';

export const formatTableFieldValue = (fieldDef, value) => {
    if (!fieldDef || value === undefined) return value;

    const dataType = fieldDef?.type;
    let displayValue = value;

    // Format the data types for Time, Number, and String.
    if (dataType === 'Time') {
        if (value.hour === undefined) {
            return value;
        }
        displayValue = moment
            .utc(`${value.hour}:${value.minute}`, 'HH:mm')
            .format('hh:mm a');
    } else if (dataType === 'Number') {
        // Keep whole numbers whole, otherwise include specified number of
        // digits of precision (default 2 as a precaution against undefined
        // schema definition). Include commas at the thousands mark.
        displayValue = parseFloat(value).toLocaleString('us-EN', {
            maximumFractionDigits: fieldDef.precision ?? 2,
        });
    } else if (dataType === 'Date') {
        // UTCMonth in Javascript is 0 indexed. Adjust our 1-indexed real-world
        // value to comply with Javascript
        let dateObject = value;
        if (dateObject?.month) {
            dateObject = { ...dateObject, month: dateObject.month - 1 };
        }
        displayValue = moment.utc(dateObject).format('YYYY-MM-DD');
    } else if (dataType === 'DateTime') {
        displayValue = moment.utc(value).format('YYYY-MM-DD hh:mm a');
    } else if (dataType === 'String') {
        displayValue = value.replace(/[\r]/g, '\n');
    } else if (dataType === 'Boolean') {
        displayValue = value.toString().toLowerCase() === 'true' ? 'Yes' : 'No';
    }

    // Replace the stored value with the lookup value on the schema definition
    if (fieldDef?.values?.length) {
        const label = fieldDef.values.filter(
            lookup => lookup.value === value
        )[0]?.label;

        // Keep the original value if there was no match
        if (label) {
            displayValue = label;
        }
    }

    // If this is a reclass field, substitute the appropriate reclassification
    // label for the reclassSource field value. For reclass fields that don't
    // have an entry in the reclassTable, it will default to use the reclassSource
    // field value label from above.
    if (fieldDef.reclassTable) {
        const match = fieldDef.reclassTable.filter(reclass =>
            reclass.values.includes(value)
        );
        if (match.length) {
            displayValue = match[0].label;
        }
    }

    return displayValue;
};

export const formatFormFieldValue = (fieldDef, value) => {
    if (!fieldDef) return value;

    const dataType = fieldDef?.type;
    let displayValue = value;

    // Time must be fed to DateTime Form fields as a momentJS object
    if (dataType === 'Time' || dataType === 'DateTime') {
        displayValue = moment.utc(value);
    }
    // UTCMonth in Javascript is 0 indexed. Adjust our 1-indexed real-world
    // value to comply with Javascript
    if (dataType === 'Date') {
        let dateObject = value;
        if (dateObject?.month) {
            dateObject = { ...dateObject, month: dateObject.month - 1 };
        }
        displayValue = moment.utc(dateObject);
    }

    // Form fields with unit options are comprised of two inputs, `value` and `unit`
    // but this is only applicable if there is overlap between the units defined in
    // the `unitsAvailable` field in the schema and the valid units defined in
    // `units.js`. If the unitsAvailable attribute only includes "invalid" units, the
    // form will default to just showing the unit defined in the "units" and ignore
    // the other options
    if (
        dataType === 'Number' &&
        getValidUnits(fieldDef?.unitsAvailable).length
    ) {
        displayValue = {
            value,
            unit: formatUnit(fieldDef.units),
        };
    }

    return displayValue;
};

export const precisionToStep = precision => {
    /*

    Converts an integer "precision" value to the corresponding "step" value. The
    step value is a float of 0.000...1. This number has a precision that matches
    the 'precision' parameter

    e.g. precisionToStep(2) -> 0.01

    */
    if (precision < 1) {
        return 1;
    }

    return parseFloat('0.' + '0'.repeat(precision - 1) + '1');
};
