import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { isFunction } from 'lodash';

import { Button, Card, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Paragraph, Title } = Typography;

const FormCard = ({
    title,
    subtitle,
    children,
    className,
    backTo,
    ...props
}) => {
    const history = useHistory();
    return (
        <>
            <Card {...props} className={`form-card ${className || ''}`}>
                <div className='form-card__title'>
                    {backTo && (
                        <Button
                            type='link'
                            onClick={() =>
                                isFunction(backTo)
                                    ? backTo({ history })
                                    : history.push(backTo)
                            }
                            icon={
                                <FontAwesomeIcon icon={['far', 'arrow-left']} />
                            }
                        />
                    )}
                    {title && title.length > 0 && (
                        <Title
                            level={3}
                            style={{ marginBottom: 0 }}
                            className='form-card__title-text'
                        >
                            {title}
                        </Title>
                    )}
                </div>
                {subtitle && subtitle.length > 0 && (
                    <Paragraph className='form-card__subtitle'>
                        {subtitle}
                    </Paragraph>
                )}
                {children}
            </Card>
        </>
    );
};

FormCard.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
};

export default FormCard;
