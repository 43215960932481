import React from 'react';
import { Spin } from 'antd';

const OverlaySpinner = props => {
    if (!props.show) {
        return null;
    }

    return (
        <div className='overlay-spinner'>
            <Spin size='large' />
        </div>
    );
};

export default OverlaySpinner;
