import React, { useState } from 'react';
import { AutoComplete, Button, Space } from 'antd';
import apiRequest from '../util/apiRequest';
import { useDebounce } from 'react-use';

import { userSearchUrl } from '../util/constants';

const UserAutocomplete = ({ onSave, onCancel }) => {
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchString, setSearchString] = useState(null);

    // eslint-disable-next-line no-unused-vars
    const [, cancel] = useDebounce(
        () => {
            const handleSearch = async queryString => {
                const result = await apiRequest.post(userSearchUrl, {
                    data: queryString,
                });
                if (result.status === 200 && result?.data?.result) {
                    setUserOptions(result.data.result);
                }
            };
            if (searchString) handleSearch(searchString);
        },
        300,
        [searchString]
    );

    const userString = u => `${u?.first_name} ${u?.last_name} (${u?.email})`;

    const options = userOptions.map(u => ({
        // antd's AutoComplete component expects a string value
        value: JSON.stringify(u.id),
        label: userString(u),
    }));

    return (
        <div className='observation__section--editable'>
            <AutoComplete
                mode
                onSearch={input => setSearchString(input)}
                style={{ width: '100%' }}
                onSelect={setSelectedUser}
                placeholder={'Search users by email or name'}
                options={options}
            />
            <Space style={{ marginLeft: '10px' }}>
                <Button
                    type='primary'
                    onClick={() => onSave(selectedUser)}
                    size='small'
                >
                    Save
                </Button>
                <Button
                    type='primary'
                    ghost
                    onClick={() => {
                        setSelectedUser(null);
                        setSearchString(null);
                        onCancel();
                    }}
                    size='small'
                >
                    Cancel
                </Button>
            </Space>
        </div>
    );
};

export default UserAutocomplete;
