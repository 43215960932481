import map from 'lodash/map';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import flatMap from 'lodash/flatMap';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';

import { schemaFieldValueFormatter } from './schemaHelper';
import { formatFormFieldValue } from './fieldFormatter';
import { convertUnit, getValidUnits } from './units';

export function getFolderKey(folder) {
    return `${folder.label}-fields`;
}

/*
  Given a project field schema object, and an optional flat map of values,
  create a dictionary where the keys are generated names of repeating
  field groups and the values are single element arrays containing an object
  where the keys are the names of each field in the repeating group and the
  values are null. This data structure can be passed to the Ant Design form
  `setFieldsValue` method to ensure that an empty or initial set of fields
  is displayed.
*/
export function getInitialRepeaterValues(schema, data) {
    const repeaters = schema.observation.folders.filter(
        folder => folder.repeatable
    );
    return repeaters.reduce(
        (ac, folder) => ({
            ...ac,
            [getFolderKey(folder)]: [
                folder.fields.reduce(
                    (acc, field) => ({
                        ...acc,
                        [field.name]: (data && data[field.name]) ?? null,
                    }),
                    {}
                ),
            ],
        }),
        {}
    );
}

/*
  Given a project field schema object and a form data object created by an Ant
  Design FORM, create an array of objects that can be POSTed to the observation
  API endpoint.
*/
export function formDataToObjects(schema, data) {
    const repeaters = schema.observation.folders.filter(
        folder => folder.repeatable
    );

    /* Repeater fields and number fields with unit selectors require special parsing */
    const unitsFields = getUnitsFields(schema);
    const unitsFieldKeys = unitsFields.map(field => field.name);
    const repeaterKeys = map(repeaters, r => getFolderKey(r));
    const specialHandlingKeys = [...unitsFieldKeys, ...repeaterKeys];

    const fieldKeys = Object.keys(data).filter(
        k => !includes(specialHandlingKeys, k)
    );

    const singleValueData = pick(data, fieldKeys);

    unitsFields.forEach(field => {
        const key = field.name;
        if (data[key]) {
            const { units: toUnit, precision } = field;
            const { unit: fromUnit, value } = data[key];
            Object.assign(singleValueData, {
                [key]: convertUnit(value, fromUnit, toUnit, precision),
            });
        }
    });

    const repeaterValues = repeaters.flatMap(folder => {
        return data[getFolderKey(folder)]?.map(entry => {
            return folder.fields.reduce((acc, field) => {
                return {
                    ...acc,
                    [field.name]: entry[field.name],
                };
            }, {});
        });
    });

    if (repeaters.length > 0) {
        return repeaterValues.map(r => Object.assign({}, singleValueData, r));
    }

    // If there are no repeating fields we still return the data as a single
    // element array for consistency.
    return [singleValueData];
}

/*
    Get fields from an observation that have multiple valid unit choices
    These fields require special handling in forms
*/
export const getUnitsFields = schema => {
    const reclass = [
        {
            fields: schema.observation.reclassFields,
            label: 'Reclass',
            repeatable: false,
        },
    ];

    const observationFolders = [...schema.observation.folders, ...reclass];
    const fields = flatMap(
        observationFolders.map(folder =>
            folder.fields?.filter(
                field => getValidUnits(field.unitsAvailable).length
            )
        )
    );
    return fields;
};

/*
    Flatten essential fields on an observation and format field values
    to be compatible with antd Form fields
*/
export const flattenObservation = (observation, schema) => {
    const formatter = schemaFieldValueFormatter(schema, formatFormFieldValue);
    const { attributes, collectionDate } = observation;

    const flattenedObservation = {
        ...formatter(cloneDeep(attributes)),
        ...observation,
        collectionDate: moment.utc(collectionDate),
    };

    // unit selection fields are not actually flat; they have the special format {value, unit}
    const unitFields = getUnitsFields(schema);
    unitFields.forEach(({ name, units }) => {
        if (flattenedObservation[name]) {
            return;
        }
        return Object.assign(flattenedObservation, {
            [name]: { value: null, unit: units },
        });
    });

    return flattenedObservation;
};

/*
Create an initial values dict for a form based off a schema

Only fields that require special handling need to appear here.

Input:
    schema: a project schema object
Output:
    { unitsFieldName: { value: null, unit: 'defaultUnit' } ... }
*/

export const createEmptyInitialFormData = schema => {
    const formData = {};

    // unit selection fields
    const unitFields = getUnitsFields(schema);
    unitFields.forEach(({ name, units }) =>
        Object.assign(formData, { [name]: { value: null, unit: units } })
    );

    return formData;
};

/*
The Antd Upload component expects photo objects in a particular format, else it breaks

Our app model:
{
    description: ""
    label: "xxx.png"
    photoId: "1"
    url: "http://some-url.png"
}

Antd spec:
    {
        uid: '1',
        name: 'xxx.png',
        status: 'done',
        url: "http://some-url.png"
    }

Antd requires the id field be called uid field but for consistency with our API, also include the same value on the key `photoId`
*/
export const formatPhotosForUploadComponent = photoList =>
    map(photoList, ({ url, photoId, label, description }) => ({
        uid: photoId,
        photoId,
        url,
        name: label,
        label,
        description,
    }));

export const formatMediaForUploadComponent = mediaList =>
    map(mediaList, ({ url, fileId, label, description, type }) => ({
        uid: fileId,
        fileId,
        url,
        name: label,
        label,
        description,
        type,
    }));

/*
  Normalize the objects that represent media files, whether they are returned
  from the Observation API, or from a request to save a new media item.
*/
export const normalizeFileObject = file =>
    file?.response ? Object.assign({}, file.response.result, file) : file;

export const getFoldersFieldDefs = folders =>
    JSON.stringify(
        folders
            .flatMap(f => f.fields)
            .reduce((a, v) => ({ ...a, [v['name']]: v['type'] }), {})
    );
