import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { findIndex, omit, forEach, union, cloneDeep, pickBy } from 'lodash';

import {
    startFetchFilterSet,
    completeFetchFilterSet,
    failFetchFilterSet,
    setFilterSetStale,
    setActiveFilterSetName,
    setIsTableVisible,
    setSelectedObservations,
    setTableData,
    setTablePage,
    setTablePageSize,
    updateObservations,
    updateVisualizationFilterSetDisplayFields,
    updateVisualizationFilterSetFieldOrder,
    clearFilterSetData,
    copyFilterSetData,
    startFetchFilterSetManagerFields,
    completeFetchFilterSetManagerFields,
    failFetchFilterSetManagerFields,
} from '../actions/filterSet';

import {
    completeSaveVisualization,
    updateVisualizationFilterSetDataSource,
} from '../actions/visualization';

import { updateStation } from '../actions/stations';

import { completeMarkForReview } from '../actions/review';

import {
    completeDeleteObservation,
    completeDeleteStationlessObservation,
} from '../actions/observations';

import { getObservationDisplayValues } from '../util/schemaHelper';

import {
    findStation,
    findObservation,
    updateObservationInState,
    replaceInListOrUnshift,
    offlineStatesAreMismatched,
} from '../util/filterSetHelper';

import { copyFilterSets } from '../util/visualizationsHelper';

import { models, staleValue } from '../util/constants';

import { isArrayIndex } from '../util';

/* Filter Sets are keyed by name and contain the shape:
    {
        fetching: true|false,
        filters: {Fieldscope Query object},
        results: [Fieldscope Result list]
    }
*/

export const initialDatasetState = Object.freeze({
    filterSets: {},
    filterSetManagerFields: {},
    activeFilterSetName: null,
    isTableVisible: {},
    tableData: {},
    tablePage: {},
    tablePageSize: {},
    selectedObservations: {},
});

// Given the previous state, a changeSet to apply to the state resulting in
// an updated set of filterSets, and a list of filterSets to update tableData for,
// returns a state with the tableData updated correspondingly.
export const updateTableData = (
    state,
    changeSet,
    tableDataFilterSetsToUpdate,
    schema,
    user,
    project
) => {
    // Update the filterSet data, then rebuild the table data based on
    // those updates.
    const stateWithUpdatedFilterSets = update(state, changeSet);
    const tableDataChangeSet = tableDataFilterSetsToUpdate.reduce(
        (acc, filterSetName) => {
            acc.tableData = acc.tableData || {};
            acc.tableData[filterSetName] = {
                $set: getObservationDisplayValues(
                    schema,
                    stateWithUpdatedFilterSets.filterSets[filterSetName].filters
                        .fields,
                    stateWithUpdatedFilterSets.filterSets[filterSetName]
                        .results,
                    user,
                    project
                ),
            };
            return acc;
        },
        {}
    );

    return update(stateWithUpdatedFilterSets, tableDataChangeSet);
};

const filterSetReducer = createReducer(
    {
        [startFetchFilterSet]: (state, payload) =>
            update(state, {
                filterSets: {
                    $merge: {
                        [payload.filterSetName]: {
                            fetching: true,
                            filters: payload.filters,
                            results: null,
                            error: false,
                            stale: null,
                        },
                    },
                },
            }),
        [completeFetchFilterSet]: (state, payload) =>
            update(state, {
                filterSets: {
                    [payload.filterSetName]: {
                        fetching: { $set: false },
                        results: { $set: payload.result },
                        error: { $set: false },
                    },
                },
            }),
        [startFetchFilterSetManagerFields]: (state, payload) =>
            update(state, {
                filterSetManagerFields: {
                    $merge: {
                        [payload.filterSetName]: {
                            fetching: true,
                            results: null,
                            error: false,
                            stale: null,
                        },
                    },
                },
            }),
        [completeFetchFilterSetManagerFields]: (state, payload) =>
            update(state, {
                filterSetManagerFields: {
                    [payload.filterSetName]: {
                        fetching: { $set: false },
                        results: { $set: payload.result },
                        error: { $set: false },
                    },
                },
            }),
        [updateVisualizationFilterSetDataSource]: (state, payload) =>
            update(state, {
                filterSets: {
                    [payload.filterSetName]: {
                        fetching: { $set: false },
                        results: { $set: null },
                        error: { $set: false },
                        filters: { $set: payload.query },
                    },
                },
                tableData: {
                    $unset: [payload.filterSetName],
                },
            }),
        [updateVisualizationFilterSetDisplayFields]: (
            state,
            { filterSetName, displayFields }
        ) =>
            update(state, {
                filterSets: {
                    [filterSetName]: {
                        filters: { displayFields: { $set: displayFields } },
                    },
                },
            }),
        [updateVisualizationFilterSetFieldOrder]: (
            state,
            { filterSetName, fieldOrder }
        ) =>
            update(state, {
                filterSets: {
                    [filterSetName]: {
                        filters: { fieldOrder: { $set: fieldOrder } },
                    },
                },
            }),
        [copyFilterSetData]: (state, { from, to }) =>
            copyFilterSets(state, from, to),
        [failFetchFilterSet]: (state, payload) => {
            // When dispatched by `dispatchApiCompleteOrFail`,
            // failFetchFilterSet will have a payload that allows identifying
            // which filterSet failed.
            if (payload?.result?.filterSetName) {
                return update(state, {
                    filterSets: {
                        [payload.result.filterSetName]: {
                            fetching: { $set: false },
                            results: { $set: null },
                            error: {
                                $set:
                                    payload.error ??
                                    `An error occurred when fetching results for filterSet ${payload.result.filterSetName}`,
                            },
                        },
                    },
                });
            }

            // When dispatched by `logErrorAndDispatchFailure`, it only
            // contains a list of error strings.
            return state;
        },
        [failFetchFilterSetManagerFields]: (state, payload) => {
            // When dispatched by `dispatchApiCompleteOrFail`,
            // failFetchFilterSet will have a payload that allows identifying
            // which filterSet failed.
            if (payload?.result?.filterSetName) {
                return update(state, {
                    filterSetManagerFields: {
                        [payload.result.filterSetName]: {
                            fetching: { $set: false },
                            results: { $set: null },
                            error: { $set: payload.error },
                        },
                    },
                });
            }

            // When dispatched by `logErrorAndDispatchFailure`, it only
            // contains a list of error strings.
            return state;
        },
        [setFilterSetStale]: (state, payload) => {
            const changeSet = {
                filterSets: {
                    [payload.filterSetName]: {
                        stale: { $set: payload.stale },
                    },
                },
            };
            if (payload.stale === staleValue.fetch) {
                changeSet.tableData = {
                    $unset: [payload.filterSetName],
                };
                changeSet.tablePage = {
                    $unset: [payload.filterSetName],
                };
                changeSet.selectedObservations = {
                    $unset: [payload.filterSetName],
                };
            }
            return update(state, changeSet);
        },
        [setActiveFilterSetName]: (state, payload) =>
            update(state, {
                activeFilterSetName: {
                    $set: payload,
                },
            }),
        [setIsTableVisible]: (state, { filterSetName, isTableVisible }) =>
            update(state, {
                isTableVisible: {
                    [filterSetName]: { $set: isTableVisible },
                },
            }),
        [setTableData]: (state, { filterSetName, data }) =>
            update(state, {
                tableData: {
                    [filterSetName]: { $set: data },
                },
            }),
        [setTablePage]: (state, { filterSetName, page }) =>
            update(state, {
                tablePage: {
                    [filterSetName]: { $set: page },
                },
            }),
        [setTablePageSize]: (state, { filterSetName, pageSize }) =>
            update(state, {
                tablePageSize: {
                    [filterSetName]: { $set: pageSize },
                },
            }),
        [setSelectedObservations]: (
            state,
            {
                controllingWidget,
                filterSetName,
                selectedBarNames,
                observationIds,
            }
        ) =>
            update(state, {
                selectedObservations: {
                    controllingWidget: { $set: controllingWidget },
                    selectedBarNames: { $set: selectedBarNames },
                    [filterSetName]: { $set: observationIds },
                },
            }),
        [updateObservations]: (
            state,
            { observations, stations, user, schema, project }
        ) => {
            const changeSet = {};
            const tableDataFilterSetsToUpdate = [];

            let updatedState = cloneDeep(state);

            observations.forEach(observation => {
                const observationsInFilterSets = findObservation(
                    updatedState,
                    observation
                );

                const station = stations.find(
                    ({ stationId }) => stationId === observation.stationId
                );

                const updatedStationRegisteredUserIds = union(
                    station?.registeredUserIds,
                    [user.userId]
                );

                const notFoundStation = pickBy(
                    observationsInFilterSets,
                    v => !isArrayIndex(v.stationIdx)
                );

                const [newState, filterSetsToUpdate] = updateObservationInState(
                    updatedState,
                    observation,
                    observationsInFilterSets,
                    updatedStationRegisteredUserIds
                );
                tableDataFilterSetsToUpdate.push(...filterSetsToUpdate);
                updatedState = newState;

                // New observations on existing stations where the user hadn't previously added an observation will not be present in the My Observations filterSet, but now should
                // For simplicity, insert the station into all fetched filterSets, without matching against the filterSet's filter logic
                if (observation.ownerId === user?.id) {
                    const filterSetsWithoutStation = omit(
                        updatedState.filterSets,
                        Object.keys(observationsInFilterSets)
                    );

                    // Grab the station to update with the new observation
                    const updatedStation = update(station, {
                        observations: {
                            $apply: replaceInListOrUnshift(
                                observation,
                                'observationId'
                            ),
                        },
                        registeredUserIds: {
                            $set: updatedStationRegisteredUserIds,
                        },
                    });

                    forEach(
                        filterSetsWithoutStation,
                        (filterSet, filterSetName) => {
                            const ownerChangeSet = { filterSets: {} };
                            const filterSetStationList = [...filterSet.results];
                            // Add station to top of list
                            filterSetStationList.unshift(updatedStation);
                            ownerChangeSet.filterSets[filterSetName] = {
                                results: { $set: filterSetStationList },
                            };
                            // Show user the new observation at the top of the list
                            if (filterSetName === state.activeFilterSetName) {
                                ownerChangeSet.tablePage = {
                                    $set: {
                                        [updatedState.activeFilterSetName]: 1,
                                    },
                                };
                            }
                            updatedState = update(updatedState, ownerChangeSet);
                            // Flag filterSet's table data for update
                            tableDataFilterSetsToUpdate.push(filterSetName);
                        }
                    );
                }

                if (Object.keys(notFoundStation).length > 0) {
                    // Handle new observations at stations not included in the filterSet
                    // For simplicity, the station & observation are added to all stashed filterSet lists. In the future, we may check them against the filterSet's filter logic.

                    // Grab the station to update with the new observation
                    const updatedStation = update(station, {
                        observations: {
                            $apply: replaceInListOrUnshift(
                                observation,
                                'observationId'
                            ),
                        },
                        registeredUserIds: {
                            $set: updatedStationRegisteredUserIds,
                        },
                    });

                    // Insert station into all filterSet data, for now
                    Object.entries(updatedState.filterSets).forEach(
                        ([filterSetName, filterSet]) => {
                            if (
                                offlineStatesAreMismatched(
                                    observation.observationId,
                                    filterSetName
                                )
                            ) {
                                // Edits to offline observations should not update the other
                                // filter sets and edits to online observations should not
                                // update the offline filterset so we exit early
                                return;
                            }
                            const filterSetStationList = replaceInListOrUnshift(
                                updatedStation,
                                'stationId'
                            )(filterSet?.results || []);
                            const newStationChangeSet = {
                                filterSets: {
                                    [filterSetName]: {
                                        results: { $set: filterSetStationList },
                                    },
                                },
                            };
                            updatedState = update(
                                updatedState,
                                newStationChangeSet
                            );

                            // We will only attempt to update the table data section
                            // for a given filter set if it has already been populated
                            // and is not already in the list of filter sets to
                            // be updated
                            if (
                                state.tableData[filterSetName] &&
                                tableDataFilterSetsToUpdate.indexOf[
                                    filterSetName
                                ] === -1
                            ) {
                                tableDataFilterSetsToUpdate.push(filterSetName);
                            }
                        }
                    );

                    // New stations will be at the top of the table
                    changeSet.tablePage = {
                        $set: { [state.activeFilterSetName]: 1 },
                    };
                }
            });

            // Select & show observations only on the current active filterset
            const obsIds = observations.map(obs => obs.observationId);
            changeSet.selectedObservations = {};
            changeSet.selectedObservations[state.activeFilterSetName] = {
                $set: obsIds,
            };

            return updateTableData(
                updatedState,
                changeSet,
                tableDataFilterSetsToUpdate,
                schema,
                user,
                project
            );
        },
        [completeDeleteObservation]: (state, payload) => {
            const changeSet = {};

            Object.entries(findObservation(state, payload)).forEach(
                ([filterSetName, { stationIdx, observationIdx }]) => {
                    changeSet.filterSets = changeSet.filterSets || {};
                    if (stationIdx >= 0) {
                        changeSet.filterSets[filterSetName] = {
                            results: {
                                [stationIdx]: {
                                    observations: {
                                        $splice: [[observationIdx, 1]],
                                    },
                                },
                            },
                        };
                    }

                    if (state.tableData[filterSetName]) {
                        const observationIdx = findIndex(
                            state.tableData[filterSetName],
                            o => o.key === payload.observationId
                        );
                        if (observationIdx >= 0) {
                            changeSet.tableData = changeSet.tableData || {};
                            changeSet.tableData[filterSetName] = {
                                $splice: [[observationIdx, 1]],
                            };
                        }
                    }
                }
            );

            return update(state, changeSet);
        },
        [completeDeleteStationlessObservation]: (state, payload) => {
            const changeSet = {};

            Object.entries(findObservation(state, payload)).forEach(
                ([filterSetName, { stationIdx }]) => {
                    changeSet.filterSets = changeSet.filterSets || {};
                    if (stationIdx >= 0) {
                        changeSet.filterSets[filterSetName] = {
                            results: {
                                $splice: [[stationIdx, 1]],
                            },
                        };
                    }

                    if (state.tableData[filterSetName]) {
                        const observationIdx = findIndex(
                            state.tableData[filterSetName],
                            o => o.key === payload.observationId
                        );
                        if (observationIdx >= 0) {
                            changeSet.tableData = changeSet.tableData || {};
                            changeSet.tableData[filterSetName] = {
                                $splice: [[observationIdx, 1]],
                            };
                        }
                    }
                }
            );

            return update(state, changeSet);
        },
        [updateStation]: (state, { station, user, schema, project }) => {
            const changeSet = {};
            const tableDataFilterSetsToUpdate = [];

            // Create a payload using the values of the station EXCEPT observations,
            // which are initialized to [] by default, and we don't want to overwrite
            // the observations in the filterSet with that [].
            const payload = omit(station, 'observations');

            Object.entries(findStation(state, station)).forEach(
                ([filterSetName, stationIdx]) => {
                    changeSet.filterSets = changeSet.filterSets || {};
                    if (stationIdx >= 0) {
                        changeSet.filterSets[filterSetName] = {
                            results: {
                                [stationIdx]: {
                                    $merge: payload,
                                },
                            },
                        };
                    }

                    // We will only attempt to update the table data section
                    // for a given filter set if it has already been populated
                    if (state.tableData[filterSetName]) {
                        tableDataFilterSetsToUpdate.push(filterSetName);
                    }
                }
            );

            return updateTableData(
                state,
                changeSet,
                tableDataFilterSetsToUpdate,
                schema,
                user,
                project
            );
        },
        [clearFilterSetData]: state =>
            update(state, { $set: initialDatasetState }),
        [completeMarkForReview]: (
            state,
            { id, user, schema, model, project }
        ) => {
            if (model !== models.OBSERVATION) {
                // TODO: Implement station commenting
                throw new Error('Station commenting is not yet implemented');
            }
            const changeSet = {};
            const tableDataFilterSetsToUpdate = [];
            const observations = findObservation(state, id);
            Object.entries(observations).forEach(
                ([filterSetName, { stationIdx, observationIdx }]) => {
                    changeSet.filterSets = changeSet.filterSets || {};
                    if (stationIdx >= 0) {
                        changeSet.filterSets[filterSetName] = {
                            results: {
                                [stationIdx]: {
                                    observations: {
                                        [observationIdx]: {
                                            trusted: { $set: false },
                                        },
                                    },
                                },
                            },
                        };
                    }

                    // We will only attempt to update the table data section
                    // for a given filter set if it has already been populated
                    if (state.tableData[filterSetName]) {
                        tableDataFilterSetsToUpdate.push(filterSetName);
                    }
                }
            );

            return updateTableData(
                state,
                changeSet,
                tableDataFilterSetsToUpdate,
                schema,
                user,
                project
            );
        },
        [completeSaveVisualization]: (state, { id }) =>
            // Copy over the WORKING filterSets to the new visualization
            // locally so they don't have to be refetched immediately.
            copyFilterSets(state, 'WORKING', id, { deleteSource: true }),
    },
    initialDatasetState
);

export default filterSetReducer;
