import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { string } from 'prop-types';
import apiRequest from '../util/apiRequest';
import moment from 'moment';
import update from 'immutability-helper';
import { Button, Input, List, message, Typography } from 'antd';

import {
    commentDateTimeFormat,
    makeObservationUrl,
    makeCommentUrl,
    makeStationUrl,
    models,
} from '../util/constants';

import { model as modelPropType } from '../util/propTypes';

const { TextArea } = Input;
const { Text } = Typography;

const makeCommentTitle = ({ date, user }) => {
    return (
        <>
            <Text>
                {moment(date)
                    .local()
                    .format(commentDateTimeFormat)}
            </Text>
            <Text>{' - '}</Text>
            <Text>{user}</Text>
        </>
    );
};

const postComment = async (comment, url) => {
    const form = new FormData();
    form.set('text', comment);
    // Adding an additional comment should not mark an item for review. We use
    // the legacy term "flag" here because that is what the API expects
    form.set('flag', 'f');

    const options = {
        method: 'POST',
        headers: {
            'content-type': `multipart/form-data`,
        },
        data: form,
        url,
    };
    const response = await apiRequest(options);
    const payload = response.data;

    if (payload.status !== 200) {
        message.error(
            `Oops! There was an error submitting your comment: ${payload.error}`
        );
    }
    return payload;
};

function ObservationFormComments({ id, model }) {
    const makeModelUrl =
        model === models.OBSERVATION ? makeObservationUrl : makeStationUrl;
    const commentsUrl = makeCommentUrl(makeModelUrl(id));

    const [newComment, setNewComment] = useState('');

    const [comments, setComments] = useState([]);

    const fetchedComments = useAsync(async () => {
        try {
            const response = await apiRequest.get(commentsUrl);
            const payload = response.data;
            if (payload.status === 200 && payload.result) {
                return payload.result;
            } else {
                throw response.error;
            }
        } catch (e) {
            return e;
        }
    }, [id]);

    const fetchedAndAddedComments =
        !fetchedComments.loading && fetchedComments.value
            ? fetchedComments.value.concat(comments)
            : comments;

    const handleAddComment = () => {
        postComment(newComment, commentsUrl).then(payload => {
            if (payload.status === 200) {
                setComments(update(comments, { $push: [payload.result] }));
                setNewComment('');
            }
        });
    };

    return (
        <>
            <List
                loading={fetchedComments.loading}
                dataSource={fetchedAndAddedComments}
                renderItem={item => (
                    <List.Item>
                        <List.Item.Meta
                            title={makeCommentTitle(item)}
                            description={item.text}
                        />
                    </List.Item>
                )}
            />
            <div>
                <TextArea
                    className='observation__newcomment'
                    rows={4}
                    onChange={e => setNewComment(e.target.value)}
                    value={newComment}
                />
                <Button
                    disabled={fetchedComments.loading || !newComment}
                    type='primary'
                    onClick={handleAddComment}
                >
                    Add comment
                </Button>
            </div>
        </>
    );
}

ObservationFormComments.propTypes = {
    id: string.isRequired,
    model: modelPropType.isRequired,
};

export default ObservationFormComments;
