import React from 'react';
import { useSelector } from 'react-redux';

import { userIsApprovedToAdd } from '../util/projectHelper';

import FourOhThree from './403';
import FormCard from './FormCard';
import ObservationForm from './ObservationForm';

const ObservationFormWrapper = () => {
    const project = useSelector(state => state.project);
    const observationEditLocation = useSelector(
        state => state.ui.observationEditLocation
    );
    const user = useSelector(state => state.auth.user);

    if (!userIsApprovedToAdd(user, project)) {
        return (
            <FourOhThree
                participationRequiresApproval={!project.data.autoApprove}
            />
        );
    }

    return (
        <FormCard
            title='Observation'
            className='observation'
            backTo={({ history }) => {
                // If any component set a location
                // before navigating to the
                // observation form then `goBack`
                // will return to that location and
                // correctly pop it off the history
                // stack.
                if (observationEditLocation?.pathname) {
                    history.goBack();
                } else {
                    history.push('/observations');
                }
            }}
        >
            <ObservationForm />
        </FormCard>
    );
};

export default ObservationFormWrapper;
