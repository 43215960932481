import apiRequest from '../util/apiRequest';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { Form, Button, Alert, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleLogoutUser, getUser } from '../actions/auth';
import {
    authLoginUrl,
    authLogoutUrl,
    loginFormSchema,
} from '../util/constants';
import { showAuthMessages } from '../util/authHelper';

import SchemaFormFields from './SchemaFormFields';

function AuthLoginForm() {
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const successRedirectPath = useSelector(state => state.auth.redirectPath);
    const dispatch = useDispatch();
    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState(null);

    const [logoutRequestState, logoutRequest] = useAsyncFn(async () => {
        const response = await apiRequest.post(authLogoutUrl);
        const payload = response.data;

        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200) {
            dispatch(handleLogoutUser());
        }
        return payload;
    }, []);

    // Show success or error messages if any from query params
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    // Show the message only once, else it shows multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => showAuthMessages(queryParams), []);

    const [loginRequestState, loginRequest] = useAsyncFn(async values => {
        const fieldNames = loginFormSchema.map(field => field.name);

        const formData = new FormData();
        fieldNames.forEach(name => formData.set(name, values[name]));

        const response = await apiRequest.post(authLoginUrl, formData);
        const payload = response.data;

        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200 && payload.result) {
            // The result for a successful login is a sessionid and not the user
            // detail, which must be fetched separately.
            dispatch(getUser());

            // Redirect to next query param if available, or redirect path set
            // in redux, or home page, in that order.
            if (queryParams?.get('next')) {
                // Use window.location.href for redirect because we don't want
                // React to handle this route
                window.location.href =
                    window.location.origin + queryParams.get('next');
            }

            history.push(successRedirectPath || '/');
        } else {
            setErrorMessage(payload.error);
        }
        return payload;
    }, []);

    const onFinish = values => {
        setErrorMessage(null);
        loginRequest(values);
    };

    const forgotPassword = (
        <>
            <em>Forgot your password? </em>
            <Link to='reset'>Password reset</Link>
        </>
    );

    const loginForm = (
        <>
            {errorMessage && (
                <Alert
                    message={errorMessage}
                    type='error'
                    showIcon
                    closable
                    style={{ margin: '10px 0' }}
                />
            )}
            <Form
                name='login'
                onFinish={onFinish}
                layout='vertical'
                className='auth-form'
            >
                <SchemaFormFields fields={loginFormSchema} />
                <Form.Item className='auth-form__buttongroup'>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={loginRequestState.loading}
                    >
                        Sign in
                    </Button>
                    <Link to='register' className='ant-btn'>
                        Create an account
                    </Link>
                </Form.Item>
                <Divider />
                <Alert
                    message={forgotPassword}
                    showIcon
                    icon={<FontAwesomeIcon icon={['far', 'lock']} />}
                    style={{ margin: '10px 0' }}
                />
            </Form>
        </>
    );

    const logoutButton = (
        <Button
            type='primary'
            htmlType='submit'
            className='auth-form-button'
            loading={logoutRequestState.loading}
            onClick={() => logoutRequest()}
        >
            Log out
        </Button>
    );

    return <>{isLoggedIn ? logoutButton : loginForm}</>;
}

export default AuthLoginForm;
