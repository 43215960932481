import React from 'react';

import { formatUnit } from '../util/units';

export default function LayerSummary({ layer, response }) {
    return (
        <div className='map-layersummary'>
            <p>{layer.label}</p>
            {layer.pointQuerySettings &&
                response.data.results.map((data, i) => (
                    <dl key={`${layer.id}_${i}`}>
                        {layer.pointQuerySettings?.fields?.map((field, j) => (
                            <div key={`${field.label}_${j}`}>
                                <dt>{field.label}</dt>
                                <dd>
                                    {data.attributes[field.name]}
                                    {field.units
                                        ? ` ${formatUnit(field.units)}`
                                        : null}
                                </dd>
                            </div>
                        ))}
                    </dl>
                ))}
        </div>
    );
}
