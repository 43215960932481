import React from 'react';
import { string, func } from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Upload, message } from 'antd';
import update from 'immutability-helper';

import { mediaType } from '../util/propTypes';
import { antdUploadStatus, maxMediaFileSizeInBytes } from '../util/constants';
import { normalizeFileObject } from '../util/observationFormHelper';

const { TextArea } = Input;

const MediaUploadListItem = ({
    accept,
    uploadUrl,
    type,
    file,
    onChange,
    customRequest,
    onTooLarge,
}) => {
    const metadataFields = file && (
        <>
            <Form.Item>
                <Form.Item name='label'>
                    <label>
                        Label
                        <Input
                            onChange={e =>
                                onChange(
                                    update(file, {
                                        label: {
                                            $set: e.target.value,
                                        },
                                    })
                                )
                            }
                            value={file?.label}
                        />
                    </label>
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Form.Item name='description'>
                    <label>
                        Description
                        <TextArea
                            autoSize={{ maxRows: 10 }}
                            onChange={e =>
                                onChange(
                                    update(file, {
                                        description: {
                                            $set: e.target.value,
                                        },
                                    })
                                )
                            }
                            value={file?.description}
                        />
                    </label>
                </Form.Item>
            </Form.Item>
        </>
    );

    const listType = type === 'photo' ? 'picture' : null;

    return (
        <>
            <Form.Item>
                <Upload
                    listType={listType}
                    accept={accept}
                    fileList={file ? [file] : null}
                    data={file => ({
                        [type]: file,
                        label: file.name,
                        description: '',
                    })}
                    action={uploadUrl}
                    showUploadList={{
                        showRemoveIcon: true,
                    }}
                    beforeUpload={file =>
                        !(file.size > maxMediaFileSizeInBytes)
                    }
                    onChange={({ file }) => {
                        const fileObject = normalizeFileObject(file);
                        if (file.size > maxMediaFileSizeInBytes) {
                            onTooLarge(fileObject);
                        } else if (file.status === antdUploadStatus.removed) {
                            onChange(null);
                        } else if (file.status === antdUploadStatus.error) {
                            onChange(message.error('Upload failed'));
                        } else {
                            // covers both cases "uploading" and "done"
                            onChange(
                                update(file, {
                                    label: { $set: file.name },
                                })
                            );
                        }
                    }}
                    customRequest={customRequest}
                >
                    {file == null && (
                        <Button>
                            <UploadOutlined />
                            Choose file
                        </Button>
                    )}
                </Upload>
            </Form.Item>
            {metadataFields}
        </>
    );
};

MediaUploadListItem.propTypes = {
    accept: string.isRequired,
    uploadUrl: string,
    type: mediaType.isRequired,
    onChange: func.isRequired,
    customRequest: func,
    onTooLarge: func,
};

MediaUploadListItem.defaultProps = {
    onTooLarge: x => x,
};

export default MediaUploadListItem;
