import { isNil } from 'lodash';

export const userCanAdminProject = (user, project) =>
    user && project?.authorities?.includes(user.userId);

export const userCanBulkUpload = (user, project) =>
    user && project?.data?.uploadUsers?.includes(user?.userId);

// If download is not restricted for the project, check that user is logged in.
// If download is restricted for the project, check that user is admin or LOC
export const userCanDownload = ({ user, project, isOffline }) => {
    return (
        ((user && !project?.restrictDownload) ||
            userCanAdminProject(user, project) ||
            userIsLoc(user, project)) &&
        !isOffline
    );
};

// Returns true if the user is a local coordinator. If `locFieldValue` is
// specified then only returns true if the user is an LOC for that specific
// value.
export const userIsLoc = (user, project, locFieldValue) => {
    if (!user || !project) {
        return false;
    }

    const managedLocValues =
        user.projects_coordinated?.[project.name]?.loc_field_values || [];

    if (!isNil(locFieldValue)) {
        if (managedLocValues.includes(locFieldValue)) {
            return true;
        }
    } else {
        if (managedLocValues.length > 0) {
            return true;
        }
    }

    return false;
};

export const userIsProjectManager = (user, project, locFieldValue) =>
    userCanAdminProject(user, project) ||
    userIsLoc(user, project, locFieldValue);

export const userIsApproved = (user, project) =>
    project.data?.isPublic ||
    user?.project_relationships[project.name]?.some(pr => pr.approved);

export const userIsApprovedToAdd = (user, project) =>
    user?.project_relationships[project.name]?.some(pr => pr.approved);

export const userApprovalPending = (user, project) => {
    return (
        !project.data?.isPublic &&
        // If the user has a relationship established with a private project
        // but is not approved then it is pending
        user?.project_relationships[project.name] &&
        !userIsApproved(user, project)
    );
};
