import { combineReducers } from 'redux';

import AuthReducer from './auth';
import ProjectReducer from './project';
import SchemaReducer from './schema';
import filterSetReducer from './filterSet';
import StationsReducer from './stations';
import ObservationsReducer from './observations';
import OrganizationsReducer from './organizations';
import UIReducer from './ui';
import VisualizationReducer from './visualization';
import VisualizationWorkspaceReducer from './visualizationWorkspace';
import GeometrySourcesReducer from './geometrySources';
import DownloadsReducer from './downloads';

export default combineReducers({
    auth: AuthReducer,
    project: ProjectReducer,
    schema: SchemaReducer,
    data: filterSetReducer,
    stations: StationsReducer,
    observations: ObservationsReducer,
    organizations: OrganizationsReducer,
    ui: UIReducer,
    visualization: VisualizationReducer,
    visualizationWorkspace: VisualizationWorkspaceReducer,
    geometrySources: GeometrySourcesReducer,
    downloads: DownloadsReducer,
});
