import moment from 'moment';

export function objectToFormData(obj) {
    const formData = new FormData();
    for (const [key, value] of Object.entries(obj)) {
        if (value !== undefined) {
            if (moment.isMoment(value)) {
                formData.set(key, value.toISOString());
            } else {
                formData.set(key, value);
            }
        }
    }
    return formData;
}
