import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import isInteger from 'lodash/isInteger';

// Inspired by https://stackoverflow.com/a/34425083/6995854
export const addScript = ({ id, src }) => {
    const script = document.createElement('script');

    script.id = id;
    script.src = src;

    document.body.appendChild(script);
};

export const getProjectName = () =>
    window.location.host.split('.')[0].toLowerCase();

export const createErrorListFromResponseObject = data =>
    flatten(
        Object.entries(data).map(([field, errors]) => {
            if (isArray(errors)) {
                return errors.map(err => `${field}: ${err}`);
            }

            return [];
        })
    );

export function dispatchApiCompleteOrFail(
    dispatch,
    completeAction,
    failAction,
    data,
    onComplete,
    onFail
) {
    if (!data || !data.status || parseInt(data.status) >= 400) {
        dispatch(failAction(data));
        if (isFunction(onFail)) {
            onFail(data);
        }
    } else {
        dispatch(completeAction(data.result));
        if (isFunction(onComplete)) {
            onComplete(data);
        }
    }
}

export function logErrorAndDispatchFailure(
    error,
    defaultMessage,
    failureAction
) {
    return dispatch => {
        const response = get(error, 'response', {
            data: null,
            status: null,
        });

        if (!response.status || response.status >= 500) {
            window.console.warn(error);
            return dispatch(failureAction([defaultMessage]));
        }

        if (response.status === 404) {
            window.console.warn(error);
            return dispatch(failureAction(['Not found']));
        }

        const errorMessages = (() => {
            if (!response || !response.data) {
                return [defaultMessage];
            }

            if (isArray(response.data)) {
                return response.data;
            }

            if (response.data.detail) {
                return [response.data.detail];
            }

            if (isObject(response.data)) {
                return createErrorListFromResponseObject(response.data);
            }

            return [defaultMessage];
        })();

        return dispatch(failureAction(errorMessages));
    };
}

export const capitalize = str =>
    `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export const isArrayIndex = i => isInteger(i) && i >= 0;

export const shouldSupportOffline =
    window?.ENVIRONMENT?.REACT_APP_OFFLINE_SUPPORT === 'true';

export const isServiceWorkerActivated = () =>
    navigator.serviceWorker?.controller?.state === 'activated';
