import React, { useState } from 'react';
import { array, bool, func } from 'prop-types';
import update from 'immutability-helper';
import { message } from 'antd';
import filesize from 'filesize';
import { identity } from 'lodash';

import {
    acceptedImageFormats,
    antdUploadStatus,
    mediaTypes,
    photoUrl,
    acceptedDocumentFormats,
    acceptedAudioFormats,
    acceptedVideoFormats,
    maxMediaFileSizeInBytes,
} from '../util/constants';
import { mediaType } from '../util/propTypes';
import { normalizeFileObject } from '../util/observationFormHelper';

import MediaUploadListItem from './MediaUploadListItem';

const acceptTypes = Object.freeze({
    [mediaTypes.photo]: acceptedImageFormats,
    [mediaTypes.document]: acceptedDocumentFormats,
    [mediaTypes.audio]: acceptedAudioFormats,
    [mediaTypes.video]: acceptedVideoFormats,
});

const uploadUrls = Object.freeze({
    [mediaTypes.photo]: photoUrl,
});

const MediaUploadList = ({
    type,
    items,
    onChange,
    newMediaAllowed,
    customRequest,
    onStatusChange,
}) => {
    const [newFile, setNewFile] = useState(null);

    return (
        <>
            {items.map((item, index) => (
                <MediaUploadListItem
                    key={item.uid}
                    type={type}
                    accept={acceptTypes[type]}
                    uploadUrl={uploadUrls[type]}
                    file={item}
                    onChange={file => {
                        const spliceArguments = file
                            ? [[index, 1, file]]
                            : [[index, 1]];
                        onChange(update(items, { $splice: spliceArguments }));
                    }}
                    customRequest={customRequest}
                />
            ))}
            {newMediaAllowed && (
                <MediaUploadListItem
                    type={type}
                    accept={acceptTypes[type]}
                    uploadUrl={uploadUrls[type]}
                    file={newFile}
                    customRequest={customRequest}
                    onTooLarge={() =>
                        message.error(
                            `File is too large (max size is ${filesize(
                                maxMediaFileSizeInBytes
                            )})`
                        )
                    }
                    onChange={file => {
                        onStatusChange(file?.status);
                        if (file.status === antdUploadStatus.done) {
                            setNewFile(null);
                            if (file.response?.error) {
                                message.error(`Failed to upload ${type}`);
                            } else {
                                onChange(
                                    update(items, {
                                        $push: [normalizeFileObject(file)],
                                    })
                                );
                            }
                        } else if (file.status === antdUploadStatus.error) {
                            setNewFile(null);
                            message.error(`Failed to upload ${type}`);
                        } else {
                            setNewFile(file);
                        }
                    }}
                />
            )}
        </>
    );
};

MediaUploadList.propTypes = {
    type: mediaType.isRequired,
    items: array,
    onChange: func.isRequired,
    newMediaAllowed: bool,
    customRequest: func,
    onStatusChange: func,
};

MediaUploadList.defaultProps = {
    items: [],
    newMediaAllowed: true,
    // The Upload component will default to posting to the uploadUrl if the customRequest is null. Noop triggers calling the customRequest, instead.
    customRequest: null,
    onStatusChange: identity,
};

export default MediaUploadList;
