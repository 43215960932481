import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    startDeleteObservation,
    completeDeleteObservation,
    failDeleteObservation,
    completeDeleteStationlessObservation,
} from '../actions/observations';

const initialState = Object.freeze({
    delete: {
        observation: null,
        loading: false,
        succeeded: false,
        error: null,
    },
});

const ObservationsReducer = createReducer(
    {
        [startDeleteObservation]: (state, payload) =>
            update(state, {
                delete: {
                    observation: { $set: payload },
                    loading: { $set: true },
                    succeeded: { $set: initialState.succeeded },
                    error: { $set: initialState.error },
                },
            }),
        [completeDeleteObservation]: state =>
            update(state, {
                delete: {
                    loading: { $set: false },
                    succeeded: { $set: true },
                },
            }),
        [completeDeleteStationlessObservation]: state =>
            update(state, {
                delete: {
                    loading: { $set: false },
                    succeeded: { $set: true },
                },
            }),
        [failDeleteObservation]: (state, payload) =>
            update(state, {
                delete: {
                    loading: { $set: false },
                    succeeded: { $set: false },
                    error: { $set: payload },
                },
            }),
    },
    initialState
);

export default ObservationsReducer;
