import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Typography, Tabs, Divider } from 'antd';
import { mobileWidthQuery } from '../util/constants';
const { Title, Text } = Typography;

const HomeAbout = ({ label, description, howToParticipate, whoIsInvolved }) => {
    const isDesktop = useMediaQuery(mobileWidthQuery);

    const descriptionElement = (
        // The value of `description` is only set by super users via the Django
        // admin so it is safe to render the markup here.
        <span dangerouslySetInnerHTML={{ __html: description }} />
    );

    const howToParticipateElement = (
        // The value of `howToParticipate` is only set by super users via the Django
        // admin so it is safe to render the markup here.
        <span dangerouslySetInnerHTML={{ __html: howToParticipate }} />
    );

    const whoIsInvolvedElement = (
        // The value of `whoIsInvolved` is only set by super users via the Django
        // admin so it is safe to render the markup here.
        <span dangerouslySetInnerHTML={{ __html: whoIsInvolved }} />
    );

    return (
        <>
            <Row justify='start'>
                <Col>
                    <Title type='primary' className='ant-typography-light'>
                        About {label}
                    </Title>
                    <Divider
                        className='section-divider'
                        style={{ marginLeft: 0 }}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        className='home-about__tabs'
                        tabPosition={isDesktop ? 'left' : 'top'}
                        tye='line'
                        items={[
                            {
                                forceRender: true,
                                label: (
                                    <>
                                        <Title level={3}>
                                            {isDesktop && (
                                                <FontAwesomeIcon
                                                    className='tab-icon'
                                                    icon={[
                                                        'far',
                                                        'long-arrow-right',
                                                    ]}
                                                />
                                            )}{' '}
                                            Project overview
                                        </Title>
                                        {isDesktop && (
                                            <Text>
                                                A general description of the
                                                project
                                            </Text>
                                        )}
                                    </>
                                ),
                                key: '1',
                                children: descriptionElement,
                            },
                            howToParticipate && {
                                forceRender: true,
                                label: (
                                    <>
                                        <Title level={3}>
                                            {isDesktop && (
                                                <FontAwesomeIcon
                                                    className='tab-icon'
                                                    icon={[
                                                        'far',
                                                        'long-arrow-right',
                                                    ]}
                                                />
                                            )}{' '}
                                            How to participate
                                        </Title>
                                        {isDesktop && (
                                            <Text>
                                                Steps you can take to get
                                                involved in the project
                                            </Text>
                                        )}
                                    </>
                                ),
                                key: '2',
                                children: howToParticipateElement,
                            },
                            whoIsInvolved && {
                                forceRender: true,
                                label: (
                                    <>
                                        <Title level={3}>
                                            {isDesktop && (
                                                <FontAwesomeIcon
                                                    className='tab-icon'
                                                    icon={[
                                                        'far',
                                                        'long-arrow-right',
                                                    ]}
                                                />
                                            )}{' '}
                                            Who is involved
                                        </Title>
                                        {isDesktop && (
                                            <Text>
                                                The people and organizations
                                                behind this project
                                            </Text>
                                        )}
                                    </>
                                ),
                                key: '3',
                                children: whoIsInvolvedElement,
                            },
                        ]}
                    />
                </Col>
            </Row>
        </>
    );
};

export default HomeAbout;
