import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { cloneDeep, find, findIndex, isEmpty, isEqual, isNil } from 'lodash';

import { createDefaulVisualizationQuery } from '../util/queryHelper';

import {
    initNewVisualization,
    setVisualizationTitle,
    setVisualizationDescription,
    toggleVisualizationIsPublic,
    updateVisualizationConfiguration,
    updateVisualizationFilterSetProp,
    updateVisualizationWidgetProp,
    updateVisualizationWidgetProps,
    deleteVisualizationFilterSet,
    deleteVisualizationWidget,
    completeFetchVisualization,
    completeSaveVisualization,
    deleteVisualizationFilterSetFilter,
    makePrimaryWidget,
    replaceVisualizationWorkspaceContent,
    updateVisualizationFilterSetDataSource,
} from '../actions/visualization';

export const initialState = {
    data: {
        id: null,
        title: 'New visualization',
        description: 'A new observation data visualization',
        is_public: true,
        configuration: {},
    },
    modified: false,
};

const generateInitialState = (project, schema) => {
    const dataSource = find(
        project?.dataSources,
        d => d.schema === schema?.name
    );
    if (!dataSource) {
        throw new Error(
            `Project does not have a data source with schema ${schema?.name}`
        );
    }

    const state = cloneDeep(initialState);
    state.data.configuration = {
        filterSets: [
            {
                id: 1,
                dataSource: dataSource?.id,
                label: 'All observations',
                ...createDefaulVisualizationQuery(schema),
            },
        ],
        widgets: [
            {
                id: 1,
                label: 'Observation map',
                description: 'A map of observations',
                type: 'map',
                filterSets: [1],
            },
        ],
    };
    return state;
};

const updateWidgetProp = (state, { id, key, value, modified }) => {
    const index = findIndex(state.data.configuration.widgets, {
        id,
    });
    // If we are not changing the value, skip the update and skip
    // setting the `modified` flag.
    if (
        !state.data.configuration.widgets[index] ||
        isEqual(value, state.data.configuration.widgets[index][key])
    ) {
        return state;
    }
    return update(state, {
        data: {
            configuration: {
                widgets: {
                    [index]: { [key]: { $set: value } },
                },
            },
        },
        modified: { $set: modified },
    });
};

const VisualizationWorkspaceReducer = createReducer(
    {
        [initNewVisualization]: (state, { project, schema }) => {
            if (state.data.id || isEmpty(state.data.configuration)) {
                // We only want to clear the working state if it is holding a
                // previously saved visualization or if we have not yet set the
                // default configuration
                return update(state, {
                    $set: generateInitialState(project, schema),
                });
            }
            return state;
        },
        [setVisualizationTitle]: (state, payload) =>
            update(state, {
                data: { title: { $set: payload } },
                modified: { $set: true },
            }),
        [setVisualizationDescription]: (state, payload) =>
            update(state, {
                data: { description: { $set: payload } },
                modified: { $set: true },
            }),
        [toggleVisualizationIsPublic]: state =>
            update(state, {
                data: { $toggle: ['is_public'] },
                modified: { $set: true },
            }),
        [completeFetchVisualization]: (state, payload) =>
            update(state, {
                data: { $set: payload },
                modified: { $set: false },
            }),
        [completeSaveVisualization]: (state, payload) =>
            update(state, {
                data: { $set: payload },
                modified: { $set: false },
            }),
        [updateVisualizationConfiguration]: (state, payload) =>
            update(state, {
                data: { configuration: { $set: payload } },
                modified: { $set: true },
            }),
        [updateVisualizationFilterSetProp]: (state, { id, key, value }) => {
            const index = findIndex(state.data.configuration.filterSets, {
                id,
            });
            const updateDefinition = isNil(value)
                ? { $unset: [key] }
                : { [key]: { $set: value } };
            return update(state, {
                data: {
                    configuration: {
                        filterSets: {
                            [index]: updateDefinition,
                        },
                    },
                },
                modified: { $set: true },
            });
        },
        [updateVisualizationFilterSetDataSource]: (
            state,
            { id, dataSource, query: { fields } }
        ) => {
            const index = findIndex(state.data.configuration.filterSets, {
                id,
            });
            return update(state, {
                data: {
                    configuration: {
                        filterSets: {
                            [index]: {
                                dataSource: { $set: dataSource },
                                fields: { $set: fields },
                                $unset: ['filters'],
                            },
                        },
                    },
                },
                modified: { $set: true },
            });
        },
        [deleteVisualizationFilterSetFilter]: (state, { id, index }) => {
            const filterSetIndex = findIndex(
                state.data.configuration.filterSets,
                {
                    id,
                }
            );
            const filterSet =
                state.data.configuration.filterSets[filterSetIndex];
            // If there are no filters left we want to remove the `filters` key
            // from the filterSet object entirely
            const editSpec =
                filterSet.filters.length - 1 < 2
                    ? { $unset: ['filters'] }
                    : {
                          filters: { $splice: [[index, 1]] },
                      };
            return update(state, {
                data: {
                    configuration: {
                        filterSets: {
                            [filterSetIndex]: editSpec,
                        },
                    },
                },
                modified: { $set: true },
            });
        },
        [updateVisualizationWidgetProp]: (state, options) => {
            return updateWidgetProp(state, options);
        },
        [updateVisualizationWidgetProps]: (state, optionsList) => {
            return optionsList.reduce(updateWidgetProp, state);
        },
        [deleteVisualizationFilterSet]: (state, id) => {
            const index = findIndex(state.data.configuration.filterSets, {
                id,
            });
            return update(state, {
                data: {
                    configuration: { filterSets: { $splice: [[index, 1]] } },
                },
                modified: { $set: true },
            });
        },
        [deleteVisualizationWidget]: (state, id) => {
            const index = findIndex(state.data.configuration.widgets, {
                id,
            });
            return update(state, {
                data: {
                    configuration: { widgets: { $splice: [[index, 1]] } },
                },
                modified: { $set: true },
            });
        },
        [makePrimaryWidget]: (state, id) => {
            const index = findIndex(state.data.configuration.widgets, {
                id,
            });
            const widget = state.data.configuration.widgets[index];
            const stateWithoutWidget = update(state, {
                data: {
                    configuration: { widgets: { $splice: [[index, 1]] } },
                },
            });
            return update(stateWithoutWidget, {
                data: {
                    configuration: { widgets: { $push: [widget] } },
                },
                modified: { $set: true },
            });
        },
        [replaceVisualizationWorkspaceContent]: (state, payload) => {
            return update(state, {
                data: { $set: payload },
                modified: { $set: true },
            });
        },
    },
    initialState
);

export default VisualizationWorkspaceReducer;
