import React, { useEffect, useRef, useState } from 'react';
import ReactMapGL, { AttributionControl } from 'react-map-gl';
import { useMeasure } from 'react-use';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { identity, find, pick } from 'lodash';

import { defaultExtentToViewport, makeMapStyle } from '../util/mapHelper';
import { closestZoom } from '../util/constants';
import LocationToolbar from './LocationToolbar';

const MAX_ZOOM_LEVEL = 15;

const makeStationFeatures = stations =>
    stations.map(station => {
        const { x, y } = station.geometry;
        return {
            type: 'Feature',
            id: station.stationId,
            properties: {
                name: station.stationName,
                ownerId: station.ownerId,
                registeredUserIds: station.registeredUserIds,
            },
            geometry: {
                type: 'Point',
                coordinates: [x, y],
            },
        };
    });

const makeStationFeatureCollection = stations => {
    return {
        type: 'FeatureCollection',
        features: stations ? makeStationFeatures(stations) : [],
    };
};

const makeSelectedStationFeatureCollection = selectedStation => {
    if (!selectedStation) {
        return makeStationFeatureCollection();
    }
    return {
        type: 'FeatureCollection',
        features: [
            selectedStation.toJSON ? selectedStation.toJSON() : selectedStation,
        ],
    };
};

const ObservationFormStationMap = ({
    stations,
    selectedId,
    onClick = identity,
    onCenterChange = identity,
    latitude,
    longitude,
    shouldShowCenterPoint,
    project,
    basemap,
    shouldZoomToStation,
    setShouldZoomToStation,
    isGeoLocationActive,
    desiredLat,
    desiredLng,
}) => {
    const defaultViewport = defaultExtentToViewport(project.data.defaultExtent);

    const [viewport, setViewport] = useState(defaultViewport);
    const [lastDesiredCenter, setLastDesiredCenter] = useState({});

    // this could be two different useState hooks, but they are tied together since
    // a geolocation error coincides with a change in loading status
    const [geolocationStatus, setGeolocationStatus] = useState({
        loading: false,
        error: null,
    });

    const [stationState, setStationsState] = useState({
        stations: null,
        featureCollection: makeStationFeatureCollection(),
    });

    const selectedFeature = find(
        stationState.featureCollection?.features,
        f => f.id === selectedId
    );
    const selectedFeatureCollection = makeSelectedStationFeatureCollection(
        selectedFeature
    );

    if (stations !== stationState.stations) {
        setStationsState(
            update(stationState, {
                featureCollection: {
                    $set: makeStationFeatureCollection(stations),
                },
                stations: { $set: stations },
            })
        );
    }

    if (
        (latitude && latitude !== viewport?.latitude) ||
        (longitude && longitude !== viewport?.longitude)
    ) {
        setViewport(
            update(viewport, {
                latitude: { $set: latitude },
                longitude: { $set: longitude },
            })
        );
    }

    if (
        (desiredLat && desiredLat !== lastDesiredCenter.latitude) ||
        (desiredLng && desiredLng !== lastDesiredCenter.longitude)
    ) {
        const newLat = desiredLat ? desiredLat : viewport?.latitude;
        const newLng = desiredLng ? desiredLng : viewport?.longitude;
        setLastDesiredCenter({
            latitude: newLat,
            longitude: newLng,
        });
        setViewport(
            update(viewport, {
                latitude: { $set: newLat },
                longitude: { $set: newLng },
            })
        );
    }

    const mapStyle = makeMapStyle({
        basemap,
        sources: {
            'stations-source': {
                type: 'geojson',
                data: stationState.featureCollection,
            },
            'selected-stations-source': {
                type: 'geojson',
                data: selectedFeatureCollection,
            },
        },
        layers: [
            {
                id: 'stations',
                type: 'circle',
                source: 'stations-source',
                paint: {
                    'circle-color': '#f00',
                    'circle-radius': 12,
                },
            },
            {
                id: 'selected-stations',
                type: 'circle',
                source: 'selected-stations-source',
                paint: {
                    'circle-color': '#f0f',
                    'circle-radius': 14,
                },
            },
        ],
    });

    const onMapClick = event => {
        const { features } = event;
        const feature =
            features && features.find(f => f.layer?.id === 'stations');
        onClick(feature);
    };

    const centerPointCanvasRef = useRef(null);
    const [
        mapContainerRef,
        { width: mapContainerWidth, height: mapContainerHeight },
    ] = useMeasure();

    useEffect(() => {
        if (shouldShowCenterPoint) {
            const canvas = centerPointCanvasRef.current;
            const x = mapContainerWidth / 2;
            const y = mapContainerHeight / 2;
            const ctx = canvas.getContext('2d');
            ctx.beginPath();
            ctx.arc(x, y, 12, 0, 2 * Math.PI);
            ctx.fill();
        }
    }, [
        centerPointCanvasRef,
        mapContainerWidth,
        mapContainerHeight,
        shouldShowCenterPoint,
    ]);

    // Centers map on station if selected from the dropdown in ObservationFormStations
    useEffect(() => {
        if (shouldZoomToStation && selectedFeature) {
            const [
                selectedLong,
                selectedLat,
            ] = selectedFeature.geometry.coordinates;

            setViewport(
                update(viewport, {
                    zoom: { $set: closestZoom },
                    latitude: { $set: selectedLat },
                    longitude: { $set: selectedLong },
                })
            );
            setShouldZoomToStation(false);
        }
    }, [
        shouldZoomToStation,
        setShouldZoomToStation,
        selectedFeature,
        viewport,
    ]);

    // If geolocating a station we want to make the map as
    // big as we can and still leave room to view the other
    // components of the modal without having to scroll
    const mapHeight =
        isGeoLocationActive === false ||
        isGeoLocationActive === undefined ||
        window.innerHeight <= 800
            ? '200px'
            : window.innerHeight >= 1100
            ? '500px'
            : `${window.innerHeight - 600}px`;

    return (
        <div className='station-map'>
            {isGeoLocationActive && (
                <LocationToolbar
                    viewport={viewport}
                    setViewport={setViewport}
                    geolocationStatus={geolocationStatus}
                    setGeolocationStatus={setGeolocationStatus}
                    onCenterChange={onCenterChange}
                />
            )}
            <div
                ref={mapContainerRef}
                style={{
                    width: '100%',
                    height: mapHeight,
                    position: 'relative',
                    border: '1px solid #d9d9d9',
                    borderRadius: '0 0 4px 4px',
                    overflow: 'hidden',
                }}
            >
                <ReactMapGL
                    width='100%'
                    height={mapHeight}
                    {...viewport}
                    maxZoom={MAX_ZOOM_LEVEL}
                    mapStyle={mapStyle}
                    onViewportChange={viewport => {
                        const { width, height, ...rest } = viewport;
                        setViewport(update(viewport, { $set: rest }));
                        onCenterChange(
                            pick(viewport, ['latitude', 'longitude'])
                        );
                    }}
                    onClick={onMapClick}
                    attributionControl={false}
                    dragPan={isGeoLocationActive}
                    scrollZoom={isGeoLocationActive}
                    doubleClickZoom={isGeoLocationActive}
                >
                    <AttributionControl
                        compact={true}
                        style={{
                            right: 0,
                            bottom: 0,
                        }}
                    />
                </ReactMapGL>
                <canvas
                    ref={centerPointCanvasRef}
                    width={mapContainerWidth || '400px'}
                    height={mapContainerHeight || '200px'}
                    style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                    }}
                ></canvas>
            </div>
        </div>
    );
};

ObservationFormStationMap.propTypes = {
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    shouldShowCenterPoint: PropTypes.bool,
};

export default ObservationFormStationMap;
