import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const FourOhFour = ({
    subTitle = 'Sorry, the page you visited does not exist.',
}) => (
    <Result
        status='404'
        title='404'
        subTitle={subTitle}
        extra={
            <Button type='primary'>
                <Link to='/'>Back Home</Link>
            </Button>
        }
    />
);

export default FourOhFour;
