export const CSV = 'csv';
export const XLSX = 'xlsx';

/*
Allow user to download antd design tabular data as a spreadsheet

Supported spreadsheet file formats:
    https://sheetjs.gitbooks.io/docs/#supported-output-formats
*/
export const downloadData = async (
    tableData,
    fieldNames,
    fileFormats,
    fileName
) => {
    const XLSX = await import('xlsx');
    const aoa = [fieldNames, ...tableData];
    const worksheet = XLSX.utils.aoa_to_sheet(aoa);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    fileFormats.forEach(fileFormat => {
        XLSX.writeFile(workbook, `${fileName}.${fileFormat}`, {
            bookType: fileFormat,
        });
    });
};

// In order to use the URL constructor, we need to make sure the URL is
// absolute. This prefix can be added temporarily then removed from the modified
// URL before returning it by using string.replace()
// NOTE: This MUST be lower case because the output of URL().toString() is
// forced to all lower case.
const ABSOLUTE_PREFIX = 'https://__make_absolute__';

const makeUrlAbsolute = url =>
    url.startsWith('/') ? new URL(ABSOLUTE_PREFIX + url) : new URL(url);

export const updateQueryParam = (url, param, value) => {
    const urlObject = new URL(makeUrlAbsolute(url));
    urlObject.searchParams.set(param, value);
    return urlObject.toString().replace(ABSOLUTE_PREFIX, '');
};

export const updatePageNumber = (url, pageNumber) => {
    const urlObject = new URL(makeUrlAbsolute(url));
    const query = JSON.parse(urlObject.searchParams.get('query'));
    query.page = pageNumber;
    return updateQueryParam(url, 'query', JSON.stringify(query));
};
