import React from 'react';
import PropTypes from 'prop-types';

import { Card, Typography } from 'antd';

import SafeHtml from './SafeHtml';

const { Paragraph, Title } = Typography;

const colors = [
    '#90d8b2',
    '#faaf90',
    '#b9e192',
    '#8babf1',
    '#f194b8',
    '#8dd2dd',
];

/**
 * Select a color based on the length of the title. This way
 * the color for a given card will be always be the same
 */
function colorBasedOnTitle(colors, title) {
    return colors[title.length % colors.length];
}

const ColoredCard = ({
    title,
    description,
    media,
    className,
    children,
    ...props
}) => {
    const color = colorBasedOnTitle(colors, title);
    return (
        <div
            className={`colored-card ${className || ''}`}
            style={{ backgroundColor: color }}
        >
            <Card
                {...props}
                className={media ? 'media-card' : ''}
                style={{ borderColor: color, backgroundImage: `url(${media})` }}
            >
                {title && title.length > 0 && (
                    <Title level={2} className='colored-card__title'>
                        {title}
                    </Title>
                )}
                {description && description.length > 0 && (
                    <Paragraph
                        ellipsis={{ rows: 4 }}
                        className='colored-card__description'
                    >
                        <SafeHtml html={description} />
                    </Paragraph>
                )}
                {children}
            </Card>
        </div>
    );
};

ColoredCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    media: PropTypes.string,
};

export default ColoredCard;
