import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { Switch, Space, Spin, Button, Dropdown } from 'antd';
import pluralize from 'pluralize';
import { LoadingOutlined } from '@ant-design/icons';

import ReviewModal from './ReviewModal';
import WidgetToolbar from './WidgetToolbar';
import ShowIfProjectAdmin from './ShowIfProjectAdmin';
import DownloadButton from './DownloadButton';

import {
    makeObservationUrl,
    makeCommentUrl,
    hardcodedMyOfflineFilterSetName,
} from '../util/constants';
import { setIsTableVisible } from '../actions/filterSet';
import { markObservationForReview } from '../actions/review';

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function ObservationTableHeader({
    filterSetName,
    filters,
    isTableVisible,
    dispatch,
    isDownloadAllowed,
    user,
    project,
    schema,
    loading,
    filterSetResults,
    isOffline,
}) {
    const resultCount = filterSetResults?.reduce(
        (acc, r) => acc + r.observations.length,
        0
    );
    const resultsText = filterSetResults
        ? `${pluralize('observation', resultCount, true)}`
        : null;

    const downloadButton =
        isTableVisible[filterSetName] &&
        isDownloadAllowed &&
        filterSetName !== hardcodedMyOfflineFilterSetName &&
        !isOffline ? (
            <DownloadButton
                filterSetName={filterSetName}
                filters={filters}
                dispatch={dispatch}
                isDownloadAllowed={isDownloadAllowed}
                schema={schema}
                elementName='observation'
                isTableVisible={isTableVisible[filterSetName]}
                user
                project
            />
        ) : null;

    const selectedObservations = useSelector(
        state => state.data.selectedObservations
    );
    const observationIds = selectedObservations[filterSetName] || [];

    const onBulkReview = comment => {
        observationIds.forEach(id => {
            const url = makeCommentUrl(makeObservationUrl(id));
            dispatch(
                markObservationForReview({ comment, url, id, user, schema })
            );
        });
    };

    const bulkReviewButton = filterSetName !==
        hardcodedMyOfflineFilterSetName &&
        observationIds.length > 0 && (
            <ShowIfProjectAdmin>
                <ReviewModal submitCallback={onBulkReview} />
            </ShowIfProjectAdmin>
        );

    return (
        <WidgetToolbar
            className='observation-header'
            left={
                <>
                    <div className='observation-header left desktop'>
                        {downloadButton}
                        {bulkReviewButton}
                    </div>
                    {isTableVisible[filterSetName] &&
                        (downloadButton || bulkReviewButton) && (
                            <div className='observation-header left mobile'>
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                key: 'download-data',
                                                label: downloadButton,
                                            },
                                            {
                                                key: 'bulk-review-data',
                                                label: bulkReviewButton,
                                            },
                                        ],
                                    }}
                                    trigger={['click']}
                                >
                                    <Button
                                        className='ant-dropdown-link'
                                        icon={
                                            <FontAwesomeIcon
                                                icon={['far', 'bars']}
                                            />
                                        }
                                        ghost
                                        type='primary'
                                    />
                                </Dropdown>
                            </div>
                        )}
                </>
            }
            center={
                <label>
                    <Space>
                        Table
                        <Switch
                            checked={!isTableVisible[filterSetName]}
                            onChange={() =>
                                dispatch(
                                    setIsTableVisible({
                                        filterSetName,
                                        isTableVisible: !isTableVisible[
                                            filterSetName
                                        ],
                                    })
                                )
                            }
                        ></Switch>
                        Map
                    </Space>
                </label>
            }
            right={
                <>
                    <div className='observation-header right desktop'>
                        {loading ? (
                            <Spin indicator={loadingIcon} />
                        ) : (
                            resultsText
                        )}
                    </div>
                </>
            }
        ></WidgetToolbar>
    );
}

export default ObservationTableHeader;
