import React from 'react';
import { StaticMap } from 'react-map-gl';
import apiRequest from '../util/apiRequest';
import { useAsync } from 'react-use';

import {
    makeMapStyle,
    makeStationMapStyle,
    makeStationFeatureCollection,
    defaultExtentToViewport,
} from '../util/mapHelper';
import { makeQueryUrl } from '../util/constants';
import { createObservationsQuery, recentOptions } from '../util/queryHelper';
import { schema, basemap } from '../util/propTypes';

const HomeObservationMap = ({ project, schema, basemap }) => {
    const stationRequest = useAsync(async () => {
        const queryUrl = makeQueryUrl(schema.name);
        const recentFilter = createObservationsQuery(schema, recentOptions);
        const response = await apiRequest.post(queryUrl, recentFilter);
        return response.data;
    }, [schema]);

    const stationData = makeStationFeatureCollection(
        stationRequest?.value?.result
    );
    const mapStyle = makeMapStyle(makeStationMapStyle(basemap, stationData));

    return (
        project?.data && (
            <div className='map-demo'>
                <StaticMap
                    viewState={defaultExtentToViewport(
                        project.data.defaultExtent
                    )}
                    mapStyle={mapStyle}
                    width={'100%'}
                    height={'400px'}
                />
            </div>
        )
    );
};

HomeObservationMap.propTypes = {
    schema: schema.isRequired,
    basemap: basemap.isRequired,
};

export default HomeObservationMap;
