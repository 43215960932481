import { cloneDeep, findIndex, isObject } from 'lodash';
import update from 'immutability-helper';

import { isArrayIndex } from '../util';
import { hardcodedMyOfflineFilterSetName, staleValue } from '../util/constants';

export const findStation = (state, station) =>
    Object.entries(state.filterSets).reduce(
        (acc, [filterSetKey, filterSet]) => {
            const stationIdx = findIndex(
                filterSet.results,
                s => s.stationId === station.stationId
            );

            if (stationIdx >= 0) {
                acc[filterSetKey] = stationIdx;
            }

            return acc;
        },
        {}
    );

// Search an array of stations and return the index of the first station that
// contains the specified `observationId`
export const findStationIndexByObservationId = (observationId, stationList) => {
    if (!stationList) {
        return -1;
    }
    return stationList.reduce((foundStationIndex, station, stationIndex) => {
        if (foundStationIndex >= 0) {
            return foundStationIndex;
        }
        const observationIndex = findIndex(
            station.observations,
            o => o.observationId === observationId
        );
        return observationIndex >= 0 ? stationIndex : foundStationIndex;
    }, -1);
};

export const offlineStatesAreMismatched = (observationId, filterSetName) => {
    const isOnlineObservation = !observationId?.includes('draft');
    if (
        filterSetName === hardcodedMyOfflineFilterSetName &&
        isOnlineObservation
    ) {
        return true;
    }
    if (
        filterSetName !== hardcodedMyOfflineFilterSetName &&
        !isOnlineObservation
    ) {
        return true;
    }
    return false;
};

// Search all filterSets in state for the stations that match that of an observation
// The `observation` argument can be a string observation ID or an object with
// `observationId` and `stationId` properties.
// Returns indices of the station and observation for later reference
// New observations with a found station will be appended to its observation list
export const findObservation = (state, observation) => {
    return Object.entries(state.filterSets).reduce(
        (acc, [filterSetKey, filterSet]) => {
            const observationId = isObject(observation)
                ? observation.observationId
                : observation;
            if (offlineStatesAreMismatched(observationId, filterSetKey)) {
                // Edits to offline observations should not update the other
                // filter sets and edits to online observations should not
                // update the offline filterset so we exit early
                return acc;
            }
            const stationIdx = isObject(observation)
                ? findIndex(
                      filterSet.results,
                      s => s.stationId === observation.stationId
                  )
                : findStationIndexByObservationId(
                      observation,
                      filterSet.results
                  );

            // The observation may have been moved to a different station, so we
            // need to look up the previous station ID.
            const prevStationIdx = isObject(observation)
                ? findStationIndexByObservationId(
                      observation.observationId,
                      filterSet.results
                  )
                : null;

            // If the station did not change we want this to be null to
            // avoid making any state changes
            const prevObservationIdx =
                isArrayIndex(prevStationIdx) && prevStationIdx !== stationIdx
                    ? findIndex(
                          filterSet.results[prevStationIdx].observations,
                          o => o.observationId === observationId
                      )
                    : null;

            if (isArrayIndex(stationIdx)) {
                let observationIdx = findIndex(
                    // Grab list index of existing observation
                    filterSet.results[stationIdx].observations,
                    o => o.observationId === observationId
                );
                if (!isArrayIndex(observationIdx)) {
                    // New observation at a fetched station will be appended
                    // to the end of the observations list
                    // Return the next index
                    observationIdx =
                        filterSet.results[stationIdx].observations.length;
                }

                // If the station did not change we want this to be null to
                // avoid making any state changes
                acc[filterSetKey] = {
                    stationIdx,
                    observationIdx,
                    prevStationIdx,
                    prevObservationIdx,
                };
            } else {
                acc[filterSetKey] = {
                    stationIdx: null,
                    observationIdx: null,
                    prevStationIdx,
                    prevObservationIdx,
                };
            }
            return acc;
        },
        {}
    );
};

export const updateObservationInState = (
    state,
    observation,
    observationsInFilterSets,
    updatedStationRegisteredUserIds
) => {
    const changeSet = {};
    const tableDataFilterSetsToUpdate = [];
    Object.entries(observationsInFilterSets).forEach(
        ([
            filterSetName,
            { stationIdx, observationIdx, prevStationIdx, prevObservationIdx },
        ]) => {
            changeSet.filterSets = changeSet.filterSets || {};
            if (isArrayIndex(stationIdx) && isArrayIndex(observationIdx)) {
                changeSet.filterSets[filterSetName] = {
                    results: {
                        [stationIdx]: {
                            observations: {
                                [observationIdx]: {
                                    $set: observation,
                                },
                            },
                            registeredUserIds: {
                                $set: updatedStationRegisteredUserIds,
                            },
                        },
                    },
                };
            }
            if (
                isArrayIndex(prevStationIdx) &&
                isArrayIndex(prevObservationIdx)
            ) {
                // Set up an empty `results` list without overwriting any values
                // previously added to `changeSet`
                changeSet.filterSets = changeSet.filterSets || {};
                changeSet.filterSets[filterSetName] =
                    changeSet.filterSets[filterSetName] || {};
                changeSet.filterSets[filterSetName].results =
                    changeSet.filterSets[filterSetName].results || [];

                changeSet.filterSets[filterSetName].results[prevStationIdx] = {
                    observations: {
                        $splice: [[prevObservationIdx, 1]],
                    },
                };
            }

            // We will only attempt to update the table data section
            // for a given filter set if it has already been populated
            if (state.tableData[filterSetName]) {
                tableDataFilterSetsToUpdate.push(filterSetName);
            }
        }
    );

    return [update(state, changeSet), tableDataFilterSetsToUpdate];
};

// Search `list` for a member object where the value of `key` is the same as
// `obj[key]`. If found, splice `obj` into a clone of `list`. If not found,
// unshift `obj` onto a clone of `list`
// This helper is structured as a higher-order function so that it can be used
// to generate a function that can be passed to immutability-helper $apply.
export const replaceInListOrUnshift = (obj, key) => list => {
    const newList = cloneDeep(list);
    const idx = findIndex(list, x => x[key] === obj[key]);
    if (idx >= 0) {
        newList.splice(idx, 1, obj);
    } else {
        newList.unshift(obj);
    }
    return newList;
};

export const shouldSetTableData = (currentFilterSet, tableData) => {
    const redrawRequested = currentFilterSet?.stale === staleValue.redraw;
    const currentFilterSetLoaded = currentFilterSet?.results;
    const currentFilterSetNotStale = !currentFilterSet?.stale;
    return (
        (!tableData && currentFilterSetLoaded && currentFilterSetNotStale) ||
        redrawRequested
    );
};

export const redrawRequested = currentFilterSet => {
    return currentFilterSet?.stale === staleValue.redraw;
};
