import apiRequest from '../util/apiRequest';
import { createAction } from 'redux-act';
import { isFunction } from 'lodash';
import { arcgisToGeoJSON } from '@esri/arcgis-to-geojson-utils';

import { makeEsriFeatureServiceUrl } from '../util/constants';

export const startFetchGeometrySource = createAction(
    'Start fetch geometry source'
);
export const completeFetchGeometrySource = createAction(
    'Complete fetch geometry source'
);
export const failFetchGeometrySource = createAction(
    'Fail fetch geometry source'
);

export function fetchGeometrySource(sourceId, baseUrl, onFailure) {
    return async dispatch => {
        dispatch(startFetchGeometrySource(sourceId));
        try {
            const response = await apiRequest.get(
                makeEsriFeatureServiceUrl(baseUrl)
            );
            const payload = Object.assign(
                {},
                {
                    responseData: response.data,
                    sourceId,
                    status: response.status,
                }
            );
            if (response.status === 200) {
                payload.geojson = arcgisToGeoJSON(payload.responseData);
                dispatch(completeFetchGeometrySource(payload));
            } else {
                dispatch(failFetchGeometrySource(payload));
            }
        } catch (e) {
            dispatch(failFetchGeometrySource({ sourceId, error: e }));
            if (isFunction(onFailure)) {
                onFailure(e);
            }
        }
    };
}
