import { createAction } from 'redux-act';
import apiRequest from '../util/apiRequest';
import { updatePageNumber, downloadData } from '../util/downloadHelper';
import { getCSVDownloadDisplayValues } from '../util/schemaHelper';
import { zipObject } from 'lodash';

export const startDownload = createAction('Start download');
export const updateTotalCounts = createAction('Update total counts');
export const appendDownloadedData = createAction('Append downloaded data');
export const completeDownload = createAction('Complete download');
export const failDownload = createAction('Fail download');
export const clearDownload = createAction('Clear download');
export const cancelDownload = createAction('Cancel download');
export const startRetryDownload = createAction('Start retry download');
export const requestAdditionalDownloadFormat = createAction(
    'Request additional download format'
);

const shouldAddAdditionalFormat = (getState, fileFormat, key) => {
    const {
        downloads: { downloads },
    } = getState();
    const downloadObject = downloads[key];
    const downloadExistsForKey = downloadObject?.fileFormats?.length;
    const downloadExistsForFileFormat = downloadObject?.fileFormats?.includes(
        fileFormat
    );
    return downloadExistsForKey && !downloadExistsForFileFormat;
};

const downloadHasAlreadyBegun = (getState, key, fetchedPageCount) => {
    const {
        downloads: { downloads },
    } = getState();
    const downloadObject = downloads[key];
    return !!downloadObject || fetchedPageCount;
};

export function download({
    key,
    url,
    fileFormat,
    columns,
    fetchedPageCount,
    schema,
}) {
    const fieldNames = columns.map(c => c.name);
    const columnHeaders = columns.map(c => c.label);
    return async (dispatch, getState) => {
        // If an ongoing download exists for the requested key but not for the requested format,
        // note the request but don't start a second download of the same data.
        if (shouldAddAdditionalFormat(getState, fileFormat, key)) {
            dispatch(requestAdditionalDownloadFormat({ key, fileFormat }));
            return;
        }
        var nextPageUrl = url;

        // If the download has already begun (eg, in the case of a retry),
        // resume instead of restarting.
        if (downloadHasAlreadyBegun(getState, key, fetchedPageCount)) {
            dispatch(startRetryDownload({ key }));
            nextPageUrl = updatePageNumber(url, fetchedPageCount + 1);
        } else {
            dispatch(startDownload({ key, url, fileFormat, columns }));
        }

        while (nextPageUrl) {
            const {
                downloads: { downloads },
            } = getState();
            const downloadObject = downloads[key];
            const shouldFetchNextPage = downloadObject && !downloadObject.error;
            if (shouldFetchNextPage) {
                try {
                    const response = await apiRequest.get(nextPageUrl);
                    const {
                        data: { result, status, error },
                    } = response;

                    const displayValues = getCSVDownloadDisplayValues(
                        schema,
                        fieldNames,
                        result.data.map(obs => zipObject(fieldNames, obs))
                    );

                    if (status !== 200 || error) {
                        throw new Error(error);
                    }
                    const numPages = result?.paginator_data?.num_pages;
                    const count = result?.paginator_data?.count;
                    if (numPages || count) {
                        dispatch(
                            updateTotalCounts({
                                key,
                                numPages,
                                count,
                            })
                        );
                    }
                    const nextPageNumber =
                        result?.paginator_data?.next_page_number;
                    nextPageUrl = nextPageNumber
                        ? updatePageNumber(url, nextPageNumber)
                        : null;
                    if (result?.data) {
                        dispatch(
                            appendDownloadedData({
                                key,
                                data: displayValues,
                            })
                        );
                    }
                } catch (error) {
                    dispatch(failDownload({ key, error }));
                }
            } else {
                nextPageUrl = null;
            }
        }

        const {
            downloads: { downloads },
        } = getState();
        const downloadObject = downloads[key];
        if (!downloadObject || downloadObject.error) {
            return;
        }
        try {
            await downloadData(
                downloadObject.data,
                columnHeaders,
                downloadObject.fileFormats,
                key
            );
        } catch (error) {
            dispatch(failDownload({ key, error }));
        }
    };
}

export const startDownloadCount = createAction('Start download count');
export const completeDownloadCount = createAction('Complete download count');
export const failDownloadCount = createAction('Fail download count');

export function downloadCount({ url }) {
    return async dispatch => {
        dispatch(startDownloadCount({ url }));
        try {
            const response = await apiRequest.get(url);
            const {
                data: { result },
            } = response;
            const count = result?.count;
            if (count > -1) {
                dispatch(
                    completeDownloadCount({
                        url,
                        count,
                    })
                );
            }
        } catch (error) {
            dispatch(failDownloadCount({ url, error }));
        }
    };
}
