import { number, shape, string, bool, object, oneOf } from 'prop-types';
import { models, mediaTypes } from './constants';

export const point = shape({
    spatialReference: shape({
        wkid: number.isRequired,
    }).isRequired,
    x: number.isRequired,
    y: number.isRequired,
});

export const tableRowData = shape({
    key: string.isRequired,
    stationId: string.isRequired,
    geometry: point.isRequired,
    isOwner: bool.isRequired,
});

export const schema = shape({
    begin: string.isRequired,
    station: object.isRequired,
    end: string.isRequired,
    observation: object.isRequired,
    label: string.isRequired,
    name: string.isRequired,
});

export const project = shape({
    data: shape({
        defaultExtent: object.isRequired,
    }),
});

export const model = oneOf([models.OBSERVATION, models.STATION]);

export const tableColumn = shape({
    dataIndex: string.isRequired,
    key: string.isRequired,
    title: string.isRequired,
});

export const mediaType = oneOf(Object.keys(mediaTypes));

export const basemap = shape({
    iconUrl: string,
    zoomLevels: string,
    description: string,
    url: string.isRequired,
    class: string,
    label: string.isRequired,
});

export const visualization = shape({
    id: number.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    modified: string.isRequired,
    is_public: bool.isRequired,
});
