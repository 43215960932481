import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Spin, ConfigProvider } from 'antd';

import App from './App';
import './styles/app.less';
import configureStore from './store';
import { shouldSupportOffline } from './util';

const { store, persistor } = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Spin />} persistor={persistor}>
            <ConfigProvider input={{ autoComplete: 'chrome-ignore' }}>
                <App />
            </ConfigProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

const updateServiceWorkerIfWaiting = registration => {
    // let waiting Service Worker know it should became active
    if (registration.waiting) {
        registration.waiting.postMessage('SKIP_WAITING');
    }
};

// Register service worker in browser
if ('serviceWorker' in navigator) {
    if (shouldSupportOffline) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then(registration => {
                    console.log('Service worker registered', registration);

                    // Force-load the new service worker if one is waiting
                    updateServiceWorkerIfWaiting(registration);

                    // If an uninstalled new service worker is found,
                    // force load once it finishes installing
                    registration.addEventListener('updatefound', () => {
                        let newWorker = registration.installing;
                        if (newWorker) {
                            newWorker.addEventListener('statechange', () => {
                                updateServiceWorkerIfWaiting(registration);
                            });
                        }
                    });
                })
                .catch(err => console.log(err));
        });
    } else {
        // unregister any active service worker
        // in case the offline feature is shut off
        navigator.serviceWorker.ready
            .then(registration => {
                registration.unregister();
                console.log('Service worker unregistered', registration);
            })
            .catch(err => console.error(err.message));
    }
}
