import apiRequest from '../util/apiRequest';
import { createAction } from 'redux-act';
import update from 'immutability-helper';

import { makeSchemaUrl } from '../util/constants';
import { logErrorAndDispatchFailure, dispatchApiCompleteOrFail } from '../util';

export const startFetchSchema = createAction('Start fetch data schema');
export const completeFetchSchema = createAction('Complete fetch data schema');
export const failFetchSchema = createAction('Fail fetch data schema');

export function fetchSchema(name, rootUrl) {
    return async dispatch => {
        dispatch(startFetchSchema(name));
        try {
            const response = await apiRequest.get(makeSchemaUrl(name, rootUrl));
            dispatchApiCompleteOrFail(
                dispatch,
                completeFetchSchema,
                failFetchSchema,
                response.data.result
                    ? update(response.data, {
                          result: { key: { $set: name } },
                      })
                    : update(response.data, { name: { $set: name } })
            );
        } catch (e) {
            dispatch(
                logErrorAndDispatchFailure(
                    e,
                    `An error prevented fetching the schema named ${name}`,
                    failFetchSchema
                )
            );
        }
    };
}
