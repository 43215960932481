import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import HomeHero from './HomeHero';
import HomeAbout from './HomeAbout';
import HomeObservations from './HomeObservations';
import HomeExplore from './HomeExplore';
import LayoutSection from './LayoutSection';
import { showAuthMessages } from '../util/authHelper';
import { userIsApproved } from '../util/projectHelper';
import { getCurrentSchema } from '../util/schemaHelper';

export default function Home() {
    const auth = useSelector(state => state.auth);
    const project = useSelector(state => state.project);
    const schemas = useSelector(state => state.schema);
    const schema = getCurrentSchema(schemas);

    // Show success or error messages if any from query params
    const { search } = useLocation();
    // Show the message only once, else it shows multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => showAuthMessages(new URLSearchParams(search)), []);

    const observationsSection = schema &&
        project &&
        userIsApproved(auth.user, project) && (
            <HomeObservations schema={schema} project={project} />
        );

    const homeHero = project?.data && (
        <HomeHero project={project} label={schema?.label} />
    );

    return (
        <>
            <LayoutSection padding='40px 0'>{homeHero}</LayoutSection>
            <LayoutSection padding='40px 0 80px'>
                <HomeAbout
                    label={schema?.label}
                    description={project?.data?.description}
                    howToParticipate={project?.data?.howToParticipate}
                    whoIsInvolved={project?.data?.whoIsInvolved}
                />
            </LayoutSection>
            {observationsSection}
            <LayoutSection className='home-explore' padding='40px 0'>
                <HomeExplore
                    visualizationFolders={project?.data?.visualizationFolders}
                />
            </LayoutSection>
            {/* </LayoutSection> */}
        </>
    );
}
