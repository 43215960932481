import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    startFetchSchema,
    completeFetchSchema,
    failFetchSchema,
} from '../actions/schema';

const initialState = Object.freeze({});

const schemaReducer = createReducer(
    {
        [startFetchSchema]: (state, payload) =>
            update(state, {
                [payload]: {
                    $set: { data: null, fetching: true, error: false },
                },
            }),
        [completeFetchSchema]: (state, payload) =>
            update(state, {
                [payload.key]: {
                    $set: {
                        data: payload,
                        fetching: false,
                        error: false,
                    },
                },
            }),
        [failFetchSchema]: (state, payload) =>
            update(state, {
                [payload.name]: {
                    $set: { data: null, fetching: false, error: payload },
                },
            }),
    },
    initialState
);

export default schemaReducer;
