import React from 'react';
import { useSelector } from 'react-redux';
import { userCanAdminProject } from '../util/projectHelper';

function ShowIfProjectAdmin({ children }) {
    const project = useSelector(state => state.project);
    const user = useSelector(state => state.auth.user);

    if (userCanAdminProject(user, project?.data)) {
        return <>{children}</>;
    }

    return null;
}

export default ShowIfProjectAdmin;
