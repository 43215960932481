import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import fieldscopeLogo from '../assets/images/fieldscope-logo.png';
import bscsLogo from '../assets/images/bscs-logo.png';
import mooreLogo from '../assets/images/moore-foundation-logo.png';
import nsfLogo from '../assets/images/nsf-logo.png';
import { Layout, Row, Col, Typography, Menu, Divider, Tooltip } from 'antd';

import NewTabLink from './NewTabLink';

const { Footer } = Layout;
const { Text } = Typography;

const NSF_TOOLTIP =
    'This material is based upon work supported by the National Science Foundation under Grants No. GEO-0627923 and DRL-1010749. Any opinions, findings, and conclusions or recommendations expressed in this material are those of the author(s) and do not necessarily reflect the views of the National Science Foundation. The development of FieldScope has also been supported by the National Geographic Society.';
const BSCS_TOOLTIP =
    'BSCS Science Learning is an independent nonprofit dedicated to transforming science education through research-driven innovation.';
const MOORE_TOOLTIP =
    'This platform is funded by the Gordon and Betty Moore Foundation through Grant GBMF5241.01 to BSCS Science Learning.';

function AppFooter({ label }) {
    const location = useLocation();
    const onTheHomePage = location && location.pathname === '/';

    const FooterBrand = (
        <>
            <img
                className='footer__brand'
                alt='FieldScope logo'
                src={fieldscopeLogo}
            />
            <Text type='secondary' className='footer__copyright'>
                © 2020 BSCS Science Learning. All rights reserved.
            </Text>
        </>
    );

    const HomeFooter = (
        <>
            <Row className='container'>
                <Col className='footer__identity'>{FooterBrand}</Col>
                <Col flex='none' className='footer__navigation'>
                    <Row gutter={64}>
                        <Col>
                            <Text className='footer__title'>Fieldscope</Text>
                            <Menu
                                mode='vertical'
                                className='footer__navigation--corporate'
                                items={[
                                    {
                                        key: 'fieldscopeorg',
                                        label: (
                                            <NewTabLink href='http://www.fieldscope.org'>
                                                About
                                            </NewTabLink>
                                        ),
                                    },
                                    {
                                        key: 'gallery',
                                        label: (
                                            <NewTabLink href='http://www.fieldscope.org/projects/'>
                                                Project gallery
                                            </NewTabLink>
                                        ),
                                    },
                                    {
                                        key: 'launch',
                                        label: (
                                            <NewTabLink href='http://www.fieldscope.org/launch-a-project/'>
                                                Launch a project
                                            </NewTabLink>
                                        ),
                                    },
                                    {
                                        key: 'contact',
                                        label: (
                                            <NewTabLink href='http://www.fieldscope.org/contact/'>
                                                Contact us
                                            </NewTabLink>
                                        ),
                                    },
                                ]}
                            />
                        </Col>
                        <Col>
                            <Text className='footer__title'>{label}</Text>
                            <Menu
                                mode='vertical'
                                className='footer__navigation--project'
                                items={[
                                    {
                                        key: 'social1',
                                        label: <Link to='/'>Homepage</Link>,
                                    },
                                ]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Divider />
        </>
    );

    const SecondaryFooter = (
        <Row align='middle' justify='space-between' className='container'>
            <Col>
                {onTheHomePage ? null : FooterBrand}
                <a href='http://www.fieldscope.org/terms-of-use/'>
                    Terms
                </a> |{' '}
                <a href='http://www.fieldscope.org/privacy-policy/'>Privacy</a>
            </Col>
            <Col>
                <Text className='footer__support'>
                    FieldScope is made possible with support from
                </Text>
                {onTheHomePage ? null : <br />}
                <Tooltip title={NSF_TOOLTIP} arrowPointAtCenter>
                    <img
                        className='footer__logo footer__logo--nsf'
                        alt='NSF logo'
                        src={nsfLogo}
                    />
                </Tooltip>
                <Tooltip
                    title={MOORE_TOOLTIP}
                    placement='topRight'
                    arrowPointAtCenter
                >
                    <img
                        className='footer__logo footer__logo--moore'
                        alt='Moore Foundation logo'
                        src={mooreLogo}
                    />
                </Tooltip>
                <Tooltip
                    title={BSCS_TOOLTIP}
                    placement='topRight'
                    arrowPointAtCenter
                >
                    <img
                        className='footer__logo footer__logo--bscs'
                        alt='BSCS logo'
                        src={bscsLogo}
                    />
                </Tooltip>
            </Col>
        </Row>
    );

    return (
        <Footer className='footer'>
            {onTheHomePage ? HomeFooter : null}
            {SecondaryFooter}
        </Footer>
    );
}

export default AppFooter;
