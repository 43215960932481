import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message, Button, Typography, Card, Space, Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DownloadProgress from './DownloadProgress';
import { downloadingExitWarning } from '../util/constants';
import { clearDownload, cancelDownload, download } from '../actions/downloads';

const { Text } = Typography;

const makePercent = (fetchedPageCount, numPages) => {
    return Math.floor((fetchedPageCount / numPages) * 100);
};

function DownloadTray() {
    const dispatch = useDispatch();

    const [isExpanded, setIsExpanded] = useState(true);
    const rawDownloads = useSelector(state => state.downloads.downloads);

    const downloads = useMemo(
        () =>
            Object.entries(rawDownloads).reduce((acc, [key, d]) => {
                return [
                    ...acc,
                    ...d.fileFormats.map(fileFormat => ({
                        percentCompleted: makePercent(
                            d.fetchedPageCount,
                            d.numPages
                        ),
                        filename: key,
                        hasError: d.error,
                        fileFormat,
                    })),
                ];
            }, []),
        [rawDownloads]
    );

    // Removes item from downloads list following fade
    const removeHiddenItem = useCallback(
        filename => dispatch(clearDownload({ key: filename })),
        [dispatch]
    );

    // Prevents user from navigating away from Fieldscope while downloads are
    // actively in progress
    useEffect(() => {
        const key = 'download tray onbeforeunload';
        if (
            !!downloads &&
            downloads.some(download => download.percentCompleted < 100)
        ) {
            window.onbeforeunload = () => {
                message.warning({
                    content: downloadingExitWarning,
                    duration: 5,
                    key,
                    onClick: () => message.destroy(key),
                });
                setIsExpanded(true);
                return true;
            };
            return () => (window.onbeforeunload = undefined);
        }
    }, [downloads]);

    const handleCancel = (filename, fileFormat) =>
        dispatch(cancelDownload({ key: filename, fileFormat }));

    const handleRetry = filename => {
        const progressItem = rawDownloads[filename];
        dispatch(
            download({
                key: filename,
                url: progressItem.initialUrl,
                // Multiple formats come from the same downloaded data so we
                // only need to dispatch a retry for the first format
                fileFormat: progressItem.fileFormats[0],
                columns: progressItem.columns,
                fetchedPageCount: progressItem.fetchedPageCount,
            })
        );
    };

    const hasError = downloads.some(download => download.hasError);
    const toggleExpanded = () => setIsExpanded(!isExpanded);

    const downloadIsCompleted = d => d.percentCompleted === 100 && !d.hasError;

    const isHidden =
        !downloads.length ||
        !downloads.some(d => !downloadIsCompleted(d) && !d.isCanceled);

    if (!Object.keys(rawDownloads).length) {
        return null;
    }

    return (
        <Card
            title={
                <Space size={7}>
                    <Text>File preparation </Text>
                    <Text>
                        <Badge
                            count={downloads.length}
                            className='download-progress-badge'
                        />
                    </Text>
                    {hasError && (
                        <Text>
                            <FontAwesomeIcon
                                icon={['far', 'exclamation-triangle']}
                            />
                        </Text>
                    )}
                </Space>
            }
            extra={
                <Button
                    type='text'
                    icon={
                        <FontAwesomeIcon
                            icon={
                                isExpanded
                                    ? ['far', 'compress-alt']
                                    : ['far', 'expand-alt']
                            }
                        />
                    }
                    onClick={toggleExpanded}
                />
            }
            className={`download-progress ${isHidden ? 'fade' : ''} ${
                isExpanded ? 'download-progress-expanded' : ''
            }`}
        >
            <div className='download-progress-list'>
                {downloads.map(
                    (
                        {
                            percentCompleted,
                            isCanceled,
                            hasError,
                            filename,
                            fileFormat,
                        },
                        i
                    ) => {
                        return (
                            <DownloadProgress
                                percentCompleted={percentCompleted}
                                isCanceled={isCanceled}
                                hasError={hasError}
                                filename={filename}
                                fileFormat={fileFormat}
                                isFirst={i === 0}
                                handleCancel={handleCancel}
                                handleRetry={handleRetry}
                                removeHiddenItem={removeHiddenItem}
                                key={`${filename}.${fileFormat}`}
                            />
                        );
                    }
                )}
            </div>
        </Card>
    );
}

export default DownloadTray;
