import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { findIndex } from 'lodash';

import {
    initNewVisualization,
    startSaveVisualization,
    completeSaveVisualization,
    failSaveVisualization,
    startFetchVisualizations,
    completeFetchVisualizations,
    failFetchVisualizations,
    startFetchVisualization,
    completeFetchVisualization,
    failFetchVisualization,
    updateViewVisualizationWidgetProp,
} from '../actions/visualization';

export const initialState = {
    list: {
        data: null,
        fetching: false,
        error: null,
    },
    detail: {
        data: null,
        fetching: false,
        fetched: false,
        error: null,
    },
};

const VisualizationReducer = createReducer(
    {
        [initNewVisualization]: (state, payload) =>
            update(state, { detail: { $set: initialState.detail } }),
        [startFetchVisualizations]: (state, payload) =>
            update(state, {
                list: {
                    fetching: { $set: true },
                    error: { $set: null },
                },
            }),
        [completeFetchVisualizations]: (state, payload) =>
            update(state, {
                list: {
                    data: { $set: payload },
                    fetching: { $set: false },
                    error: { $set: null },
                },
            }),
        [failFetchVisualizations]: (state, payload) =>
            update(state, {
                list: {
                    fetching: { $set: false },
                    error: { $set: payload },
                },
            }),
        [startFetchVisualization]: (state, payload) =>
            update(state, {
                detail: {
                    fetching: { $set: true },
                    fetched: { $set: false },
                    error: { $set: null },
                },
            }),
        [completeFetchVisualization]: (state, payload) =>
            update(state, {
                detail: {
                    data: { $set: payload },
                    fetching: { $set: false },
                    fetched: { $set: true },
                    error: { $set: null },
                },
            }),
        [failFetchVisualization]: (state, payload) =>
            update(state, {
                detail: {
                    fetching: { $set: false },
                    fetched: { $set: false },
                    error: { $set: payload },
                },
            }),
        [startSaveVisualization]: (state, payload) =>
            update(state, {
                detail: {
                    fetching: { $set: true },
                    error: { $set: null },
                    fetched: { $set: false },
                },
            }),
        [completeSaveVisualization]: (state, payload) =>
            update(state, {
                detail: {
                    data: { $set: payload },
                    fetching: { $set: false },
                    fetched: { $set: true },
                    error: { $set: null },
                },
            }),
        [failSaveVisualization]: (state, payload) =>
            update(state, {
                detail: {
                    fetching: { $set: false },
                    fetched: { $set: false },
                    error: { $set: payload },
                },
            }),
        [updateViewVisualizationWidgetProp]: (state, { id, key, value }) => {
            const index = findIndex(state.detail.data.configuration.widgets, {
                id,
            });
            return update(state, {
                detail: {
                    data: {
                        configuration: {
                            widgets: {
                                [index]: { [key]: { $set: value } },
                            },
                        },
                    },
                },
            });
        },
    },
    initialState
);

export default VisualizationReducer;
