import { createReducer } from 'redux-act';
import update from 'immutability-helper';
import { isNil } from 'lodash';
import {
    setBasemap,
    toggleDataLayer,
    setLayerOpacity,
    setEmbed,
    setOffline,
    setHasOfflineObservations,
    setOptOutDownloadInfo,
    setObservationEditLocation,
} from '../actions/ui';

export const initialState = {
    basemap: null,
    activeDataLayerIds: [],
    layerOpacity: {},
    embed: false,
    isOffline: false,
    hasOfflineObservations: false,
    optOutDownloadInfo: false,
    observationEditLocation: null,
};

const UIReducer = createReducer(
    {
        [setBasemap]: (state, payload) =>
            update(state, { basemap: { $set: payload } }),
        [toggleDataLayer]: (state, payload) => {
            const index = state.activeDataLayerIds?.indexOf(payload);
            if (isNil(index)) {
                return update(state, {
                    activeDataLayerIds: { $set: [payload] },
                });
            } else if (index === -1) {
                return update(state, {
                    activeDataLayerIds: { $push: [payload] },
                });
            } else {
                return update(state, {
                    activeDataLayerIds: { $splice: [[index, 1]] },
                });
            }
        },
        [setLayerOpacity]: (state, { layerId, value }) => {
            return update(state, {
                layerOpacity: {
                    [layerId]: { $set: value },
                },
            });
        },
        [setEmbed]: (state, payload) =>
            update(state, { embed: { $set: payload } }),
        [setOffline]: (state, payload) =>
            update(state, { isOffline: { $set: payload } }),
        [setHasOfflineObservations]: (state, payload) =>
            update(state, { hasOfflineObservations: { $set: payload } }),
        [setOptOutDownloadInfo]: (state, payload) =>
            update(state, { optOutDownloadInfo: { $set: payload } }),
        [setObservationEditLocation]: (state, payload) =>
            update(state, { observationEditLocation: { $set: payload } }),
    },
    initialState
);

export default UIReducer;
