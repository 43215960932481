import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { find } from 'lodash';

import FilterSetDataViewer from './FilterSetDataViewer';

import {
    createObservationsQuery,
    userOptions,
    recentOptions,
    groupOptions,
} from '../util/queryHelper';
import { getCurrentSchema } from '../util/schemaHelper';
import { userIsProjectManager } from '../util/projectHelper';

import { setActiveFilterSetName } from '../actions/filterSet';
import { setBasemap, toggleDataLayer, setLayerOpacity } from '../actions/ui';

import {
    hardcodedMyFilterSetName,
    hardcodedRecentFilterSetName,
    hardcodedAllFilterSetName,
    hardcodedGroupFilterSetName,
    hardcodedMyOfflineFilterSetName,
} from '../util/constants';
import { shouldSupportOffline } from '../util';

function ObservationHome({
    project,
    activeFilterSetName,
    schemas,
    stations,
    basemap,
    activeDataLayerIds,
    layerOpacity,
    isOffline,
    hasOfflineObservations,
    user,
    dispatch,
}) {
    const schema = getCurrentSchema(schemas);
    const dataSource = find(
        project?.data?.dataSources,
        d => d.schema === schema?.name
    );
    const dataSourceOptions = { dataSource: dataSource?.id };

    useEffect(() => {
        // switch to Recent filterset if the user logged out
        if (activeFilterSetName === hardcodedMyOfflineFilterSetName && !user) {
            dispatch(setActiveFilterSetName(hardcodedRecentFilterSetName));
        }
        // if the user is offline, switch filterSet to Offline
        else if (user && isOffline && stations) {
            dispatch(setActiveFilterSetName(hardcodedMyOfflineFilterSetName));
        }
        // in this view we can only see one of the hardcoded filtersets so if
        // the active filterset is custom then by default revert it to the recent
        // observations
        else if (
            !activeFilterSetName ||
            ![
                hardcodedAllFilterSetName,
                hardcodedMyFilterSetName,
                hardcodedRecentFilterSetName,
                hardcodedGroupFilterSetName,
                hardcodedMyOfflineFilterSetName,
            ].includes(activeFilterSetName)
        ) {
            dispatch(setActiveFilterSetName(hardcodedRecentFilterSetName));
        }
    }, [user, dispatch, isOffline, activeFilterSetName, stations]);

    // All members of a project group can see group's observation data
    const projectRelationship = user?.project_relationships[project.name]?.[0];
    const groupInfo = projectRelationship?.loc_field
        ? projectRelationship
        : null;

    const additionalOptions = {
        isManager: userIsProjectManager(user, project?.data),
        useStations: project?.data?.useStations,
    };

    const filterMap = schema && {
        [hardcodedRecentFilterSetName]: createObservationsQuery(
            schema,
            Object.assign(
                {},
                recentOptions,
                dataSourceOptions,
                additionalOptions
            )
        ),
        [hardcodedMyFilterSetName]: createObservationsQuery(
            schema,
            Object.assign(
                {},
                userOptions(user?.userId),
                dataSourceOptions,
                additionalOptions
            )
        ),
        [hardcodedAllFilterSetName]: createObservationsQuery(
            schema,
            Object.assign({}, dataSourceOptions, additionalOptions)
        ),
        [hardcodedGroupFilterSetName]:
            groupInfo &&
            createObservationsQuery(
                schema,
                Object.assign(
                    {},
                    groupOptions(groupInfo),
                    dataSourceOptions,
                    additionalOptions
                )
            ),
        [hardcodedMyOfflineFilterSetName]: createObservationsQuery(schema, {}),
    };

    // force displaying offline mode if authenticated user is offline
    const isOfflineEnabledOnProject =
        shouldSupportOffline && project?.data?.dataSchemas?.length > 0;
    const defaultActiveKey =
        user && isOffline
            ? hardcodedMyOfflineFilterSetName
            : hardcodedRecentFilterSetName;

    const areStationsLoaded = !!stations;

    return (
        <>
            <Tabs
                defaultActiveKey={defaultActiveKey}
                activeKey={activeFilterSetName}
                onTabClick={key => dispatch(setActiveFilterSetName(key))}
                items={[
                    user &&
                        isOfflineEnabledOnProject &&
                        (isOffline || hasOfflineObservations) && {
                            label: 'My Offline Observations',
                            key: hardcodedMyOfflineFilterSetName,
                            disabled: !areStationsLoaded,
                        },
                    user && {
                        label: 'My Observations',
                        key: hardcodedMyFilterSetName,
                        disabled: isOffline,
                    },
                    {
                        label: 'Recent Observations',
                        key: hardcodedRecentFilterSetName,
                        disabled: isOffline,
                    },
                    {
                        label: 'All Observations',
                        key: hardcodedAllFilterSetName,
                        disabled: isOffline,
                    },
                    groupInfo && {
                        label: 'My Group',
                        key: hardcodedGroupFilterSetName,
                        disabled: isOffline,
                    },
                ]}
            />
            <FilterSetDataViewer
                schema={schema}
                filterSetName={activeFilterSetName}
                filters={filterMap?.[activeFilterSetName]}
                basemap={basemap}
                activeDataLayerIds={activeDataLayerIds}
                layerOpacity={layerOpacity}
                onToggleDataLayer={layerId =>
                    dispatch(toggleDataLayer(layerId))
                }
                onBasemapChange={value => dispatch(setBasemap(value))}
                onLayerOpacityChange={(layerId, value) =>
                    dispatch(setLayerOpacity({ layerId, value }))
                }
                showHeader
            />
        </>
    );
}

function mapStateToProps({
    project,
    schema,
    stations: { data: stations },
    data: { activeFilterSetName },
    auth: { user },
    dispatch,
    ui: {
        basemap,
        activeDataLayerIds,
        layerOpacity,
        isOffline,
        hasOfflineObservations,
    },
}) {
    return {
        project,
        activeFilterSetName,
        schemas: schema,
        stations,
        basemap,
        activeDataLayerIds,
        layerOpacity,
        isOffline,
        hasOfflineObservations,
        user,
        dispatch,
    };
}

export default connect(mapStateToProps)(ObservationHome);
