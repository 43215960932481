import { createAction } from 'redux-act';
import apiRequest from '../util/apiRequest';
import isFunction from 'lodash/isFunction';
import update from 'immutability-helper';

import { visualizationsUrl, makeVisualizationUrl } from '../util/constants';
import { logErrorAndDispatchFailure, dispatchApiCompleteOrFail } from '../util';
import { copyFilterSetData } from './filterSet';

export const initNewVisualization = createAction('Init new visualization');
export const setVisualizationTitle = createAction('Set visualization title');
export const setVisualizationDescription = createAction(
    'Set visualization description'
);
export const toggleVisualizationIsPublic = createAction(
    'Toggle visualization is public'
);
export const updateVisualizationConfiguration = createAction(
    'Update working visualization configuration'
);
export const updateVisualizationFilterSetProp = createAction(
    'Update visualization filter set prop'
);
export const updateVisualizationFilterSetDataSource = createAction(
    'Update visualization filter set data source'
);
export const deleteVisualizationFilterSetFilter = createAction(
    'Delete visualization filter set filter'
);
export const updateVisualizationWidgetProp = createAction(
    'Update visualization widget prop'
);
export const updateVisualizationWidgetProps = createAction(
    'Update visualization widget props'
);
export const updateViewVisualizationWidgetProp = createAction(
    'Update view visualization widget prop'
);
export const deleteVisualizationFilterSet = createAction(
    'Delete visualization filter set'
);
export const deleteVisualizationWidget = createAction(
    'Delete visualization widget'
);

export const startFetchVisualizations = createAction(
    'Start fetch visualizations'
);
export const completeFetchVisualizations = createAction(
    'Complete fetch visualizations'
);
export const failFetchVisualizations = createAction(
    'Fail fetch visualizations'
);

export const startFetchVisualization = createAction(
    'Start fetch visualization'
);
export const completeFetchVisualization = createAction(
    'Complete fetch visualization'
);
export const failFetchVisualization = createAction('Fail fetch visualization');

export const startSaveVisualization = createAction('Start save visualization');
export const completeSaveVisualization = createAction(
    'Complete save visualization'
);
export const failSaveVisualization = createAction('Fail delete visualization');

export const startDeleteVisualization = createAction(
    'Start delete visualization'
);
export const completeDeleteVisualization = createAction(
    'Complete save visualization'
);
export const failDeleteVisualization = createAction(
    'Fail delete visualization'
);
export const makePrimaryWidget = createAction('Make primary widget');
export const replaceVisualizationWorkspaceContent = createAction(
    'Replace visualization workspace content'
);

export function fetchVisualizations() {
    return dispatch => {
        dispatch(startFetchVisualizations());
        return apiRequest
            .get(visualizationsUrl)
            .then(({ data }) => {
                return dispatchApiCompleteOrFail(
                    dispatch,
                    completeFetchVisualizations,
                    failFetchVisualizations,
                    data
                );
            })
            .catch(e => {
                dispatch(
                    logErrorAndDispatchFailure(
                        e,
                        `An error occurred fetching visualizations`,
                        failFetchVisualization
                    )
                );
            });
    };
}

export function fetchVisualization(id) {
    return dispatch => {
        dispatch(startFetchVisualization());
        return apiRequest
            .get(makeVisualizationUrl(id))
            .then(({ data }) => {
                return dispatchApiCompleteOrFail(
                    dispatch,
                    completeFetchVisualization,
                    failFetchVisualization,
                    data
                );
            })
            .catch(e => {
                dispatch(
                    logErrorAndDispatchFailure(
                        e,
                        `An error occurred fetching visualization ${id}`,
                        failFetchVisualization
                    )
                );
            });
    };
}

export function saveVisualization(visualization, onComplete, onFail) {
    return dispatch => {
        dispatch(startSaveVisualization());
        if (visualization.id) {
            // UPDATE
            return apiRequest
                .put(makeVisualizationUrl(visualization.id), visualization)
                .then(({ data }) => {
                    return dispatchApiCompleteOrFail(
                        dispatch,
                        completeSaveVisualization,
                        failSaveVisualization,
                        data,
                        onComplete,
                        onFail
                    );
                })
                .catch(e => {
                    dispatch(
                        logErrorAndDispatchFailure(
                            e,
                            `An error occurred saving the visualization`,
                            failSaveVisualization
                        )
                    );
                    if (isFunction(onFail)) {
                        onFail(e);
                    }
                });
        } else {
            // CREATE
            return apiRequest
                .post(visualizationsUrl, visualization)
                .then(({ data }) => {
                    if (visualization.copiedFrom) {
                        dispatch(
                            copyFilterSetData({
                                from: visualization.copiedFrom,
                                to: data.result.id,
                            })
                        );
                    }

                    return dispatchApiCompleteOrFail(
                        dispatch,
                        completeSaveVisualization,
                        failSaveVisualization,
                        data,
                        onComplete,
                        onFail
                    );
                })
                .catch(e => {
                    dispatch(
                        logErrorAndDispatchFailure(
                            e,
                            `An error occurred creating a new visualization`,
                            failSaveVisualization
                        )
                    );
                    if (isFunction(onFail)) {
                        onFail(e);
                    }
                });
        }
    };
}

export function saveVisualizationAsNew(visualization, onComplete, onFail) {
    const copiedVisualization = update(visualization, {
        $unset: ['id', 'owner'],
        copiedFrom: { $set: visualization.id },
    });
    return dispatch => {
        dispatch(saveVisualization(copiedVisualization, onComplete, onFail));
    };
}

function completeDeleteVisualizationAndFetchAll(payload) {
    return dispatch => {
        dispatch(completeDeleteVisualization(payload));
        dispatch(fetchVisualizations());
    };
}

export function deleteVisualization(id, onComplete, onFail) {
    return dispatch => {
        dispatch(startDeleteVisualization());
        return apiRequest
            .delete(makeVisualizationUrl(id))
            .then(({ data }) => {
                return dispatchApiCompleteOrFail(
                    dispatch,
                    completeDeleteVisualizationAndFetchAll,
                    failDeleteVisualization,
                    data,
                    onComplete,
                    onFail
                );
            })
            .catch(e => {
                dispatch(
                    logErrorAndDispatchFailure(
                        e,
                        `An error occurred deleting visualization ${id}`,
                        failDeleteVisualization
                    )
                );
                if (isFunction(onFail)) {
                    onFail(e);
                }
            });
    };
}
