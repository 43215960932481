import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import pluralize from 'pluralize';
import { pick } from 'lodash';

import ObservationTableDownloadModal from './ObservationTableDownloadModal';

import {
    makeCsvUrl,
    csvPageSize,
    managerFieldSchemaFolders,
} from '../util/constants';
import {
    pluckFieldDefinitionsByName,
    userColumnLabels,
} from '../util/schemaHelper';
import { download, downloadCount } from '../actions/downloads';
import { zipWith, merge } from 'lodash';

const makeDownloadQuery = (filters, csvPageSize) => {
    // The `filters` property of a `filterSet` object may contain additional fields
    // that are ignored by the API so we remove them before submitting
    const pickedProperties = filters
        ? pick(filters, [
              'fields',
              'filters',
              'limit',
              'offset',
              'orderby',
              'query',
              'stations',
              'table',
              'wkid',
          ])
        : null;
    const downloadQueryObj = pickedProperties
        ? { ...pickedProperties, pageSize: csvPageSize }
        : null;
    const downloadQuery = downloadQueryObj
        ? encodeURIComponent(JSON.stringify(downloadQueryObj))
        : '';
    return downloadQuery;
};

const formatCountText = (elementName, count) =>
    !count
        ? null
        : `${count.toLocaleString()} ${pluralize(elementName, count)}`;

function DownloadButton({
    filterSetName,
    filters,
    dispatch,
    isDownloadAllowed,
    schema,
    elementName,
    isTableVisible,
    userSchemaColumnMap,
}) {
    const countUrl = makeCsvUrl(
        schema?.key || 'data',
        makeDownloadQuery({ ...filters, count_only: true }, csvPageSize)
    );
    const count = useSelector(state => state.downloads.counts[countUrl]?.count);

    useEffect(() => {
        dispatch(downloadCount({ url: countUrl }));
    }, [countUrl, dispatch]);

    const resultsText = formatCountText(elementName, count);
    const displayColumns = pluckFieldDefinitionsByName(
        schema,
        filters.fields,
        null,
        managerFieldSchemaFolders
    );

    const columnLabels =
        elementName === 'user'
            ? userColumnLabels(filters.fields, userSchemaColumnMap)
            : filters.fields.map(f => {
                  const column = displayColumns.find(c => c.name === f);
                  return column ? column.label : f;
              });

    const columns = zipWith(
        filters.fields.map(name => ({ name })),
        columnLabels.map(label => ({ label })),
        (a, b) => merge(a, b)
    );

    return isDownloadAllowed && isTableVisible ? (
        <ObservationTableDownloadModal
            downloadCallback={fileFormat => {
                const downloadQuery = makeDownloadQuery(filters, csvPageSize);
                dispatch(
                    download({
                        key: filterSetName,
                        url: makeCsvUrl(schema?.key || 'data', downloadQuery),
                        fileFormat,
                        columns,
                        schema,
                    })
                );
            }}
            filename={filterSetName}
            count={count}
            countText={resultsText}
        />
    ) : null;
}

export default DownloadButton;
