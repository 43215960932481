import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';
import mapboxgl from 'mapbox-gl';
import { pick } from 'lodash';
import moment from 'moment';

import { getUser } from '../actions/auth';
import { fetchProject } from '../actions/project';
import { fetchSchema } from '../actions/schema';
import { fetchStations } from '../actions/stations';
import {
    checkIfOfflineObservations,
    setEmbed,
    setOffline,
    setOnlineStatus,
} from '../actions/ui';
import { getProjectName, addScript, shouldSupportOffline } from '.';
import { isServiceWorkerActivated } from '../util';

function defaultSchemaName(project) {
    if (project?.dataSchemas?.length > 0) {
        return project.dataSchemas[0].schema;
    }
    if (project?.dataSources?.length > 0) {
        return project.dataSources[0].schema;
    }
    return null;
}

export function useInitialize() {
    // When testing the loading of image layers from the fieldscope.org ArcGIS
    // servers some tiles were taking a long time to return. Reducing the
    // default 16 to 8 improves overall render performance
    mapboxgl.maxParallelImageRequests = 8;

    const projectName = getProjectName();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const project = useSelector(state => state.project.data);
    const schemas = useSelector(state => state.schema);
    const { data: stations, expirationDate: stationsExpiration } = useSelector(
        state => state.stations
    );

    // Determine authentication status
    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(getUser());
        }
    }, [dispatch, isLoggedIn]);

    // Fetch project detail
    useEffect(() => {
        dispatch(fetchProject(projectName));
    }, [dispatch, projectName]);

    const schemaDetails = project?.dataSources?.map(ds =>
        pick(ds, ['schema', 'url'])
    );
    useEffect(() => {
        if (schemaDetails) {
            schemaDetails.forEach(({ schema, url }) => {
                if (!schemas?.[schema] && !schemas?.[schema]?.fetching) {
                    dispatch(fetchSchema(schema, url));
                }
            });
        }
    }, [dispatch, schemas, schemaDetails]);

    // We only need to fetch stations for the default schema since that is the
    // only schema used for data entry.
    const schema = defaultSchemaName(project);
    const serviceWorkerIsActivated = isServiceWorkerActivated();

    useEffect(() => {
        const stationsAreExpired =
            stationsExpiration && moment() >= stationsExpiration;
        if (schema && (!stations || stationsAreExpired)) {
            dispatch(fetchStations(schema));
        }
    }, [
        dispatch,
        schema,
        stations,
        stationsExpiration,
        serviceWorkerIsActivated,
    ]);

    // The stations request is long-running. If the service worker updates
    // while it is running, the request returns a 404 error.
    // If we are missing stations data, retry the request following the
    // update.
    const handleServiceWorkerUpdate = useCallback(() => {
        const hasStations = stations && stations.length;
        if (schema && !hasStations) {
            dispatch(fetchStations(schema));
        }
    }, [dispatch, schema, stations]);
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener(
                'controllerchange',
                handleServiceWorkerUpdate
            );
        }

        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener(
                    'controllerchange',
                    handleServiceWorkerUpdate
                );
            }
        };
    }, [handleServiceWorkerUpdate]);

    // Set embed mode if `?embed` querystring parameter is present.
    // If the param is present or has any value, we set embed to true.
    // If it is absent or misspelled, it will be set to false.
    const embed = useSearchParam('embed');
    useEffect(() => {
        const isEmbed = embed !== null;
        dispatch(setEmbed(isEmbed));

        if (!isEmbed) {
            // Load ZenDesk in non-embed mode
            addScript({
                id: 'ze-snippet',
                src:
                    'https://static.zdassets.com/ekr/snippet.js?key=c35b3e4b-0298-45d2-bf5c-c5ed4193e028',
            });
        }

        // We only want to set this on first load, not on every navigation,
        // so we don't include `embed` in the dependency list.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    // Reset offline observation status between user sessions
    useEffect(() => {
        dispatch(checkIfOfflineObservations());
    }, [dispatch, isLoggedIn]);

    useEffect(() => {
        if (shouldSupportOffline && project?.dataSchemas?.length > 0) {
            let timeout;
            const pollOffline = () => {
                dispatch(setOnlineStatus());
                timeout = setTimeout(pollOffline, 5000);
            };

            const handleGoingOnline = () => {
                dispatch(setOffline(false));
                dispatch(checkIfOfflineObservations());
                clearTimeout(timeout);
            };

            const handleGoingOffline = () => dispatch(setOffline(true));

            const handleGoingOfflineManually = () => {
                handleGoingOffline();
                pollOffline();
            };

            // On initial load, check and set offline status
            dispatch(setOnlineStatus());

            // Toggle the offline mode on or off if the status changes
            window.addEventListener('online', handleGoingOnline);
            window.addEventListener('offline', handleGoingOffline);
            window.addEventListener(
                'manual-offline',
                handleGoingOfflineManually
            );
            window.addEventListener('manual-online', handleGoingOnline);

            // Remove event listeners on dismount
            return () => {
                window.removeEventListener('online', handleGoingOnline);
                window.removeEventListener('offline', handleGoingOffline);
                window.removeEventListener(
                    'manual-offline',
                    handleGoingOfflineManually
                );
                window.removeEventListener('manual-online', handleGoingOnline);
                clearTimeout(timeout);
            };
        }
    }, [dispatch, project]);
}
