import Qty from 'js-quantities';
import intersection from 'lodash/intersection';

export const degC = 'degC';
export const deg_C = 'deg C';
export const degF = 'degF';
export const deg_F = 'deg F';
export const meter = 'm';
export const millimeter = 'mm';
const centimeter = 'cm';
const kilometer = 'km';
const inch = 'in';
const foot = 'ft';
const yard = 'yd';
const mile = 'mi';

const metricLengthUnits = [meter, millimeter, centimeter, kilometer];
const imperialLengthUnits = [inch, foot, yard, mile];

const metricAreaUnits = metricLengthUnits.map(unit => `${unit}^2`);
const imperialAreaUnits = imperialLengthUnits.map(unit => `${unit}^2`);

export const validTemperatureUnits = [degC, deg_C, degF, deg_F];
export const validLengthUnits = [...metricLengthUnits, ...imperialLengthUnits];
export const validAreaUnits = [...metricAreaUnits, ...imperialAreaUnits];
const validUnits = [
    ...validTemperatureUnits,
    ...validLengthUnits,
    ...validAreaUnits,
];

export const createUnitsSelectOptions = units =>
    units.map(unit => ({
        value: unit,
        label: formatUnit(unit),
    }));

export const getValidUnits = unitList => intersection(validUnits, unitList);

export const convertUnit = (value, fromUnit, toUnit, precision) => {
    if (
        fromUnit === toUnit ||
        !validUnits.includes(fromUnit) ||
        !validUnits.includes(toUnit)
    ) {
        return value;
    }

    let convertedValue;

    // temperature keywords don't align well with helper library js-quantities
    if (validTemperatureUnits.includes(fromUnit)) {
        switch (toUnit) {
            case degF:
                convertedValue = (value * 9) / 5 + 32;
                break;
            // otherwise, convert to degC
            default:
                convertedValue = ((value - 32) * 5) / 9;
                break;
        }
    } else {
        convertedValue = Qty(value, fromUnit).to(toUnit).scalar;
    }
    return Number(convertedValue.toFixed(precision));
};

export const formatUnit = unit => {
    if (['degc', 'deg c'].indexOf(unit?.toLowerCase()) >= 0) {
        return '°C';
    }
    if (['degf', 'deg f'].indexOf(unit?.toLowerCase()) >= 0) {
        return '°F';
    }
    return unit;
};
