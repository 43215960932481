import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Form, Radio, Select, Typography, Space, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ObservationFormStationForm from './ObservationFormStationForm';
import ObservationFormStationMap from './ObservationFormStationMap';
import { stationIdKey } from '../util/constants';
import { addStation } from '../actions/stations';

const { Text } = Typography;

const { Option } = Select;

const ALL_STATIONS = 'ALL_STATIONS';
const PREVIOUSLY_USED_STATIONS = 'PREVIOUSLY_USED_STATIONS';

export default function ObservationFormStations({
    isFetchingStations,
    stations,
    user,
    form,
    schema,
    project,
    basemap,
    isOffline,
}) {
    const [stationFilter, setStationFilter] = useState(
        PREVIOUSLY_USED_STATIONS
    );
    const [shouldZoomToStation, setShouldZoomToStation] = useState(false);

    // When creating the "your previous" list of stations we include the
    // station already selected on the form so that a manager who is editing the
    // observation but is not in the `registerUserIds` list for the station
    // will be able to see the station in the list
    const filteredStations =
        stationFilter === ALL_STATIONS
            ? stations
            : stations?.filter(
                  station =>
                      station.registeredUserIds.includes(user?.userId) ||
                      form.getFieldValue(stationIdKey) === station.stationId
              );

    const options = filteredStations?.map(({ stationId, stationName }) => (
        <Option key={stationId} value={stationId}>
            {stationName}
        </Option>
    ));

    const onMapClick = feature => {
        form.setFieldsValue({
            [stationIdKey]: feature?.id.toString(),
        });
    };

    const dispatch = useDispatch();

    const newStationModal = (
        <ObservationFormStationForm
            schema={schema}
            project={project}
            onFinish={station => {
                dispatch(addStation({ station, user, schema }));
                form.setFieldsValue({
                    stationId: station.stationId,
                });
            }}
            basemap={basemap}
            isOffline={isOffline}
            userId={user?.userId}
        />
    );

    return (
        <>
            <Radio.Group
                onChange={e => {
                    form.setFieldsValue({ stationId: null });
                    setStationFilter(e.target.value);
                }}
                value={stationFilter}
            >
                <Radio value={PREVIOUSLY_USED_STATIONS}>
                    Your previous stations
                </Radio>
                <Radio value={ALL_STATIONS}>All stations</Radio>
            </Radio.Group>
            <Form.Item
                name={stationIdKey}
                rules={[
                    {
                        required: true,
                        message: `Please select a station`,
                    },
                ]}
            >
                <Select
                    showSearch
                    autoComplete={'chrome-ignore'}
                    placeholder='Select a station'
                    filterOption={(input, option) =>
                        option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    notFoundContent={
                        isFetchingStations ? (
                            <Spin tip='Loading...' style={{ width: '100%' }} />
                        ) : null
                    }
                    onSelect={() => setShouldZoomToStation(true)}
                >
                    {options}
                </Select>
            </Form.Item>
            <Alert
                style={{ marginBottom: '10px' }}
                message={
                    <Space>
                        <Text>
                            You can add a new station or select a different
                            station on the map below.
                        </Text>
                        {newStationModal}
                    </Space>
                }
                type='info'
                showIcon
                icon={<FontAwesomeIcon icon={['far', 'map']} />}
            />
            <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                    prevValues.stationId !== curValues.stationId
                }
            >
                {({ getFieldValue }) => {
                    return (
                        <ObservationFormStationMap
                            stations={filteredStations}
                            onClick={onMapClick}
                            selectedId={getFieldValue(stationIdKey)}
                            project={project}
                            basemap={basemap}
                            shouldZoomToStation={shouldZoomToStation}
                            setShouldZoomToStation={setShouldZoomToStation}
                        />
                    );
                }}
            </Form.Item>
        </>
    );
}
