import React, { useEffect, useState } from 'react';
import apiRequest from '../util/apiRequest';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { Input, Table, Tabs } from 'antd';
import moment from 'moment';
import map from 'lodash/map';

import { manageUrl } from '../util/constants';
import { userCanAdminProject, userIsLoc } from '../util/projectHelper';

import ManageUsersTableDefinition from './ManageUsersTableDefinition';
import ManageLocsTableDefinition from './ManageLocsTableDefinition';

const { Search } = Input;

const table = options => (
    <div className='observation-data'>
        <Table
            className='observation-table'
            title={() => <h1>{options.title}</h1>}
            rowKey='id'
            columns={options.columns}
            dataSource={options.dataSource}
            rowSelection={{
                selectionType: 'checkbox',
            }}
            pagination={{
                position: ['bottomCenter'],
            }}
            loading={options.loading}
            scroll={{ x: 'max-content' }}
            sticky={{ offsetHeader: 64 }}
            showHeader={options.dataSource?.length > 0}
        />
    </div>
);

const paginatedTable = options => (
    <div className='paginated-table'>
        <Table
            className='paginated-table'
            title={() => (
                <div className='manage-table-toolbar'>
                    <h1>{options.title}</h1>
                    {options.downloadButton}
                    <Search
                        placeholder='Search'
                        style={{ marginLeft: 20, width: 300 }}
                        onSearch={options.onSearch}
                        loading={options.loading}
                        allowClear
                    />
                </div>
            )}
            rowKey='id'
            columns={options.columns}
            dataSource={options.dataSource}
            pagination={{
                current: options.page,
                pageSize: options.pageSize,
                position: ['bottomCenter'],
                total: options.total,
            }}
            scroll={{ x: 'max-content' }}
            sticky={{ offsetHeader: 64 }}
            loading={options.loading}
            onChange={options.onChange}
        />
    </div>
);

function Manage({ project }) {
    const user = useSelector(state => state.auth.user);
    const userIsAdmin = userCanAdminProject(user, project?.data);
    const useStations = project?.data?.useStations;

    const stationTableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            ellipsis: true,
        },
    ];

    const observationTableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: text => <Link to={`/observations/${text}`}>{text}</Link>,
        },
        {
            title: 'Name',
            dataIndex: 'station__name',
            ellipsis: true,
        },
        {
            title: 'Date',
            dataIndex: 'collection_date',
            render: text => moment(text).format('MMMM Do YYYY, h:mm:ss a'),
        },
    ];

    const [manageRequestState, manageRequest] = useAsyncFn(async () => {
        const response = await apiRequest.get(manageUrl);
        const payload = response.data;

        // The auth API does not return failure HTTP codes, but instead
        // puts proper HTTP codes in the `status` attribute of the response data
        if (payload.status === 200 && payload.result) {
            return payload.result;
        }
        return payload;
    }, []);

    useEffect(() => {
        if (user) {
            manageRequest();
        }
    }, [user, manageRequest]);

    const observationsTab = {
        observations: {
            title: 'Observations Marked for Review',
            tab: 'Observations',
            type: 'table',
            columns: observationTableColumns,
            dataSource: manageRequestState.value?.untrusted_observations,
        },
    };

    const stationsTab = {
        stations: {
            title: 'Stations Marked for Review',
            tab: 'Stations',
            type: 'table',
            columns: stationTableColumns,
            dataSource: manageRequestState.value?.untrusted_stations,
        },
    };

    const usersTab = { users: ManageUsersTableDefinition(project) };
    const groupsTab = { groups: ManageLocsTableDefinition(project) };

    const defaultTabKey = Object.keys(observationsTab)[0];

    const [activeTabKey, setActiveTabKey] = useState(defaultTabKey);

    if (!project?.data) {
        return null;
    }

    if (!userIsAdmin && !userIsLoc(user, project?.data)) {
        return <div>Oops you are not authorized to see this page.</div>;
    }

    if (manageRequestState.error) {
        return <div>Oops there was an error loading this page.</div>;
    }

    const tabOptions = {
        ...observationsTab,
        ...(useStations ? stationsTab : {}),
        ...usersTab,
        ...(userIsAdmin ? groupsTab : {}),
    };

    const tableOptions = {
        table,
        paginatedTable,
    };

    const opts = tabOptions[activeTabKey];
    const tabContent = tableOptions[opts.type](opts);

    return (
        <>
            <Tabs
                defaultActiveKey={defaultTabKey}
                activeKey={activeTabKey}
                onTabClick={key => setActiveTabKey(key)}
                items={map(tabOptions, ({ tab }, key) => ({ label: tab, key }))}
            />
            {tabContent}
        </>
    );
}

export default Manage;
