import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Radio, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setOptOutDownloadInfo } from '../actions/ui';
import { CSV, XLSX } from '../util/downloadHelper';
import { largeDataThreshold } from '../util/constants';

function ObservationTableDownloadModal({
    downloadCallback,
    filename,
    count,
    countText,
}) {
    const dispatch = useDispatch();
    const { Text } = Typography;
    const [isModalOpen, setisModalOpen] = useState(false);
    const [isInfoModalOpen, setisInfoModalOpen] = useState(false);
    const [fileFormat, setFileFormat] = useState(XLSX);
    const optOutDownloadInfo = useSelector(
        state => state.ui.optOutDownloadInfo
    );

    const showModal = () => {
        setisModalOpen(true);
    };
    const hideModal = () => {
        setisModalOpen(false);
    };
    const showInfoModal = () => {
        setisInfoModalOpen(true);
    };
    const hideInfoModal = () => {
        setisInfoModalOpen(false);
    };

    const handleDownload = () => {
        downloadCallback(fileFormat);
        hideModal();
    };

    const shouldShowInfoModal =
        count > largeDataThreshold && isInfoModalOpen && !optOutDownloadInfo;

    return (
        <>
            <Button type='link' onClick={showModal}>
                <FontAwesomeIcon icon={['far', 'download']} /> Download data
            </Button>
            <Modal
                title='Download data'
                open={isModalOpen}
                onCancel={hideModal} // used by the "X" close button
                onOk={() => {
                    if (!optOutDownloadInfo) showInfoModal();
                    handleDownload();
                }}
                okText={'Download'}
                zIndex={10000}
            >
                <Text className='download-modal-text' strong>
                    Selected data:
                </Text>
                <Text className='download-modal-text'>
                    '{filename}' {countText ? `- ${countText}` : ''}
                </Text>
                {count > 100 ? (
                    <Text className='download-length-warning'>
                        Note: larger files can take some time to prepare.
                    </Text>
                ) : null}
                <Text className='download-modal-text-margin' strong>
                    File type:
                </Text>
                <Radio.Group
                    name='fileFormat'
                    onChange={e => setFileFormat(e.target.value)}
                    value={fileFormat}
                >
                    <Radio value={XLSX}>XLSX</Radio>
                    <Radio value={CSV}>CSV</Radio>
                </Radio.Group>
            </Modal>
            <Modal
                title='Download data'
                open={shouldShowInfoModal}
                onCancel={hideInfoModal} // used by the "X" close button
                cancelText={"Don't show me this again"}
                cancelButtonProps={{
                    onClick: () => {
                        dispatch(setOptOutDownloadInfo(true));
                        hideInfoModal();
                    },
                }}
                onOk={hideInfoModal}
                okText={'Got it'}
                zIndex={10000}
            >
                <Text>
                    You're downloading a large file, this takes some time to
                    prepare.
                    <br />
                    <br />
                    You can follow the progress of file preparation in this
                    bottom left window from any page in Fieldscope. This is also
                    where you can cancel a file or retry in case it fails.
                    <br />
                    <br />
                    While your downloads are in progress, please do not navigate
                    away from Fieldscope or close the tab.
                </Text>
            </Modal>
        </>
    );
}

export default ObservationTableDownloadModal;
