import React, { useState, useRef } from 'react';
import {
    Button,
    Form,
    Layout,
    List,
    message,
    Select,
    Space,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import apiRequest from '../util/apiRequest';
import update from 'immutability-helper';
import { find, findIndex } from 'lodash';

import { setRedirectPath } from '../actions/auth';
import { fetchStations } from '../actions/stations';
import {
    defaultPageSize,
    observationSchemaFolderAddendumForUseStationsUpload,
    observationSchemaFolderAddendumForUpload,
    stationFieldAddendumForUpload,
} from '../util/constants';
import { getCurrentSchema } from '../util/schemaHelper';
import { toUniformString } from '../util/stringHelper';
import {
    tryParseSheet,
    getUploadPayload,
    makeObservationsUseStationsReviewData,
    makeObservationsReviewData,
    makeStationsReviewData,
} from '../util/uploadHelper';
import { formatTableFieldValue } from '../util/fieldFormatter';

const { Content, Sider } = Layout;
const { Paragraph, Text } = Typography;
const { Option } = Select;

function BulkUpload({ useStations }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const schemas = useSelector(state => state.schema);
    const schema = getCurrentSchema(schemas);

    const auth = useSelector(state => state.auth);
    const userId = auth?.user?.userId;

    const [form] = Form.useForm();

    const observationAddendum = useStations
        ? observationSchemaFolderAddendumForUseStationsUpload
        : observationSchemaFolderAddendumForUpload;

    const fields = {
        stations: stationFieldAddendumForUpload.concat(
            schema?.station?.folders[0]?.fields || []
        ),
        observations: observationAddendum
            .concat(schema?.observation?.folders || [])
            .flatMap(f => f.fields),
    };

    const initialFilesState = { observations: null, stations: null };
    const [files, setFiles] = useState(initialFilesState);
    const [reviewData, setReviewData] = useState(null);
    const [committedStationData, setCommittedStationData] = useState(null);
    const [errorData, setErrorData] = useState(null);
    const [uploadId, setUploadId] = useState(null);

    const reset = () => {
        setFiles(initialFilesState);
        setUploadId(null);
        setReviewData(null);
        setCommittedStationData(null);
        setErrorData(null);
    };

    if (!userId) {
        return (
            <Paragraph>
                <Link
                    to='/login'
                    onClick={() =>
                        dispatch(setRedirectPath(history.location.pathname))
                    }
                >
                    Log in
                </Link>{' '}
                to upload stations and observations.
            </Paragraph>
        );
    }

    const makeSetFileHandler = type => file => {
        if (!file) {
            setFiles(update(files, { [type]: { $set: null } }));
            return;
        }
        const reader = new FileReader();
        reader.onload = async e2 => {
            const [headers, body, data] = await tryParseSheet(e2.target.result);

            const uniformHeaders = headers.map(toUniformString);

            const matches = fields[type].map(f => {
                const idx = uniformHeaders.indexOf(toUniformString(f.label));
                if (idx >= 0) {
                    f.matchName = headers[idx];
                    f.matchIndex = idx;
                } else {
                    f.matchName = null;
                    f.matchIndex = null;
                }
                return f;
            });

            setFiles(
                update(files, {
                    [type]: {
                        $set: {
                            file,
                            body: new File([body], file.name),
                            matches,
                            headers,
                            data,
                        },
                    },
                })
            );
        };
        reader.readAsArrayBuffer(file);
    };

    const triggerUpload = () => {
        const payload = getUploadPayload(useStations, schema.key, files);

        apiRequest
            .post('/api/v3/upload', payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                const uploadId = data?.result?.uploadId;

                if (uploadId && uploadId !== 'None') {
                    setUploadId(uploadId);
                    reviewUploadData(uploadId);
                } else {
                    setErrorData(data?.result?.errors || []);
                }
            });
    };

    const reviewUploadData = uploadId =>
        apiRequest.get(`/api/v3/upload/${uploadId}`).then(({ data }) => {
            if (data.status === 200 && data.result) {
                setReviewData(data.result);
            }
        });

    const postUpload = action => {
        const payload = new FormData();
        payload.set('action', action);
        apiRequest
            .post(`/api/v3/upload/${uploadId}`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(({ data }) => {
                if (data.status === 200 && data.result) {
                    if (action === 'cancel') {
                        reset();
                    }
                    if (action === 'commit') {
                        message.success('Bulk upload complete');
                        setReviewData(null);
                        if (files.stations) {
                            dispatch(fetchStations(schema?.key));
                            setCommittedStationData(data.result);
                        } else {
                            // If only observastions were uploaded, the workflow is complete
                            reset();
                        }
                    }
                }
            });
    };

    const updateMapping = (type, fieldIndex, newMatchIndex) => {
        const updateSpec = {
            [fieldIndex]: {
                matchName: {
                    $set: files[type].headers[newMatchIndex],
                },
                matchIndex: { $set: newMatchIndex },
            },
        };
        const existingMatchIndex = findIndex(
            files[type].matches,
            m => m.matchIndex === newMatchIndex
        );
        if (existingMatchIndex >= 0) {
            updateSpec[existingMatchIndex] = {
                $unset: ['matchName', 'matchIndex'],
            };
        }
        setFiles(
            update(files, {
                [type]: {
                    matches: updateSpec,
                },
            })
        );
    };

    return (
        <Form form={form} layout='vertical'>
            <ToolbarSection
                files={files}
                uploadId={uploadId}
                reset={reset}
                triggerUpload={triggerUpload}
                postUpload={postUpload}
                errorData={errorData}
                committedStationData={committedStationData}
            />
            {useStations && (
                <UploadSection
                    type='stations'
                    fields={fields['stations']}
                    file={files['stations']}
                    setFile={makeSetFileHandler('stations')}
                    uploadId={uploadId}
                    tableData={
                        (reviewData && makeStationsReviewData(reviewData)) ||
                        (committedStationData &&
                            makeStationsReviewData(committedStationData))
                    }
                    errorData={errorData?.filter(e => e.type === 'Station')}
                    onMappingChange={({ fieldIndex, newMatchIndex }) => {
                        updateMapping('stations', fieldIndex, newMatchIndex);
                    }}
                />
            )}
            <UploadSection
                type='observations'
                fields={fields['observations']}
                file={files['observations']}
                setFile={makeSetFileHandler('observations')}
                uploadId={uploadId}
                tableData={
                    reviewData &&
                    (useStations
                        ? makeObservationsUseStationsReviewData(reviewData)
                        : makeObservationsReviewData(reviewData))
                }
                errorData={errorData?.filter(e => e.type === 'Observation')}
                onMappingChange={({ fieldIndex, newMatchIndex }) => {
                    updateMapping('observations', fieldIndex, newMatchIndex);
                }}
            />
        </Form>
    );
}

function ToolbarSection({
    files,
    uploadId,
    reset,
    triggerUpload,
    postUpload,
    errorData,
    committedStationData,
}) {
    const everyRequiredFieldMatched = matches =>
        matches.filter(m => m.required).every(m => m.matchIndex !== null);

    // We default to true because it is ok to upload only one of the two files
    const everyRequiredStationFieldMatched = files.stations?.matches
        ? everyRequiredFieldMatched(files.stations.matches)
        : true;

    const everyRequiredObservationFieldMatched = files.observations?.matches
        ? everyRequiredFieldMatched(files.observations.matches)
        : true;

    const selectedFilesHaveUnmatchedRequiredFields =
        !everyRequiredStationFieldMatched ||
        !everyRequiredObservationFieldMatched;

    const noFilesSelected = !files?.stations && !files?.observations;

    return (
        <Form.Item>
            <Space>
                <Button
                    onClick={() => reset()}
                    disabled={!files?.stations && !files?.observations}
                >
                    Clear
                </Button>
                <Button
                    icon={<UploadOutlined />}
                    onClick={() => triggerUpload()}
                    disabled={
                        noFilesSelected ||
                        selectedFilesHaveUnmatchedRequiredFields ||
                        uploadId ||
                        errorData ||
                        committedStationData
                    }
                >
                    Upload
                </Button>
                <Button
                    disabled={!uploadId || committedStationData}
                    onClick={() => postUpload('commit')}
                >
                    Commit
                </Button>
                <Button
                    disabled={!uploadId || committedStationData}
                    onClick={() => postUpload('cancel')}
                >
                    Cancel
                </Button>
                {errorData && (
                    <span style={{ color: 'red' }}>
                        {errorData?.length > 0
                            ? 'Please correct the errors listed below' +
                              ' and try again.'
                            : 'An error prevented the upload from being processed.'}
                    </span>
                )}
            </Space>
        </Form.Item>
    );
}

function UploadSection({
    type,
    fields,
    file,
    setFile,
    uploadId,
    tableData,
    errorData,
    onMappingChange,
}) {
    const fileRef = useRef(null);
    const [tablePageSize, setTablePageSize] = useState(defaultPageSize);
    const [tablePage, setTablePage] = useState(1);

    const onFileSelect = e => {
        const file = e.target.files[0] || null;
        if (!file) {
            return;
        }
        setFile(file);
    };

    if (fileRef?.current && !file?.file) {
        fileRef.current.value = '';
    }

    const cleared = !uploadId && !errorData;
    const hasData = uploadId && tableData?.length > 0;
    const hasErrors = !uploadId && errorData?.length > 0;

    const existingDataLabel =
        type === 'stations'
            ? 'Existing stations matching the uploaded observations'
            : '';

    return cleared || hasData || hasErrors ? (
        <div className='observation__section' style={{ paddingTop: '26px' }}>
            <Text
                className='observation__section--title'
                style={{ textTransform: 'capitalize' }}
            >
                {type}
            </Text>

            <Form.Item>
                {!uploadId && !errorData ? (
                    <Button onClick={() => fileRef.current.click()}>
                        {file
                            ? file.file.name
                            : `Select CSV / Excel File for ${type}`}
                    </Button>
                ) : (
                    <div>{file?.file?.name || existingDataLabel}</div>
                )}
                <input
                    type='file'
                    id={`${type}file`}
                    accept='.xls,.xlsx,.xlsb,.csv'
                    style={{ display: 'none' }}
                    ref={fileRef}
                    onChange={onFileSelect}
                />
            </Form.Item>

            {/* Header Match Report */}
            {type && file && !uploadId && !errorData && (
                <Form.Item>
                    {file.matches.map((sm, fieldIndex) => {
                        const icon = sm.matchName
                            ? '✅' // Matched
                            : sm.required
                            ? '❌' // Required, not matched
                            : '✖️'; // Not required, not matched
                        const select = (
                            <Select
                                value={sm.matchIndex}
                                style={{ width: '200px' }}
                                onChange={value =>
                                    onMappingChange({
                                        fieldIndex,
                                        newMatchIndex: value,
                                    })
                                }
                            >
                                <Option value={null}></Option>
                                {file.headers.map((header, i) => (
                                    <Option value={i} key={i}>
                                        {header}
                                    </Option>
                                ))}
                            </Select>
                        );
                        return (
                            <Paragraph key={sm.name}>
                                {icon} {sm.label} ↔ {select}
                            </Paragraph>
                        );
                    })}
                </Form.Item>
            )}

            {/* Data Review Table */}
            {tableData?.length > 0 ? (
                <Table
                    bordered
                    className='observation-table'
                    scroll={{ x: 'max-content' }}
                    sticky={{ offsetHeader: 64 }}
                    showHeader={tableData?.length > 0}
                    columns={fields.map(f => ({
                        title: f.label,
                        dataIndex: f.name,
                        key: f.name,
                        render: (text, _, index) => {
                            return formatTableFieldValue(f, text);
                        },
                    }))}
                    dataSource={tableData}
                ></Table>
            ) : null}

            {errorData && (
                <Layout>
                    <Sider>
                        <List
                            itemLayout='horizontal'
                            dataSource={errorData}
                            renderItem={item => (
                                <List.Item key={`${item.row}|${item.column}`}>
                                    <List.Item.Meta
                                        title={`Row ${item.row + 1} Column ${
                                            item.fieldName
                                        }`}
                                        description={
                                            <Text style={{ color: 'red' }}>
                                                {item.description}
                                            </Text>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Sider>
                    <Content>
                        <Table
                            bordered
                            className='observation-table'
                            scroll={{ x: 'max-content' }}
                            sticky={{ offsetHeader: 64 }}
                            showHeader={file?.data.length > 0}
                            columns={
                                file &&
                                file.matches.map(match => ({
                                    title: match.matchName,
                                    dataIndex: match.matchName,
                                    key: match.matchName,
                                    render: (text, _, index) => {
                                        // The index value refers to the row number within the (paginated)
                                        // table, so it will only ever range from 0 to tablePageSize - 1.
                                        // This math allows us to match the paginated index to the overall
                                        // row number within the error data
                                        const totalRowNumber =
                                            tablePageSize * (tablePage - 1) +
                                            index;
                                        const error = find(errorData, {
                                            fieldName: match.matchName,
                                            row: totalRowNumber,
                                        });
                                        return error
                                            ? {
                                                  children: (
                                                      <Tooltip
                                                          title={
                                                              error.description
                                                          }
                                                      >
                                                          <Text
                                                              style={{
                                                                  color: 'red',
                                                                  fontWeight:
                                                                      'bold',
                                                                  cursor:
                                                                      'help',
                                                              }}
                                                          >
                                                              {text}
                                                          </Text>
                                                      </Tooltip>
                                                  ),
                                              }
                                            : text;
                                    },
                                }))
                            }
                            dataSource={file?.data}
                            pagination={{
                                defaultCurrent: 1,
                                onChange: (page, pageSize) => {
                                    setTablePage(page);
                                    setTablePageSize(pageSize);
                                },
                            }}
                        ></Table>
                    </Content>
                </Layout>
            )}
        </div>
    ) : null;
}

export default BulkUpload;
