import cloneDeep from 'lodash/cloneDeep';
import {
    pluckFieldNamesFromSchema,
    getSynthesizedFieldNames,
} from './schemaHelper';
import {
    observationManagerFieldsSchemaFolder,
    stationManagerFieldsSchemaFolder,
} from '../util/constants';

/* Query generators */

export const createObservationsQuery = (schema, options) => {
    const queryOptions = options ? cloneDeep(options) : {};
    const isManager = !!queryOptions.isManager;
    delete queryOptions.isManager;
    const useStations = !!queryOptions.useStations;
    delete queryOptions.useStations;

    const fieldNames = getObservationTableFieldsFromProjectDataSchema(
        schema,
        isManager,
        useStations
    );
    const defaults = {
        wkid: 4326,
        fields: fieldNames,
        displayFields: fieldNames,
    };

    return Object.assign({}, defaults, queryOptions);
};

export const createDefaulVisualizationQuery = (schema, options) => {
    return createObservationsQuery(
        schema,
        Object.assign({}, allOptions, options)
    );
};

/* Helpers */

export const getObservationTableFieldsFromProjectDataSchema = (
    schema,
    isManager,
    useStations
) => {
    return [
        ...getSynthesizedFieldNames(schema),
        ...getFieldsFromProjectDataSchema(schema),
        ...(isManager
            ? useStations
                ? observationManagerFieldsSchemaFolder.fields
                      .map(x => x.name)
                      .concat(
                          stationManagerFieldsSchemaFolder.fields.map(
                              x => x.name
                          )
                      )
                : observationManagerFieldsSchemaFolder.fields.map(x => x.name)
            : []),
    ];
};

export const getFieldsFromProjectDataSchema = schema => {
    const { station, observation } = schema;
    return [
        ...pluckFieldNamesFromSchema(station),
        ...pluckFieldNamesFromSchema(observation),
    ];
};

/* Filters */

export const userOptions = userId => ({
    label: 'My observations',
    filters: [
        'and',
        {
            enabled: true,
            oneof: [userId],
            field: 'user',
            label: 'My observations',
        },
    ],
});

export const recentOptions = {
    label: 'Recent observations',
    orderby: '-collection_date',
    limit: 25,
};

export const groupOptions = ({ loc_field_values, loc_field }) => ({
    label: 'My Group',
    filters: [
        'and',
        {
            enabled: true,
            oneof: loc_field_values,
            field: loc_field,
            label: 'My Group',
        },
    ],
});

export const allOptions = {
    label: 'All observations',
    limit: 1000,
};
