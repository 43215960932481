import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function ReviewModal({ submitCallback }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [comment, setComment] = useState(null);
    const { TextArea } = Input;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const hideModal = () => {
        setComment(null);
        setIsModalOpen(false);
    };

    const handleSubmit = () => {
        submitCallback(comment);
        hideModal();
    };

    return (
        <>
            <Button
                onClick={showModal}
                size='small'
                icon={<FontAwesomeIcon icon={['far', 'exclamation-circle']} />}
            >
                Review
            </Button>
            <Modal
                title='Review'
                open={isModalOpen}
                onCancel={hideModal} // used by the "X" close button
                onOk={handleSubmit}
                okText={'Submit'}
                zIndex={10000}
            >
                Leave a note for the project managers on why this data needs to
                be reviewed.
                <TextArea
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    allowClear
                    autoSize={{ minRows: 4, maxRows: 10 }}
                    // Override style preventing the modal from expanding to fit
                    // the TextArea
                    style={{ maxHeight: 'initial' }}
                />
            </Modal>
        </>
    );
}

export default ReviewModal;
