import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, message } from 'antd';
import update from 'immutability-helper';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { closestZoom } from '../util/constants';
import Geocoder from './Geocoder';
import WidgetToolbar from './WidgetToolbar';

const MIN_ZOOM_LEVEL = 0;
const MAX_ZOOM_LEVEL = 15;

const LocationToolbar = ({
    viewport,
    setViewport,
    geolocationStatus,
    setGeolocationStatus,
    onCenterChange,
}) => {
    const project = useSelector(state => state.project);

    // store a state variable to track whether location services are permitted
    const [locationPermission, setLocationPermission] = useState(false);

    useEffectOnce(() => {
        if ('permissions' in navigator) {
            navigator.permissions
                .query({ name: 'geolocation' })
                .then(result => {
                    setLocationPermission(
                        ['granted', 'prompt'].includes(result.state)
                    );
                });
        } else {
            // safari doesn't allow you to query navigator.permissions
            // so upon loading we just need to make an attempt to get
            // location in order to determine if it is enabled
            navigator.geolocation.getCurrentPosition(
                // currently this step is causing an error on development related
                // to a bad http request. This it not an issue on staging or production
                // for more context see this comment:
                // https://github.com/azavea/fieldscope/pull/1232#issuecomment-1181206116
                _ => setLocationPermission(true), // onSuccess
                e => {
                    // onError
                    if (e.code === e.PERMISSION_DENIED) {
                        setLocationPermission(false);
                    } else {
                        setLocationPermission(true);
                    }
                },
                // Timing out quickly ensures that we don't waste time trying
                // to actually load the location since we don't need it anyway
                { timeout: 1 }
            );
        }
    });

    const incrementZoom = z => Math.min(MAX_ZOOM_LEVEL, z + 1);
    const decrementZoom = z => Math.max(MIN_ZOOM_LEVEL, z - 1);

    const zoomIn = () =>
        setViewport(
            update(viewport, {
                zoom: { $set: incrementZoom(viewport.zoom) },
            })
        );

    const zoomOut = () =>
        setViewport(
            update(viewport, {
                zoom: { $set: decrementZoom(viewport.zoom) },
            })
        );

    const onGeocode = ({ latitude, longitude }) => {
        setViewport(
            update(viewport, {
                latitude: { $set: latitude },
                longitude: { $set: longitude },
                zoom: { $set: closestZoom },
            })
        );
        onCenterChange({ latitude, longitude });
    };

    const geolocate = () => {
        setGeolocationStatus({ loading: true, error: null });

        const onGeolocationSuccess = geolocationPosition => {
            setGeolocationStatus({ loading: false, error: null });
            const { latitude, longitude } = geolocationPosition.coords;
            onGeocode({ latitude, longitude });
        };

        const onGeolocationError = error => {
            setGeolocationStatus({ loading: false, error });
            message.error(`Unable to use your location: ${error.message}`);
        };

        const geolocationOptions = {
            timeout: 10000, // geolocation will time out after ten seconds
            maximumAge: 300000, // return a cached geolocation that is up to 5 min old
            enableHighAccuracy: false, // sacrifice most accurate position for better performance
        };

        navigator.geolocation.getCurrentPosition(
            onGeolocationSuccess,
            onGeolocationError,
            geolocationOptions
        );
    };

    const MapLeftActions = [
        <Button
            type='link'
            icon={<FontAwesomeIcon icon={['far', 'plus']} />}
            disabled={viewport.zoom >= MAX_ZOOM_LEVEL}
            onClick={zoomIn}
        ></Button>,
        <Button
            type='link'
            icon={<FontAwesomeIcon icon={['far', 'minus']} />}
            disabled={viewport.zoom <= MIN_ZOOM_LEVEL}
            onClick={zoomOut}
        ></Button>,
        <Divider type='vertical' />,
        <Button
            type='link'
            icon={<FontAwesomeIcon icon={['far', 'location']} />}
            disabled={
                geolocationStatus.error ||
                geolocationStatus.loading ||
                !locationPermission
            }
            loading={geolocationStatus.loading}
            onClick={geolocate}
        ></Button>,
        <Divider type='vertical' />,
        <Geocoder onGeocode={onGeocode} dataExtent={project.data.dataExtent} />,
    ];

    return <WidgetToolbar left={MapLeftActions} />;
};

export default LocationToolbar;
