import React from 'react';
import {
    Form,
    Input,
    InputNumber,
    Select,
    TimePicker,
    Tooltip,
    DatePicker,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import * as a from 'indefinite';
import { isNil, isNumber } from 'lodash';

import { dateFormat, timeFormat, dateTimeFormat } from '../util/constants';
import { filterOption } from '../util/stringHelper';
import {
    createUnitsSelectOptions,
    getValidUnits,
    convertUnit,
    formatUnit,
} from '../util/units';
import { precisionToStep } from '../util/fieldFormatter';

/*
A partial form component for rendering inputs dynamically from a form schema
Parent component must be an antd form object
*/

const createSelectOptions = valuesArray =>
    valuesArray.map(v => {
        const { value, label } = v;
        return (
            <Select.Option key={value} value={value}>
                {label}
            </Select.Option>
        );
    });

const createInputFromFieldSchema = (
    {
        entryType,
        values,
        type,
        minimum,
        maximum,
        precision,
        label,
        units,
        unitsAvailable,
        required,
        name,
    },
    useExpandingInputs,
    set
) => {
    if (type === 'String') {
        if (values) {
            // Choice field
            const selectOptions = createSelectOptions(values);
            return (
                <Select
                    placeholder='Select one'
                    /*
                    Selects also accept a search string
                    Search the display label name instead of the programmatic value, which is more intuitive to the user
                    */
                    showSearch
                    autoComplete={'chrome-ignore'}
                    filterOption={filterOption}
                >
                    {selectOptions}
                </Select>
            );
        } else if (entryType === 'LongForm') {
            // Text area
            return <Input.TextArea autoSize={{ maxRows: 10 }} allowClear />;
        } else if (label.toLowerCase().includes('password')) {
            // Password input
            return <Input.Password />;
        } else {
            // Vanilla free-form string, auto expanding text area
            return useExpandingInputs ? (
                <Input.TextArea autoSize={{ maxRows: 10 }} />
            ) : (
                <Input />
            );
        }
    } else if (type === 'Number') {
        // Number input, agnostic to aggregate type (e.g. count, average, sum)
        // Filter out unrecognized units
        const validUnitsAvailable = getValidUnits(unitsAvailable);
        if (validUnitsAvailable.length) {
            const numberInput = (
                <InputNumber step={precisionToStep(precision)} />
            );

            const selectOptions = createSelectOptions(
                createUnitsSelectOptions(validUnitsAvailable)
            );
            const unitSelector = <Select>{selectOptions}</Select>;

            return (
                <Input.Group compact>
                    <Form.Item
                        name={[name, 'value']}
                        rules={[
                            {
                                required,
                                message: `Please enter ${a(
                                    label.toLowerCase()
                                )}`,
                            },
                            // custom min/max input validation is needed for fields that accept multiple units
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!isNumber(value)) {
                                        return Promise.resolve();
                                    }
                                    if (
                                        !isNil(precision) &&
                                        value.toFixed(precision).length <
                                            value.toString().length
                                    ) {
                                        return Promise.reject(
                                            `Values must have exactly ${precision} digits of precision`
                                        );
                                    }
                                    const fromUnit = getFieldValue(name).unit;
                                    if (isNumber(maximum)) {
                                        const convertedMax = convertUnit(
                                            maximum,
                                            units,
                                            fromUnit,
                                            precision
                                        );
                                        if (value > convertedMax) {
                                            return Promise.reject(
                                                `The value cannot be above ${convertedMax} ${fromUnit}`
                                            );
                                        }
                                    }
                                    if (isNumber(minimum)) {
                                        const convertedMin = convertUnit(
                                            minimum,
                                            units,
                                            fromUnit,
                                            precision
                                        );
                                        if (value < convertedMin) {
                                            return Promise.reject(
                                                `The value cannot be below ${convertedMin} ${fromUnit}`
                                            );
                                        }
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        {numberInput}
                    </Form.Item>
                    <Form.Item
                        name={[name, 'unit']}
                        rules={[
                            {
                                required,
                                message: `Please enter ${a(
                                    label.toLowerCase()
                                )}`,
                            },
                        ]}
                    >
                        {unitSelector}
                    </Form.Item>
                </Input.Group>
            );
        }

        return (
            <Form.Item
                name={set ? [set.name, name] : name}
                rules={[
                    {
                        validator: (rule, value) => {
                            // Allow values to be blank. Blank inputs can be defined as either
                            // undefined or null
                            if (
                                value === undefined ||
                                value === null ||
                                value === ''
                            ) {
                                return Promise.resolve();
                            }

                            const floatValue = parseFloat(value);
                            // parseFloat ignores non-number characters if
                            // number characters are at the start of a string.
                            // But we want to make sure that non number
                            // characters are not submitted to the database, so
                            // we also check the input's value with isNaN
                            if (isNaN(floatValue) || isNaN(value)) {
                                return Promise.reject(
                                    `The value must be a number.`
                                );
                            }
                            if (isNumber(maximum) && floatValue > maximum) {
                                return Promise.reject(
                                    `The value cannot be above ${maximum}`
                                );
                            }
                            if (isNumber(minimum) && floatValue < minimum) {
                                return Promise.reject(
                                    `The value cannot be below ${minimum}`
                                );
                            }
                            // Although the incoming value is a string here, just confirm that the
                            // parsed numeric version has the right precision; this matches the behavior of the
                            // validation for fields with units, above, and also avoids yelling at the user if
                            // they enter trailing zeroes that won't actually impact the final value.
                            if (
                                !isNil(precision) &&
                                floatValue.toFixed(precision).length <
                                    floatValue.toString().length
                            ) {
                                return Promise.reject(
                                    `Values must have exactly ${precision} digits of precision`
                                );
                            }

                            return Promise.resolve();
                        },
                    },
                ]}
            >
                {/* This is a workaround for a bug/limitation of Antd's number input. If
                these components have a precision prop > 0, it impossible for the user to
                reset an input value to be blank after entering it. By passing a step value
                that corresponds to the desired precision, we the correct number of decimal
                places and the ability to reset the value to null. */}
                <InputNumber step={precisionToStep(precision)} />
            </Form.Item>
        );
    } else if (type === 'Date') {
        // Date selector
        return (
            <DatePicker format={date => date.utc(true).format(dateFormat)} />
        );
    } else if (type === 'Time') {
        return <TimePicker use12Hours format={timeFormat} />;
    } else if (type === 'DateTime') {
        return (
            <DatePicker
                showTime
                showSecond={false}
                format={datetime => datetime.utc(true).format(dateTimeFormat)}
            />
        );
    }
};

/**
 * Given an array of `fields` for a FieldScope folder, generates an array of
 * `Form.Item`s with appropriate input components.
 *
 * When generating a dynamic group of fields, where a fieldset may be added
 * or removed at runtime, the `set` parameter is used to track individual
 * instances in the group.
 *
 * @param { fields } Array of fields for a folder. Usually of the structure:
 *                   { label, required, name, description, ... }. Required.
 * @param { set }    Object of Form.List. Of the structure:
 *                   { name: 0, key: 0, fieldKey: 0 }. Optional.
 * @param { indicateRequired } Bool controlling the presence of * next to the
 *                             label of required fields. Optional. Default false.
 */
function SchemaFormFields({
    fields,
    set,
    indicateRequired = false,
    useExpandingInputs = false,
}) {
    const formFields = fields.map(field => {
        const {
            label,
            required,
            name,
            description,
            units,
            unitsAvailable,
        } = field;
        const itemName = set ? [set.name, name] : name;
        const fieldKey = set ? [set.fieldKey, name] : null;

        const input = createInputFromFieldSchema(
            field,
            useExpandingInputs,
            set
        );

        const unitLabel = units && !getValidUnits(unitsAvailable).length && (
            <span className='form-field-unit'>{formatUnit(units)}</span>
        );
        const requiredLabel = required && indicateRequired ? '*' : '';

        // Some fields have long descriptive content, add it to an
        // icon-triggered tooltip on the label.
        const helpContent = description ? (
            <Tooltip title={description}>
                <QuestionCircleOutlined />
            </Tooltip>
        ) : null;

        return (
            <Form.Item key={name}>
                <label htmlFor={itemName}>
                    {label} {requiredLabel} {helpContent}
                </label>
                <div className='form-field'>
                    <Form.Item
                        name={itemName}
                        fieldKey={fieldKey}
                        hasFeedback
                        rules={[
                            {
                                required,
                                message: `Please enter ${a(
                                    label.toLowerCase()
                                )}`,
                            },
                        ]}
                        noStyle
                    >
                        {input}
                    </Form.Item>
                    {unitLabel}
                </div>
            </Form.Item>
        );
    });

    return formFields;
}

export default SchemaFormFields;
