import React from 'react';
import { Card, List, Tabs, Tooltip, Typography } from 'antd';
import { array, func } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import NewTabLink from './NewTabLink';
import { mediaType } from '../util/propTypes';
import { makeMediaDict } from '../util/mediaHelper';
import { pauseAllMediaTags } from '../util/domHelper';
import { mediaTypes, mediaCategoryNames } from '../util/constants';

const { Meta } = Card;
const { Paragraph } = Typography;

const makeMediaTabPane = (key, dataSource, renderItem) => {
    return {
        label: mediaCategoryNames[key],
        key: key,
        children: (
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 3,
                    xl: 4,
                    xxl: 6,
                }}
                dataSource={dataSource}
                renderItem={renderItem}
            />
        ),
    };
};

const makeDownloadLink = url => (
    <NewTabLink key={url} href={url}>
        <Tooltip title='Download'>
            <FontAwesomeIcon icon={['far', 'download']} />
        </Tooltip>
    </NewTabLink>
);

const makeTabChangeHandler = onTypeChange => e => {
    pauseAllMediaTags();
    onTypeChange(e);
};

const renderPhotoItem = item => (
    <List.Item key={item.url} className='observation-modal__media'>
        <Card
            key={item.photoId}
            cover={<img alt={item.description} src={item.url}></img>}
            title={
                <Tooltip title={item.label} trigger={['hover', 'click']}>
                    {item.label}
                </Tooltip>
            }
            extra={[makeDownloadLink(item.url)]}
        >
            {item.description?.length ? (
                <Meta
                    description={
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                expandable: true,
                            }}
                        >
                            {item.description}
                        </Paragraph>
                    }
                />
            ) : null}
        </Card>
    </List.Item>
);

const renderVideoItem = item => (
    <List.Item key={item.url} className='observation-modal__media'>
        <Card
            cover={
                <video height='100%' controls>
                    <source src={item.url} />
                </video>
            }
            title={
                <Tooltip title={item.label} trigger={['hover', 'click']}>
                    {item.label}
                </Tooltip>
            }
            extra={[makeDownloadLink(item.url)]}
        >
            {item.description?.length ? (
                <Meta
                    description={
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                expandable: true,
                            }}
                        >
                            {item.description}
                        </Paragraph>
                    }
                />
            ) : null}
        </Card>
    </List.Item>
);

const renderAudioItem = item => (
    <List.Item key={item.url} className='observation-modal__media'>
        <Card
            cover={
                <audio controls>
                    <source src={item.url} />
                </audio>
            }
            title={
                <Tooltip title={item.label} trigger={['hover', 'click']}>
                    {item.label}
                </Tooltip>
            }
            extra={[makeDownloadLink(item.url)]}
        >
            {item.description?.length ? (
                <Meta
                    description={
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                expandable: true,
                            }}
                        >
                            {item.description}
                        </Paragraph>
                    }
                />
            ) : null}
        </Card>
    </List.Item>
);

const renderDocumentItem = item => (
    <List.Item key={item.url} className='observation-modal__media'>
        <Card
            title={
                <Tooltip title={item.label} trigger={['hover', 'click']}>
                    {item.label}
                </Tooltip>
            }
            extra={[makeDownloadLink(item.url)]}
        >
            {item.description?.length ? (
                <Meta
                    description={
                        <Paragraph
                            ellipsis={{
                                rows: 2,
                                expandable: true,
                            }}
                        >
                            {item.description}
                        </Paragraph>
                    }
                />
            ) : null}
        </Card>
    </List.Item>
);

const MediaBrowser = ({ observations, activeType, onTypeChange }) => {
    const { photos, videos, audio, documents } = makeMediaDict(observations);
    return (
        <Tabs
            activeKey={activeType}
            onChange={makeTabChangeHandler(onTypeChange)}
            items={[
                photos?.length &&
                    makeMediaTabPane(mediaTypes.photo, photos, renderPhotoItem),
                videos?.length &&
                    makeMediaTabPane(mediaTypes.video, videos, renderVideoItem),
                audio?.length &&
                    makeMediaTabPane(mediaTypes.audio, audio, renderAudioItem),
                documents?.length &&
                    makeMediaTabPane(
                        mediaTypes.document,
                        documents,
                        renderDocumentItem
                    ),
            ]}
        />
    );
};

MediaBrowser.propTypes = {
    observations: array.isRequired,
    activeType: mediaType,
    onTypeChange: func.isRequired,
};

export default MediaBrowser;
