import { createAction } from 'redux-act';
import apiRequest from '../util/apiRequest';
import { pick } from 'lodash';

import {
    makeQueryUrl,
    makeQueryManagerFieldsUrl,
    hardcodedMyOfflineFilterSetName,
    filterSetDefaultResultLimit,
} from '../util/constants';
import { logErrorAndDispatchFailure, dispatchApiCompleteOrFail } from '../util';

export const startFetchFilterSet = createAction('Start fetching filter set');
export const completeFetchFilterSet = createAction('Complete fetch filter set');
export const failFetchFilterSet = createAction('Fail fetching filter set');
export const setFilterSetStale = createAction('Set filter set stale');
export const setActiveFilterSetName = createAction(
    'Set active filter set name'
);
export const setIsTableVisible = createAction('Set table visibility');
export const setTableData = createAction('Set table data by filterSet');
export const setTablePage = createAction('Set table page by filterSet');
export const setTablePageSize = createAction(
    'Set table page size by filterSet'
);
export const setSelectedObservations = createAction(
    'Set selected observations by filterSet'
);
export const updateObservations = createAction('Update observations');
export const updateVisualizationFilterSetDisplayFields = createAction(
    'Update visualization filter set display fields'
);
export const updateVisualizationFilterSetFieldOrder = createAction(
    'Update visualization filter set field order'
);
export const clearFilterSetData = createAction('Clear filter set data');
export const copyFilterSetData = createAction(
    'Copy filterSets from one visualization to another'
);

export const startFetchFilterSetManagerFields = createAction(
    'Start fetching filter set manager fields'
);
export const completeFetchFilterSetManagerFields = createAction(
    'Complete fetch filter set manager fields'
);
export const failFetchFilterSetManagerFields = createAction(
    'Fail fetching filter set manager fields'
);

export function fetchFilterSet(filterSet, url) {
    const { filterSetName, filters, schemaName } = filterSet;

    return (dispatch, getState) => {
        const {
            stations: { data: stationList },
            auth: { user },
        } = getState();

        const isOfflineFilterSet =
            filterSetName === hardcodedMyOfflineFilterSetName;
        const isAuthenticated = !!user?.userId;

        if (isOfflineFilterSet && !stationList) return;

        dispatch(startFetchFilterSet({ filterSetName, filters }));

        // The `filters` property of a `filterSet` object may contain additional fields
        // that are ignored by the API so we remove them before submitting
        const pickedProperties = pick(filters, [
            'fields',
            'filters',
            'limit',
            'offset',
            'orderby',
            'query',
            'stations',
            'wkid',
        ]);

        // Set the limit to the default, but override with any limit set in filters
        const pickedPropertiesWithLimit = Object.assign(
            { limit: filterSetDefaultResultLimit },
            pickedProperties
        );

        const query =
            isOfflineFilterSet && isAuthenticated
                ? apiRequest.get(url)
                : apiRequest.post(
                      makeQueryUrl(schemaName, url),
                      pickedPropertiesWithLimit
                  );

        return query
            .then(({ data }) => {
                // offline observation data are returned without stations
                // they need to be matched to and nested under their station
                let result = data.result;

                if (isOfflineFilterSet) {
                    // remove duplicate station ids
                    const stationIds = Array.from(
                        new Set(result?.map(o => o.stationId))
                    );

                    // match and nest fetched observations to stations
                    result = stationIds.reduce((acc, stationId) => {
                        const station = stationList.find(
                            s => s.stationId === stationId
                        );

                        // its possible station data gets dropped before
                        // offline data is committed
                        // don't show orphan observations without stations
                        if (!station) return acc;

                        const userOwnedObservations = result.filter(
                            o => o.ownerId === user.userId
                        );
                        station.observations = userOwnedObservations.filter(
                            o => o.stationId === stationId
                        );
                        acc.push(station);
                        return acc;
                    }, []);
                }

                // FilterSet names are dynamic. Include the filterset name
                // on the data payload to the reducer so that it can find the
                // appropriate key to update.
                const payload = Object.assign(data, {
                    result: {
                        result,
                        filterSetName,
                    },
                });

                return dispatchApiCompleteOrFail(
                    dispatch,
                    completeFetchFilterSet,
                    failFetchFilterSet,
                    payload
                );
            })
            .catch(e =>
                dispatch(
                    logErrorAndDispatchFailure(
                        e,
                        `An error occurred when fetching results for filterSet ${filterSetName}`,
                        failFetchFilterSet
                    )
                )
            );
    };
}

export function fetchFilterSetManagerFields(filterSet, url) {
    const { filterSetName, filters, schemaName } = filterSet;

    return dispatch => {
        const isOfflineFilterSet =
            filterSetName === hardcodedMyOfflineFilterSetName;

        // offline data has no manager fields to fetch
        if (isOfflineFilterSet) return;

        dispatch(startFetchFilterSetManagerFields({ filterSetName, filters }));
        return apiRequest
            .post(makeQueryManagerFieldsUrl(schemaName, url), filters)
            .then(({ data }) => {
                // FilterSet names are dynamic. Include the filterset name
                // on the data payload to the reducer so that it can find the
                // appropriate key to update.
                const payload = Object.assign(data, {
                    result: {
                        result: data.result,
                        filterSetName,
                    },
                });

                return dispatchApiCompleteOrFail(
                    dispatch,
                    completeFetchFilterSetManagerFields,
                    failFetchFilterSetManagerFields,
                    payload
                );
            })
            .catch(e => {
                return dispatch(
                    logErrorAndDispatchFailure(
                        e,
                        `An error occurred when fetching manager fields for filterSet ${filterSetName}`,
                        failFetchFilterSetManagerFields
                    )
                );
            });
    };
}
