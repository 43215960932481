import React from 'react';
import { Row, Col, Typography, Statistic } from 'antd';
import { string } from 'prop-types';
import { isFinite } from 'lodash';

import ColoredCard from './ColoredCard';

import { project } from '../util/propTypes';

const { Title } = Typography;

const numberFormat = new Intl.NumberFormat();

const HomeHero = ({ project, label }) => {
    // `background` is an attribute of the existing Project
    // model that we are repurposing for our "hero" image.
    const {
        background: heroImageSrc,
        observationCount,
        userCount,
        shortDescription,
        showContributors,
        showObservations,
        label: title,
    } = project.data;

    const shortDescriptionElement = (
        // The value of `shortDescription` is only set by super users via the Django
        // admin so it is safe to render the markup here.
        <span dangerouslySetInnerHTML={{ __html: shortDescription }} />
    );

    return (
        <>
            <Row className='home-hero' align='middle'>
                <Col flex='3'>
                    <div
                        className='home-hero__image'
                        style={{ backgroundImage: `url(${heroImageSrc})` }}
                    ></div>
                </Col>
                <Col flex='2' className='home-hero__message'>
                    <Title>{title}</Title>
                    {shortDescriptionElement}
                </Col>
            </Row>
            <Row
                align='top'
                justify='center'
                gutter={[16, 16]}
                className='home-stats'
            >
                {showObservations && isFinite(observationCount) && (
                    <Col lg={{ span: 8 }} md={{ span: 12 }}>
                        <ColoredCard
                            title='Total observations'
                            className='home-hero__card'
                        >
                            <Statistic
                                value={numberFormat.format(observationCount)}
                                valueStyle={{ fontSize: '38px' }}
                            />
                        </ColoredCard>
                    </Col>
                )}
                {showContributors && isFinite(userCount) && (
                    <Col lg={{ span: 8 }} md={{ span: 12 }}>
                        <ColoredCard
                            title='Total contributors'
                            className='home-hero__card'
                        >
                            <Statistic
                                value={numberFormat.format(userCount)}
                                valueStyle={{ fontSize: '38px' }}
                            />
                        </ColoredCard>
                    </Col>
                )}
            </Row>
        </>
    );
};

HomeHero.propTypes = {
    project: project.isRequired,
    label: string,
};

export default HomeHero;
