import apiRequest from '../util/apiRequest';
import React, { useState } from 'react';
import { useAsyncFn } from 'react-use';
import { Form, Button, Alert } from 'antd';
import {
    authPasswordResetUrl,
    passwordResetFormSchema,
} from '../util/constants';

import SchemaFormFields from './SchemaFormFields';

function AuthPasswordResetRequestForm() {
    const [alertMessage, setAlertMessage] = useState(null);

    const [passwordResetRequestState, passwordResetRequest] = useAsyncFn(
        async values => {
            const fieldNames = passwordResetFormSchema.map(field => field.name);

            const formData = new FormData();
            fieldNames.forEach(name => formData.set(name, values[name]));

            const response = await apiRequest.post(
                authPasswordResetUrl,
                formData
            );
            const payload = response.data;
            if (payload.status === 200) {
                setAlertMessage(payload.result || payload.error);
            } else {
                setAlertMessage(
                    'An error has occurred. Please try again or contact an administrator.'
                );
            }

            return payload;
        },
        []
    );

    const handleFinish = values => {
        setAlertMessage(null);
        passwordResetRequest(values);
    };

    const alert = alertMessage && (
        <Alert
            message={alertMessage}
            type='info'
            showIcon
            closable
            style={{ margin: '10px 0' }}
        />
    );

    const passwordResetForm = (
        <>
            {alert}
            <Form onFinish={handleFinish} className='login-form'>
                <SchemaFormFields fields={passwordResetFormSchema} />
                <Form.Item className='auth-form__buttongroup'>
                    <Button
                        type='primary'
                        htmlType='submit'
                        className='auth-form-button'
                        loading={passwordResetRequestState.loading}
                    >
                        Send password reset email
                    </Button>
                </Form.Item>
            </Form>
        </>
    );

    return passwordResetForm;
}

export default AuthPasswordResetRequestForm;
