import React from 'react';
import { BaseControl } from 'react-map-gl';
import { array } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';

import MapLegend from './MapLegend';

// We use a class-based component here so that we can override the `BaseControl`
// provided by react-map-gl, which handles intercepting click events.
class ObservationMapLegend extends BaseControl {
    _render() {
        const {
            activeDataLayers,
            legendValues,
            stationLegendTitle,
        } = this.props;
        return (
            <div
                ref={this._containerRef}
                className='mapboxgl-ctrl mapboxgl-ctrl-group '
            >
                <Popover
                    content={
                        <MapLegend
                            activeDataLayers={activeDataLayers || []}
                            legendValues={legendValues}
                            stationLegendTitle={stationLegendTitle}
                        />
                    }
                    trigger='click'
                    placement='bottomRight'
                >
                    <Button size='large' title='Legend'>
                        <FontAwesomeIcon icon={['far', 'list']} />
                    </Button>
                </Popover>
            </div>
        );
    }
}

ObservationMapLegend.propTypes = {
    activeDataLayers: array,
};

ObservationMapLegend.defaultProps = {
    activeDataLayers: [],
};

export default ObservationMapLegend;
