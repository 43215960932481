// Helper functions for creating an updating user profiles
export const flattenUserObjectWithHdata = (user, formSchema) => {
    if (user) {
        let initialValues = {};
        for (const formField of formSchema) {
            const fieldName = formField.name;
            if (fieldName in user) {
                initialValues[fieldName] = user[fieldName];
            } else if (fieldName in user.data) {
                initialValues[fieldName] = user.data[fieldName];
            }
        }

        return initialValues;
    } else {
        return {};
    }
};

export const formValuesToUserRequestPayload = (
    fieldNames,
    formValues,
    userProfile
) => {
    const formFields = fieldNames
        .map(field => {
            const fieldValue = formValues[field];
            return `${field}=${fieldValue ? fieldValue : ''}`;
        })
        // remove empty fields
        .filter(field => !field.endsWith('='));

    // If a userprofile is not passed (e.g. on registration, when
    // there is no existing user profile), we don't need to look for
    // additional fields
    if (!userProfile) {
        return formFields.join('&');
    }

    // there could be cases in which there are extra fields
    // in the user profile that do not show up in the form
    // (e.g. a user is a member of two different projects that
    // both have custom fields) this makes sure that we don't
    // remove those properties when we update others
    const hdata = userProfile.data;
    const extraFields = Object.keys(hdata)
        .filter(f => !fieldNames.includes(f))
        .map(f => `${f}=${hdata[f]}`);

    return [...formFields, ...extraFields].join('&');
};
