import { createReducer } from 'redux-act';
import update from 'immutability-helper';

import {
    startDownload,
    updateTotalCounts,
    appendDownloadedData,
    failDownload,
    clearDownload,
    cancelDownload,
    startDownloadCount,
    completeDownloadCount,
    failDownloadCount,
    startRetryDownload,
    requestAdditionalDownloadFormat,
} from '../actions/downloads';

export const createInitialDownloadState = ({ url, fileFormat, columns }) => ({
    initialUrl: url,
    error: null,
    data: [],
    count: null,
    numPages: null,
    fetchedPageCount: 0,
    nextPageNumber: null,
    fileFormats: [fileFormat],
    columns,
});

export const initialState = {
    downloads: {},
    counts: {},
};

const removeDownload = (state, { key, fileFormat }) => {
    const downloadObject = state.downloads[key];
    const objectHasSingleFormat =
        downloadObject && downloadObject.fileFormats.length < 2;
    if (!fileFormat || objectHasSingleFormat) {
        return update(state, {
            downloads: { $unset: [key] },
        });
    }
    return update(state, {
        downloads: {
            [key]: {
                fileFormats: formats =>
                    formats.filter(format => format !== fileFormat),
            },
        },
    });
};

const DownloadsReducer = createReducer(
    {
        [startDownload]: (state, { key, url, fileFormat, columns }) =>
            update(state, {
                downloads: {
                    $merge: {
                        [key]: createInitialDownloadState({
                            url,
                            fileFormat,
                            columns,
                        }),
                    },
                },
            }),
        [updateTotalCounts]: (state, { key, numPages, count }) => {
            if (state.downloads[key]) {
                return update(state, {
                    downloads: {
                        [key]: { $merge: { numPages, count } },
                    },
                });
            }
            return state;
        },
        [appendDownloadedData]: (state, { key, data }) => {
            if (state.downloads[key]) {
                return update(state, {
                    downloads: {
                        [key]: {
                            data: { $push: data },
                            fetchedPageCount: { $apply: x => x + 1 },
                        },
                    },
                });
            }
            return state;
        },
        [failDownload]: (state, { key, error }) => {
            if (state.downloads[key]) {
                return update(state, {
                    downloads: {
                        [key]: { $merge: { error } },
                    },
                });
            }
            return state;
        },
        [startRetryDownload]: (state, { key }) => {
            if (state.downloads[key]) {
                return update(state, {
                    downloads: {
                        [key]: { $merge: { error: null } },
                    },
                });
            }
            return state;
        },
        // Cancel and clear currently have the same effect but we keep the
        // distinct actions for clarity. Cancel interrups and in progress
        // download. Clear removes a failed download.
        [cancelDownload]: removeDownload,
        [clearDownload]: removeDownload,
        [startDownloadCount]: (state, { url }) =>
            update(state, {
                counts: {
                    $merge: { [url]: { count: null } },
                },
            }),
        [completeDownloadCount]: (state, { url, count }) =>
            update(state, {
                counts: {
                    $merge: { [url]: { count: count } },
                },
            }),
        [failDownloadCount]: (state, { url, error }) =>
            update(state, {
                counts: {
                    $merge: { [url]: { count: null, error } },
                },
            }),
        [requestAdditionalDownloadFormat]: (state, { key, fileFormat }) => {
            if (state.downloads[key]) {
                return update(state, {
                    downloads: {
                        [key]: {
                            fileFormats: formats =>
                                formats.includes(fileFormat)
                                    ? formats
                                    : [...formats, fileFormat],
                        },
                    },
                });
            }
            return state;
        },
    },
    initialState
);

export default DownloadsReducer;
