import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconBadge from './IconBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Typography, Button } from 'antd';

import HomeObservationMap from './HomeObservationMap';
import { setActiveFilterSetName } from '../actions/filterSet';
import { hardcodedRecentFilterSetName } from '../util/constants';
import { project, schema } from '../util/propTypes';

const HomeObservations = ({ project, schema }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { Title, Paragraph } = Typography;

    return (
        <div className='home-observations'>
            <Row justify='center' align='middle'>
                <Col
                    lg={{ span: 6, offset: 4 }}
                    className='home-observations__details'
                >
                    <IconBadge icon={['far', 'map-marker-check']} />
                    <Title type='primary' className='ant-typography-light'>
                        Our most recent observations
                    </Title>
                    <Paragraph>
                        Here are the most recent 25 observations collected for
                        this project.
                    </Paragraph>
                    <Button
                        onClick={() => {
                            dispatch(
                                setActiveFilterSetName(
                                    hardcodedRecentFilterSetName
                                )
                            );
                            history.push('/observations');
                        }}
                    >
                        <span>Explore the data</span>
                        <FontAwesomeIcon icon={['far', 'arrow-right']} />
                    </Button>
                </Col>
                <Col flex={1}>
                    <div className='home-observations__map'>
                        <HomeObservationMap schema={schema} project={project} />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

HomeObservations.propTypes = {
    project: project.isRequired,
    schema: schema.isRequired,
};

export default HomeObservations;
